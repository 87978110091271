import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'app/store';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './i18n';
import './server/index';

Sentry.init({
  dsn: 'https://c14c9ff77f3b4bb9898b503af4c105cb@o404659.ingest.sentry.io/5474083',
  release: process.env.REACT_APP_GIT_SHA,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Sentry.ErrorBoundary
  showDialog
    fallback={({ error, componentStack, resetError }) => {
      return (
      <div style={{width: "80%", maxWidth: "750px", margin: "0 auto"}}>
        <h1>An error has occured. </h1>
        <h2>The MeetPlans Development Team was already notified.</h2>
        <hr/>
        <div>{error.toString()}</div>
        <hr/>
        <div>{componentStack}</div>
        <hr/>
        <button
          onClick={resetError}
        >
          Click here to reset or try refreshing the page.
        </button>
      </div>);
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
