import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

import { validations } from '_helpers';
import { RootState } from 'app/rootReducer';
import { activateInvitedOrgUser } from '../authSlice';

type ActivateUSerFormData = {
  email: string;
  password: string;
};

type RecoverFormProps = {
  code: string;
};

const ActivateNewUser = ({ code }: RecoverFormProps) => {
  const { t } = useTranslation('login');
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const resMsg = useSelector((state: RootState) => state.authentication.error);

  async function activateUser(props: ActivateUSerFormData) {
    dispatch(activateInvitedOrgUser(code, props.email, props.password));
  }

  return (
    <Form className="auth-form p32" onSubmit={handleSubmit(activateUser)}>
      <Form.Group as={Col}>
        <Form.Label htmlFor="email">
          <Trans i18nKey="email" ns="login">
            Email address
          </Trans>
        </Form.Label>
        <Form.Control autoFocus required type="email" {...register('email', validations.email)} id="email" name="email" placeholder={t('email')} />

        {errors?.email && (
          <Form.Text muted>
            <Trans i18nKey="email_error_message" ns="login">
              errors?.email?.message
            </Trans>
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label htmlFor="password">
          <Trans i18nKey="new password" ns="login">
            Please enter your new password
          </Trans>{' '}
        </Form.Label>
        <Form.Control
          required
          autoFocus
          type="password"
          {...register('password', validations.password)}
          id="password"
          name="password"
          // aria-invalid={errors.password ? 'true' : 'false'}
          placeholder={t('password')}
        />
        {errors.password && (
          <Form.Text id="passwordHelpBlock" className="error-text">
            <Trans i18nKey="password description" ns="login">
              Your password must be 8-20 characters long, and contain a mix of letters and numbers.
            </Trans>
            <br />
            {errors.password.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label htmlFor="password_repeat">
          <Trans i18nKey="repeat password title" ns="login">
            Please repeat your password
          </Trans>
        </Form.Label>
        <Form.Control
          required
          type="password"
          {...register('password_repeat', { validate: (value) => value === getValues('password') || t("The passwords don't match").toString() })}
          id="password_repeat"
          name="password_repeat"
          aria-invalid={errors.password_repeat ? 'true' : 'false'}
          placeholder={t('repeat password')}
        />
        {errors.password_repeat && (
          <Form.Text id="passwordHelpBlock" className="error-text">
            <Trans i18nKey="{errors.password_repeat.message}" ns="login">
              {errors.password_repeat.message}
            </Trans>
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group as={Col}>
        <Button variant="primary" type="submit">
          <Trans i18nKey="login" ns="login">
            Send
          </Trans>
        </Button>
      </Form.Group>

      <Link className="ml12" to="/login">
        <Trans i18nKey="Already registered? Login" ns="login">
          Already registered? Login
        </Trans>
      </Link>

      {resMsg && <p className="ml12 mt-4 error-text">{t(resMsg)}</p>}
    </Form>
  );
};

export default ActivateNewUser;
