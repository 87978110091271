import { captureException } from '@sentry/react';

export function handleErrorResponse(e: any) {
  console.log(e);
  captureException(e);
  const error = (e.response && e.response.data && e.response.data.message) || e.message || e.statusText;
  if (error) {
    return Promise.reject(error);
  }

  return Promise.reject();
}

export function handleCaughtError(err: any) {
  console.log(err);
  captureException(err);
}
