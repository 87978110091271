import { handleCaughtError } from '_helpers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';

import { CalendarResponseMessage, OrgToDo } from 'server';
import { getClientTodos, addNewTodo, updateTodo, getMyTodos, deleteTodo, setTodoDone } from './taskInterface';
import { showMessage } from 'features/alerts/alertSlice';
import { RESPONSE_CODE } from '../asyncRequest';
import openNotificationWithIcon from '../alerts/Toast';

interface TaskState {
  clientId: string;
  tasks: OrgToDo[];
  taskType: string;
}

const initialState: TaskState = {
  clientId: '',
  tasks: [],
  taskType: '',
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTasks(state, action: PayloadAction<CalendarResponseMessage>) {
      state.tasks = action.payload.todos || [];
      if (state.tasks.length > 0) {
        state.clientId = state.tasks[0].client?.user_id || '';
      } else {
        state.clientId = '';
      }
    },
    updateTask(state, action: PayloadAction<OrgToDo>) {
      const idx = state.tasks.findIndex((item) => item.id === action.payload.id);
      if (idx > -1) {
        state.tasks[idx] = action.payload;
      }
    },
    setClientID(state, action: PayloadAction<string>) {
      state.clientId = action.payload;
    },
    setTaskType(state, action: PayloadAction<string>) {
      state.taskType = action.payload;
    },
    removeTask(state, action: PayloadAction<string>) {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    },
  },
});

export const { setTasks, setClientID, setTaskType, updateTask, removeTask } = taskSlice.actions;
export default taskSlice.reducer;

/***
 * Tasks dispatch methods
 **/

export const getClientTasks = (clientId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await getClientTodos(authToken, clientId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setClientID(clientId));
      dispatch(setTaskType('client'));
      dispatch(setTasks(resp));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const getMyTasks = (): AppThunk => async (dispatch, getState) => {
  // Returns the assigned task (for a user) or all org tasks for admins
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await getMyTodos(authToken);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setClientID(''));
      dispatch(setTaskType('all'));
      dispatch(setTasks(resp));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const addClientTask = (clientId: string, task: OrgToDo): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;
    // TODO: throw an error if there's no client to add a task for...
    if (!clientId) return;

    const resp: CalendarResponseMessage = await addNewTodo(usertoken, task);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getClientTasks(clientId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    openNotificationWithIcon('error', 'Error adding todo', 'Please Try again later');
  }
};

export const updateClientTask = (clientId: string, task: OrgToDo): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken } = state.authentication;
    if (!usertoken) {
      dispatch(showMessage('usertoken is null', 'warning'));
      return;
    }

    if (task.client) {
      task.client.user_id = clientId;
    }

    const resp: CalendarResponseMessage = await updateTodo(usertoken, task);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      if (resp.todos && resp.todos.length > 0) {
        dispatch(updateTask(resp.todos[0]));
      }
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const deleteClientTask = (clientId: string, todoId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;

    const resp: CalendarResponseMessage = await deleteTodo(usertoken, todoId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getClientTasks(clientId));
      // dispatch(removeTask(todoId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const setDone = (clientId: string, todoId: string, isDone: boolean): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;

    const resp: CalendarResponseMessage = await setTodoDone(usertoken, todoId, isDone);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getClientTasks(clientId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};
