import React, { useState, useEffect } from 'react';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';


const ClockContainer = () => {
    const [date, setDate] = useState(new Date());
    function tick() {
        setDate(new Date());
    }

    useEffect(() => {
        const timerID = setInterval( () => tick(), 1000 );
        return () => {
            clearInterval(timerID);
          };
       });

    return (
        <div className="d-flex justify-content-between align-items-center" style={{width: "150px"}}>
            <Clock value={date} size={40} renderSecondHand={false}/>
            {date.toLocaleTimeString('de-DE') }
        {/*    todo: consider changing to user's local time instead of Europe only*/}
        </div>
    );
}

export default ClockContainer;