import { handleCaughtError } from '_helpers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';

import { AccountResponseMessage, AccountRole } from 'server/api';
import { userManagementService } from './userManagementService';
import { AsyncRequest, RESPONSE_CODE, startLoading, loadingFailed } from '../asyncRequest';

interface RolesState extends AsyncRequest {
  roles: AccountRole[];
}

const initialState: RolesState = {
  loading: false,
  error: null,
  roles: [],
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    requestStart: startLoading,
    requestFailed: loadingFailed,
    setOrganizationRoles(state, { payload }: PayloadAction<AccountRole[]>) {
      state.loading = false;
      state.roles = payload;
    },
  },
});

export const { requestStart, requestFailed, setOrganizationRoles } = rolesSlice.actions;
export default rolesSlice.reducer;

export const fetchOrganizationRoles = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      dispatch(requestFailed('Not authenticated'));
      return;
    }

    if (state.roles.loading || state.roles.roles.length > 0) {
      return;
    }

    dispatch(requestStart());

    const response: AccountResponseMessage = await userManagementService.getOrganizationRoles(authToken);
    if (response.code === RESPONSE_CODE.SUCCESS && response.roles) {
      dispatch(setOrganizationRoles(response.roles));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};
