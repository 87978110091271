import React from 'react';
import {notification} from "antd";
import { Translation } from 'react-i18next';

export type AntToastMessageType = 'error' | 'info' | 'success' | 'warning';


const openNotificationWithIcon = (type:AntToastMessageType, message: string, description: string = '', i18nKey?: string) => {
  // const { t } = useTranslation(i18nKey);

  notification[type]({
    message: <Translation ns={i18nKey}>{t => t(message)}</Translation>,  
    description: <Translation ns={i18nKey}>{t => t(description)}</Translation>,
    duration: 3
  });
};

export default openNotificationWithIcon;