import { combineReducers } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

import updatePasswordReducer from 'features/auth/updatePasswordSlice';
import authReducer from 'features/auth/authSlice';
import accountReducer from 'features/account/accountSlice';
import userManagementReducer from 'features/user-management/userManagementSlice';
import rolesReducer from 'features/user-management/rolesSlice';
import alertReducer from 'features/alerts/alertSlice';
import calendarReducer from 'features/schedule/calendarSlice';
import chatReducer from 'features/schedule/chatSlice';
import clientDataReducer from 'features/clientData/clientDataSlice';
import orgDataReducer from 'features/orgData/orgDataSlice';
import meetingReducer from 'features/meeting/meetingSlice';
import taskReducer from 'features/tasks/taskSlice';

const rootReducer = combineReducers({
  updatePassword: updatePasswordReducer,
  authentication: authReducer,
  accounts: accountReducer,
  userManagement: userManagementReducer,
  roles: rolesReducer,
  alert: alertReducer,
  calendar: calendarReducer,
  tasks: taskReducer,
  chat: chatReducer,
  clientData: clientDataReducer,
  organization: orgDataReducer,
  meeting: meetingReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
