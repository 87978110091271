import { handleCaughtError } from '_helpers';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from 'app/store';

import { CalendarResponseMessage, Meeting, MeetingRequest, MeetingType } from 'server';
import calendarServices, { getOrgMeetings } from './calendarInterface';
import { showMessage } from 'features/alerts/alertSlice';
import { RESPONSE_CODE } from '../asyncRequest';
import openNotificationWithIcon from '../alerts/Toast';
import { RootState } from 'app/rootReducer';
import { parseISO, addMinutes } from 'date-fns';
interface CalendarState extends CalendarResponseMessage {
  fetchMeetingRequestsStatus: 'idle' | 'loading' | 'failed';
  fetchMeetingsStatus: 'idle' | 'loading' | 'failed';
  currentMeetingStatus: 'idle' | 'processing';
  fetchMeetingRequestsError: string | null;
  fetchMeetingsError: string | null;
  showDatePicker: boolean;
  refreshMeetingID: string | null;
  meetingTypes: MeetingType[];
}

export interface NewMeetingDetails {
  startTimeISO: string;
  endTimeISO?: string;
  meetingType?: MeetingType;
  reqId: string;
  clientId: string;
  participants: string[];
}

export interface MeetingTime {
  meetingId: string;
  startTimeISO: string;
  endTimeISO: string;
}

export interface GetOrgMeetings {
  fromDate?: Date;
  toDate?: Date;
}

export interface GetMeetings extends GetOrgMeetings {
  user_id: string;
}

export const SLICE_LENGTH_IN_MINUTES: number = 5;

const initialState: CalendarState = {
  meeting_notes: [],
  meeting_requests: [],
  fetchMeetingRequestsStatus: 'idle',
  fetchMeetingsStatus: 'idle',
  currentMeetingStatus: 'idle',
  fetchMeetingsError: null,
  showDatePicker: false,
  fetchMeetingRequestsError: null,
  meeting_status: [],
  meeting_todos: [],
  meetingTypes: [],
  meetings: [],
  message: '',
  series: [],
  refreshMeetingID: null,
};

export const fetchUserMeetings = createAsyncThunk<CalendarResponseMessage | undefined, GetMeetings, { state: RootState; dispatch: AppDispatch }>(
  'calendar/fetchUserMeetings',
  async (getMeetings: GetMeetings, { getState, dispatch }) => {
    try {
      const { user_id, fromDate, toDate } = getMeetings;
      const state = getState();
      const authToken = state.authentication.usertoken;
      if (!authToken) {
        return;
      }
      const response = await calendarServices.getUserMeetings(authToken, user_id, fromDate, toDate);

      if (response.code === RESPONSE_CODE.SUCCESS) {
        return response;
      } else {
        console.log('error getting meetings');
      }
    } catch (err) {
      handleCaughtError(err);
    }
  }
);

export const fetchOrgMeetings = createAsyncThunk<CalendarResponseMessage | undefined, GetOrgMeetings, { state: RootState; dispatch: AppDispatch }>(
  'calendar/fetchOrgMeetings',
  async (getMeetings: GetOrgMeetings, { getState, dispatch }) => {
    try {
      const { fromDate, toDate } = getMeetings;
      const state = getState();
      const authToken = state.authentication.usertoken;
      if (!authToken) {
        return;
      }
      const response = await getOrgMeetings(authToken, fromDate, toDate);

      if (response.code === RESPONSE_CODE.SUCCESS) {
        return response;
      } else {
        console.log('error getting all organization meetings');
      }
    } catch (err) {
      handleCaughtError(err);
    }
  }
);

// Handle scheduling a meeting from the request page.
// TODO: remove the dependency of the setCurrentMeetingStatus
export const scheduleMeeting = createAsyncThunk<CalendarResponseMessage | undefined, NewMeetingDetails, { state: RootState; dispatch: AppDispatch }>(
  'meetings/scheduleMeeting',
  async (meetingDetails: NewMeetingDetails, { getState, dispatch }) => {
    try {
      const state = getState();
      const authToken = state.authentication.usertoken;
      if (!authToken) {
        return;
      }
      let { startTimeISO, endTimeISO, meetingType, reqId, clientId, participants } = meetingDetails;
      if (!endTimeISO && !meetingType) {
        return;
      }
      if (!endTimeISO) {
        endTimeISO = addMinutes(parseISO(startTimeISO), meetingType?.slices ?? 0 * SLICE_LENGTH_IN_MINUTES).toISOString();
      }
      const response = await calendarServices.createMeeting(authToken, startTimeISO, endTimeISO, reqId, clientId, participants, meetingType);

      if (response.code === RESPONSE_CODE.SUCCESS) {
        dispatch(setCurrentMeetingStatus('processing'));
        openNotificationWithIcon('success', 'Success', 'The meeting was booked successfully', 'meeting-requests');
        return response;
      } else {
        openNotificationWithIcon('error', 'Error saving meeting', 'Please Try again later', 'meeting-requests');
      }
    } catch (err) {
      handleCaughtError(err);
      openNotificationWithIcon('error', 'Error saving meeting', 'Please Try again later', 'meeting-requests');
    }
  }
);

export const updateMeetingTime = createAsyncThunk<CalendarResponseMessage | undefined, MeetingTime, { state: RootState; dispatch: AppDispatch }>(
  'meetings/updateMeeting',
  async (meetingInfo: MeetingTime, { getState, dispatch }) => {
    try {
      const state = getState();
      const authToken = state.authentication.usertoken;
      if (!authToken) {
        return;
      }
      const response = await calendarServices.updateMeetingTime(authToken, meetingInfo.meetingId, meetingInfo.startTimeISO, meetingInfo.endTimeISO);

      if (response.code === RESPONSE_CODE.SUCCESS) {
        dispatch(setNewMeetingTime(meetingInfo));
        dispatch(setCurrentMeetingStatus('processing'));
        openNotificationWithIcon('success', 'Success', 'The meeting was updated successfully', 'meeting-requests');
        dispatch(setRefreshMeetingId(meetingInfo.meetingId));
        return response;
      } else {
        openNotificationWithIcon('error', 'Error saving meeting', 'Please Try again later', 'meeting-requests');
      }
    } catch (err) {
      handleCaughtError(err);
      openNotificationWithIcon('error', 'Error saving meeting', 'Please Try again later', 'meeting-requests');
    }
  }
);

export const fetchMeetingRequests = createAsyncThunk('calendar/fetchMeetingRequests', async (usertoken: string) => {
  try {
    if (!usertoken) return [];
    const response = await calendarServices.getOpenMeetingRequests(usertoken);
    if (response.code === RESPONSE_CODE.SUCCESS) {
      return response.meeting_requests;
    }
  } catch (err) {
    handleCaughtError(err);
    openNotificationWithIcon('error', 'something went wrong', err || 'Please Try again later', 'meeting-requests');
    return err;
  }
});

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setMeetingRequests(state, action: PayloadAction<MeetingRequest[]>) {
      state.meeting_requests = action.payload;
    },
    setCurrentMeetingStatus(state, action: PayloadAction<'idle' | 'processing'>) {
      state.currentMeetingStatus = action.payload;
    },
    setShowDatePicker(state, action: PayloadAction<boolean>) {
      state.showDatePicker = action.payload;
    },
    addMeetingRequestIfNotExist(state, action: PayloadAction<MeetingRequest>) {
      const requestInState = state.meeting_requests?.find((r) => r.id === action.payload.id);
      if (!requestInState && state.meeting_requests) {
        state.meeting_requests = [...state.meeting_requests, action.payload];
      }
    },
    addMeeting(state, action: PayloadAction<Meeting>) {
      state.meetings = state.meetings || [];
      state.meetings.push(action.payload);
    },
    removeMeeting(state, action: PayloadAction<string>) {
      state.meetings = state.meetings?.filter((m) => m.id !== action.payload);
    },
    setMeetingNotes(state, action: PayloadAction<CalendarResponseMessage>) {
      state.meeting_notes = action.payload.meeting_notes;
    },
    setMeetingTodos(state, action: PayloadAction<CalendarResponseMessage>) {
      state.meeting_todos = action.payload.meeting_todos;
    },
    setMeetingTypes(state, action: PayloadAction<CalendarResponseMessage>) {
      state.meetingTypes = action.payload.meeting_types?.sort(sortMeetingTypes) || [];
    },
    setNewMeetingTime(state, action: PayloadAction<MeetingTime>) {
      const m_id = state.meetings?.findIndex((m) => m.id === action.payload.meetingId);
      if (m_id && state.meetings && state.meetings[m_id]) {
        state.meetings[m_id].end_time = action.payload.endTimeISO;
        state.meetings[m_id].start_time = action.payload.startTimeISO;
      }
    },
    setRefreshMeetingId(state, action: PayloadAction<string | null>) {
      state.refreshMeetingID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMeetingRequests.pending, (state, action) => {
      state.fetchMeetingRequestsStatus = 'loading';
    });
    builder.addCase(fetchMeetingRequests.fulfilled, (state, action) => {
      state.fetchMeetingRequestsStatus = 'idle';
      state.meeting_requests = action.payload;
    });
    builder.addCase(fetchMeetingRequests.rejected, (state, action) => {
      state.fetchMeetingRequestsStatus = 'failed';
      state.fetchMeetingRequestsError = action.error.message!;
    });
    builder.addCase(fetchUserMeetings.pending, (state, action) => {
      state.fetchMeetingsStatus = 'loading';
    });
    builder.addCase(fetchUserMeetings.fulfilled, (state, action) => {
      state.fetchMeetingsStatus = 'idle';
      state.meetings = action.payload?.meetings;
    });
    builder.addCase(fetchUserMeetings.rejected, (state, action) => {
      state.fetchMeetingsStatus = 'failed';
      state.fetchMeetingsError = action.error.message!;
    });
    builder.addCase(fetchOrgMeetings.pending, (state, action) => {
      state.fetchMeetingsStatus = 'loading';
    });
    builder.addCase(fetchOrgMeetings.fulfilled, (state, action) => {
      state.fetchMeetingsStatus = 'idle';
      state.meetings = action.payload?.meetings;
    });
    builder.addCase(fetchOrgMeetings.rejected, (state, action) => {
      state.fetchMeetingsStatus = 'failed';
      state.fetchMeetingsError = action.error.message!;
    });
  },
});

export const {
  setMeetingRequests,
  setCurrentMeetingStatus,
  setMeetingNotes,
  setMeetingTodos,
  addMeetingRequestIfNotExist,
  setMeetingTypes,
  setShowDatePicker,
  addMeeting,
  removeMeeting,
  setNewMeetingTime,
  setRefreshMeetingId,
} = calendarSlice.actions;
export default calendarSlice.reducer;

/***
 * Calendar dispatch methods
 **/

function sortMeetingTypes(a: MeetingType, b: MeetingType) {
  if (a?.slices && b.slices) {
    return a?.slices > b.slices ? 1 : -1;
  }
  return 0;
}

export const selectMeetingRequests = (state: RootState) => (state.calendar as CalendarState).meeting_requests;

export const getMeetingById = (meetingId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarServices.getMeetingById(authToken, meetingId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      if (resp.meetings && resp.meetings?.length > 0) {
        dispatch(addMeetingRequestIfNotExist(resp.meetings[0]));
      }
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const deleteMeeting = (meetingId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarServices.deleteMeeting(authToken, meetingId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(removeMeeting(meetingId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const addNewMeeting = (meeting: NewMeetingDetails): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    if (!meeting.startTimeISO) {
      dispatch(showMessage('missing start time', 'warning'));
      return;
    }

    if (!meeting.meetingType) {
      dispatch(showMessage('missing meeting type', 'warning'));
      return;
    }

    if (!meeting.endTimeISO) {
      meeting.endTimeISO = addMinutes(parseISO(meeting.startTimeISO), meeting.meetingType?.slices ?? 0 * SLICE_LENGTH_IN_MINUTES).toISOString();
    }

    if (!meeting.participants || meeting.participants.length === 0) {
      dispatch(showMessage('missing meeting participants', 'warning'));
      return;
    }

    if (meeting.reqId === '') {
      meeting.participants.push(meeting.clientId);
    }

    const resp: CalendarResponseMessage = await calendarServices.createMeeting(
      authToken,
      meeting.startTimeISO,
      meeting.endTimeISO,
      meeting.reqId,
      meeting.clientId,
      meeting.participants,
      meeting.meetingType
    );
    if (resp.code === RESPONSE_CODE.SUCCESS && resp.meetings && resp.meetings.length > 0) {
      dispatch(addMeeting(resp.meetings[0]));
      openNotificationWithIcon('success', 'Success', 'The meeting was booked successfully', 'meeting-requests');
      // dispatch(showMessage('something went wrong', 'success'));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const removeRequest = (requestId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarServices.deleteMeetingRequest(authToken, requestId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      const lst = state.calendar.meeting_requests?.filter((req) => req.id !== requestId);
      dispatch(setMeetingRequests(lst || []));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const getMeetingNotes = (meetingId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarServices.getMeetingNotes(authToken, meetingId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setMeetingNotes(resp));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const addMeetingNote = (meetingId: string, clientId: string, note: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const writerId = state.authentication.userid ?? '';

    const resp: CalendarResponseMessage = await calendarServices.addMeetingNote(authToken, meetingId, clientId, writerId, note);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingNotes(meetingId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const updateMeetingNote = (meetingId: string, noteId: string, note: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarServices.updateMeetingNote(authToken, noteId, note);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingNotes(meetingId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    dispatch(showMessage(err, 'danger'));
  }
};

export const getMeetingTodos = (meetingId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarServices.getMeetingToDo(authToken, meetingId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setMeetingTodos(resp));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const addMeetingTodo = (meetingId: string, clientId: string, item: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;

    const resp: CalendarResponseMessage = await calendarServices.addMeetingTodo(usertoken, meetingId, clientId, item, userid);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingTodos(meetingId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    openNotificationWithIcon('error', 'Error adding todo', 'Please Try again later');
  }
};

export const updateMeetingTodo = (meetingId: string, todoId: string, item: string, isDone: boolean): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;

    const resp: CalendarResponseMessage = await calendarServices.updateMeetingTodos(usertoken, meetingId, todoId, item, isDone, userid);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingTodos(meetingId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const deleteMeetingTodo = (meetingId: string, todoId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;

    const resp: CalendarResponseMessage = await calendarServices.deleteMeetingTodos(usertoken, meetingId, todoId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingTodos(meetingId));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const getMeetingTypes = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken } = state.authentication;
    if (!usertoken) return;

    const resp: CalendarResponseMessage = await calendarServices.getMeetingTypes(usertoken);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setMeetingTypes(resp));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const addMeetingType = (name: string, slices: number): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken } = state.authentication;
    if (!usertoken) return;

    const resp: CalendarResponseMessage = await calendarServices.addMeetingType(usertoken, name, slices);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingTypes());
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const removeMeetingType = (id: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken } = state.authentication;
    if (!usertoken) return;

    const resp: CalendarResponseMessage = await calendarServices.deleteMeetingType(usertoken, id);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getMeetingTypes());
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};
