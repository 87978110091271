import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleCaughtError } from '_helpers';
import { AppThunk } from 'app/store';

import { AccountResponseMessage, OrgData, Label } from 'server';
import { accountService } from '../account/accountService';
import { getOrgWhiteLabel } from './orgService';
import { AsyncRequest, RESPONSE_CODE, startLoading, loadingFailed } from '../asyncRequest';

/*
TODO: Finish this
Gets and upadtes general organization and experts information
*/

interface OrgState extends AsyncRequest {
  data: OrgData;
  availableLabels: Label[];
  // organizationLabels: Label[];
}

const initialState: OrgState = {
  loading: false,
  error: null,
  data: {},
  availableLabels: [],
  // organizationLabels: [],
};

const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    requestStart: startLoading,
    requestFailed: loadingFailed,
    setOrgData(state, action: PayloadAction<OrgData>) {
      state.data = action.payload;
    },
    setAvailableLabels(state, action: PayloadAction<Label[]>) {
      state.availableLabels = action.payload;
    },
    // setOrganizationLabels(state, action: PayloadAction<Label[]>) {
    //   state.organizationLabels = action.payload;
    // },
  },
});

export const { requestStart, requestFailed, setOrgData, setAvailableLabels /*, setOrganizationLabels */ } = orgSlice.actions;
export default orgSlice.reducer;

export const getWhiteLabel = (): AppThunk => async (dispatch) => {
  try {
    const res = await getOrgWhiteLabel();

    if (res.code === RESPONSE_CODE.SUCCESS && res.organization_data) {
      dispatch(setOrgData(res.organization_data));
    } else {
      dispatch(requestFailed(res.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const getLabels = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }

    const response: AccountResponseMessage = await accountService.getUserlabels(authToken);

    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setAvailableLabels(response.labels ?? []));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const addLabel = (category: string, labelName: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }

    const response: AccountResponseMessage = await accountService.createLabel(category, authToken, labelName);

    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getLabels());
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const deleteLabel = (labelId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }

    const response: AccountResponseMessage = await accountService.deleteUserlabel(labelId, authToken);

    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getLabels());
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};
