import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { v4 as uuidv4 } from 'uuid';

import { AppThunk } from 'app/store';
import openNotificationWithIcon, {AntToastMessageType} from "../alerts/Toast";


export type AlertMessageType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

interface AlertMessage {
  content: string;
  type: AlertMessageType;
}

interface AlertInstance {
  id: string;
  message: AlertMessage;
}

interface AlertState {
  alerts: AlertInstance[];
}

const initialState: AlertState = {
  alerts: [],
};

const messageAlerts = createSlice({
  name: 'messageAlerts',
  initialState,
  reducers: {
    pushAlert(state, action: PayloadAction<AlertInstance>) {
      state.alerts = [...state.alerts, action.payload];
    },
    popAlert(state, action: PayloadAction<string>) {
      state.alerts = state.alerts.filter((alert) => alert.id !== action.payload);
    },
  },
});

export const { pushAlert, popAlert } = messageAlerts.actions;
export default messageAlerts.reducer;

export const showMessage = (content: string, type: AlertMessageType): AppThunk => async (dispatch) => {
  let msgType: AntToastMessageType;
  switch (type) {
    case "primary":
    case "success":
      msgType="success";
      break;
    case "secondary":
    case "dark":
    case "light":
      msgType="info";
      break;
    case "warning":
      msgType="warning";
      break;
    case "danger":
    default:
      msgType="error";
  }

  openNotificationWithIcon(msgType, msgType, content);

  // const id = uuidv4();
  // const alert: AlertInstance = {
  //   id,
  //   message: {
  //     content: content,
  //     type: type,
  //   },
  // };
  // dispatch(pushAlert(alert));

  // setTimeout(() => dispatch(popAlert(id)), 2000);
};
