import { AccountsApi, AccountResponseMessage } from 'server';
import { handleErrorResponse } from '_helpers';

export const getOrgWhiteLabel = async (): Promise<AccountResponseMessage> => {
  const account = new AccountsApi();
  try {
    const { data } = await account.whiteLabel();
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
};
