import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RootState } from 'app/rootReducer';

import { Form, Button, Col } from 'react-bootstrap';

import { validations } from '_helpers';
import { logout } from 'features/auth/authSlice';
import { resetPassword } from 'features/auth/interface';

type ResetPasswordFormData = {
  email: string;
};

type msgState = {
  txt: string;
  type: string;
};

const ResetPasswordForm = () => {
  const { t } = useTranslation(['login', 'error-messages']);
  const [msg, setMsg] = useState<msgState>({ txt: '', type: '' });
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const lang = useSelector((state: RootState) => state.accounts.pref_lang);

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  async function reset_password(props: ResetPasswordFormData) {
    try {
      await resetPassword(lang, props.email);
      setMsg({ type: 'msg', txt: t('An Email was sent to this address') });
    } catch (e) {
      if (e?.response?.status === 405) {
        setMsg({ txt: t('Email does not exist'), type: 'error-text' });
      } else {
        setMsg({ txt: t('Something went wrong'), type: 'error-text' });
      }
    }
  }

  return (
    <>
      <Form className="login-form" onSubmit={handleSubmit(reset_password)}>
        <Form.Group as={Col}>
          <Form.Label htmlFor="email">
            <Trans i18nKey="email" ns="login">
              Email address
            </Trans>
          </Form.Label>
          <Form.Control autoFocus required type="email" {...register('email', validations.email)} id="email" name="email" placeholder={t('email')} />

          {errors?.email && (
            <Form.Text muted>
              <Trans i18nKey="email_error_message" ns="login">
                {errors?.email?.message && <span className="error-text">{t('error-messages:' + errors?.email?.message)}</span>}
              </Trans>
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group className="justify-space-between" as={Col}>
          <Button variant="primary" type="submit">
            <Trans i18nKey="login" ns="login">
              Send
            </Trans>
          </Button>

          <Button variant="ghost">
            <Link to="/login">
              <Trans i18nKey="back to login" ns="login">
                Cancel
              </Trans>
            </Link>
          </Button>
        </Form.Group>

        {msg && msg.txt && <p className={`mt-4 ml12 ${msg.type}`}>{t('error-messages:' + msg.txt)}</p>}
      </Form>
    </>
  );
};

export default ResetPasswordForm;
