import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { handleCaughtError } from '_helpers';

import { AppThunk } from 'app/store';

import { Meeting, MeetingParticipant, OrganizationUser, CalendarResponseMessage } from 'server';
import calendarServices from 'features/schedule/calendarInterface';
import { showMessage } from 'features/alerts/alertSlice';
import { RESPONSE_CODE } from '../asyncRequest';
import { accountService } from 'features/account/accountService';

interface MeetingState extends Meeting {
  user_status?: string;
}

const initialState: MeetingState = {
  participants: [],
  user_status: '',
  actual_duration: 0,
};

const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    setMeeting(state, action: PayloadAction<Meeting>) {
      state = action.payload;
    },
    setParticipants(state, action: PayloadAction<MeetingParticipant[]>) {
      state.participants = action.payload;
    },
    addParticipant(state, action: PayloadAction<MeetingParticipant>) {
      state.participants?.push(action.payload);
    },
    setActualDuration(state, action: PayloadAction<number>) {
      state.actual_duration = action.payload;
    },
    setUserStatus(state, action: PayloadAction<string>) {
      state.user_status = action.payload;
    },
  },
});

export const { setMeeting, setParticipants, addParticipant, setActualDuration, setUserStatus } = meetingSlice.actions;
export default meetingSlice.reducer;

export const getMeetingById = (meetingId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarServices.getMeetingById(authToken, meetingId);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      if (resp.meetings && resp.meetings?.length > 0) {
        dispatch(setMeeting(resp.meetings[0]));

        if (resp.meetings[0].participants) {
          dispatch(setParticipants(resp.meetings[0].participants));
        } else {
          dispatch(setParticipants([]));
        }
      }
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const setParticipantStatus = (participantId: string, meetingId: string, status: number): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    const resp: CalendarResponseMessage = await calendarServices.setParticipantStatus(authToken, meetingId, participantId, status);

    if (status === 4) {
      accountService.updateUserStatus(authToken, 'in a meeting');
    } else {
      accountService.updateUserStatus(authToken, 'online');
    }

    if (resp.code === RESPONSE_CODE.SUCCESS) {
      // if (resp.meetings && resp.meetings?.length > 0) {
      //   dispatch(setParticipants(resp.meetings[0]));
      // }
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const inviteParticipant = (orgUser: OrganizationUser, meetingId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) return;

    if (!orgUser.user_id) {
      return;
    }

    if (state.meeting.participants && state.meeting.participants?.findIndex((p) => p.participant_id === orgUser.user_id) > -1) {
      return;
    }

    const resp: CalendarResponseMessage = await calendarServices.inviteParticipant(authToken, meetingId, orgUser.user_id);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      if (resp.meetings && resp.meetings?.length > 0 && resp.meetings[0].participants) {
        dispatch(setParticipants(resp.meetings[0].participants));
      }
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};
