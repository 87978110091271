import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

import { validations } from '_helpers';
import { updatePassword } from '../../interface';

type ResetPasswordFormData = {
  password: string;
  password_repeat: string;
};

type RecoverFormProps = {
  code: string;
};

const RecoverForm = ({ code }: RecoverFormProps) => {
  const { t } = useTranslation('login');
  const [errMessage, setErrMessage] = useState('');
  const { register, handleSubmit, getValues, formState: { errors } } = useForm<ResetPasswordFormData>();
  const history = useHistory();

  async function update_password(data: ResetPasswordFormData) {
    updatePassword(code, data.password)
      .then(() => {
        setErrMessage(t('Your new password was successfully set'));
        setTimeout(() => {
          history.push('/login');
        }, 2000);
      })
      .catch(() => {
        setErrMessage(t('Something went wrong'));
      });
  }

  return (
    <Form className="login-form" onSubmit={handleSubmit(update_password)}>
      <Form.Group as={Col}>
        <Form.Label htmlFor="password">
          <Trans i18nKey="new password" ns="login">
            Please enter your new password
          </Trans>{' '}
        </Form.Label>
        <Form.Control
          required
          autoFocus
          type="password"
          {...register('password', validations.password)}
          id="password"
          name="password"
          aria-invalid={errors.password ? 'true' : 'false'}
          placeholder={t('password')}
        />
        {errors.password && (
          <Form.Text id="passwordHelpBlock" muted>
            <Trans i18nKey="password description" ns="login">
              Your password must be 8-20 characters long, and contain a mix of letters and numbers.
            </Trans>
            <br />
            {errors.password.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label htmlFor="password_repeat">
          <Trans i18nKey="repeat password title" ns="login">
            Please repeat your password
          </Trans>
        </Form.Label>
        <Form.Control
          required
          type="password"
          {...register('password_repeat', { validate: (value) => value === getValues('password') || t("The passwords don't match").toString() })}
          id="password_repeat"
          name="password_repeat"
          aria-invalid={errors.password_repeat ? 'true' : 'false'}
          placeholder={t('repeat password')}
        />
        {errors.password_repeat && (
          <Form.Text id="passwordHelpBlock" muted>
            <Trans i18nKey="{errors.password_repeat.message}" ns="login">
              {errors.password_repeat.message}
            </Trans>
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group as={Col}>
        <Button variant="primary" type="submit">
          <Trans i18nKey="reset password send" ns="login"></Trans>
        </Button>
        {errMessage && <p className="mt-4" role="alert">{errMessage}</p>}
      </Form.Group>
    </Form>
  );
};

export default RecoverForm;
