import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faTasks, faInbox, faCog, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useRouteMatch, useLocation, Switch, Route, NavLink, Redirect } from 'react-router-dom';
import './MainContainer.scss';

import { Navbar, Nav, Button } from 'react-bootstrap';

import { WSHandler } from 'components/websocket/WSHandler';

import { RootState } from 'app/rootReducer';
import { performLogout } from 'features/auth/authSlice';
import { getPersonalUserData } from 'features/account/accountSlice';
import ClockContainer from 'components/clock/ClockContainer';
import i18n from 'i18n';
import Loading from "../loading/Loading";
import SearchClient from "components/searchClient/SearchClient";

const Dashboard = lazy(() => import('../../pages/dashboard/Dashboard'));
const Settings = lazy(() => import('pages/settings/Settings'));
const Requests = lazy(() => import('pages/incomingRequests/incomingRequests'));
const OpenTasks = lazy(() => import('pages/openTasks/OpenTasks'));
const Calendar = lazy(() => import('pages/calendar/Calendar'));
const SearchResults = lazy(() => import('pages/searchResults/SearchResults'));
const Meeting = lazy(() => import('features/schedule/meeting/Meeting'));

const MainContainer: React.FC = () => {
  window.name = 'meetplans';

  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('main_menu');
  const { usertoken } = useSelector((state: RootState) => state.authentication);
  const { logo_url } = useSelector((state: RootState) => state.organization.data);

  const { userPersonalData, isAdmin, isConsultant, isExpert } = useSelector((state: RootState) => state.accounts);

  useEffect(() => {
    if (usertoken) {
      dispatch(getPersonalUserData());
    }
  }, [dispatch, usertoken]);

  // now its only needed to update pref_lang in redux in order to update app lang
  useEffect(() => {
    userPersonalData?.pref_lang && i18n.changeLanguage(userPersonalData?.pref_lang);
  }, [userPersonalData]);

  function logout() {
    dispatch(performLogout());
  }

  return (
    <>
      <WSHandler/>

      {location.pathname !== '/app/meeting' && 
      (<Navbar bg="white" expand="lg" className="d-flex align-items-baseline">
        <Navbar.Brand href={`${match.url}/dashboard`}>
          <img src={logo_url} alt="Logo" width="100px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="nav-items">
            <div className="nav-top-row mt-4">
            { !isExpert && <SearchClient/> }
              <div className={isExpert ? "expertClockAlignment" : ""}>
                <ClockContainer />
              </div>
              <Nav className="salute-and-logout">
                <Nav.Item>
                  {t('Hello,')} &nbsp;
                  <b>
                    {userPersonalData?.first_name} {userPersonalData?.last_name}
                  </b>
                </Nav.Item>
                <Button variant="basic" onClick={() => logout()}>
                  <i className="mp-arrow-right"></i>{t('Logout')}
                </Button>
              </Nav>
            </div>
            <div className="nav-bottom-row">
              <Nav className="mr-auto my-4 ml-3 tabs" activeKey={`${location.pathname}`}>
                <NavLink to={`${match.url}/dashboard`} activeClassName="active" exact={false}>
                  <FontAwesomeIcon icon={faTachometerAlt} /> {t('Dashboard')}
                </NavLink>
                <NavLink to={`${match.url}/requests`} activeClassName="active" exact={false} hidden={!(isAdmin || isConsultant)}>
                  <FontAwesomeIcon icon={faInbox} />  {t('Incoming Requests')}
                </NavLink>
                <NavLink to={`${match.url}/calendar`} activeClassName="active" exact={false}>
                  <FontAwesomeIcon icon={faCalendarAlt} /> {t('Calendar')}
                </NavLink>
                <NavLink to={`${match.url}/tasks`} activeClassName="active" exact={false}>
                  <FontAwesomeIcon icon={faTasks} /> {t('Open tasks')}
                </NavLink>
                <NavLink to={`${match.url}/settings`} activeClassName="active" exact={false}>
                  <FontAwesomeIcon icon={faCog} /> {t('Settings')}
                </NavLink>
              </Nav>
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>)}

      <div className="main-container">
        <Suspense fallback={<Loading/>}>
          <Switch>
            <Route path={`${match.path}/dashboard/:meeting_id`} component={Dashboard} />
            <Route path={`${match.path}/dashboard`} component={Dashboard} />
            <Route path={`${match.path}/calendar`} component={Calendar} />
            <Route path={`${match.path}/tasks`} component={OpenTasks} />
            <Route path={`${match.path}/requests/:request_id`} component={Requests} />
            <Route path={`${match.path}/requests`} component={Requests} />
            <Route path={`${match.path}/settings`} component={Settings} />
            <Route path={`${match.path}/search/:term`} component={SearchResults} />
            <Route path={`${match.path}/meeting`} component={Meeting} />
            <Redirect from="/" to={`${match.path}/dashboard`} />
          </Switch>
        </Suspense>
      </div>
    </>
  );
};

export default MainContainer;
