import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, InputGroup, FormControl } from 'react-bootstrap';

type SearchFormData = {
  searchTerm: string;
};

const SearchClient: React.FC = () => {
  const { t } = useTranslation('main_menu');
  const { register, handleSubmit } = useForm();
  const history = useHistory();

  function searchClients(props: SearchFormData) {
    history.push('/app/search/' + props.searchTerm);
  }

  return (
    <Form inline className="search-form my-2 my-lg-0 ml-4" onSubmit={handleSubmit(searchClients)}>
      <InputGroup>
        <FormControl
          {...register("searchTerm")}
          style={{ width: '300px' }}
          type="text"
          id="searchTerm"
          name="searchTerm"
          placeholder={t('Client Search')}
          className="sm-2"
        />
        <InputGroup.Append onClick={handleSubmit(searchClients)}>
          <InputGroup.Text>
            <i className="mp-search" />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
};

export default SearchClient;
