import { handleCaughtError } from '_helpers';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from 'app/store';

import { AccountResponseMessage, OrganizationUser } from 'server/api';
import { RootState } from '../../app/rootReducer';

import { userManagementService } from './userManagementService';
import { AsyncRequest, RESPONSE_CODE, startLoading, loadingFailed } from '../asyncRequest';
import { IUserInviteForm } from 'models/interfaces';
import { logout } from 'features/auth/authSlice';

interface OrganizationUserState extends AsyncRequest {
  users: OrganizationUser[];
  inviteColleagueStatus: 'idle' | 'loading' | 'failed';
  inviteColleagueError: string;
}

const initialState: OrganizationUserState = {
  loading: false,
  error: null,
  users: [],
  inviteColleagueStatus: 'idle',
  inviteColleagueError: '',
};

export const inviteColleague = createAsyncThunk<OrganizationUser | undefined, IUserInviteForm, { state: RootState; dispatch: AppDispatch }>(
  'userManagement/inviteColleague',
  async (userDetails: IUserInviteForm, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.authentication.usertoken;
      if (!authToken) {
        return;
      }

      const response: AccountResponseMessage = await userManagementService.addNewOrgUser(authToken, userDetails);

      if (response.code === RESPONSE_CODE.SUCCESS && response.organization_data) {
        return response.user;
      } else {
        rejectWithValue(response.message || 'Something went wrong');
      }
      return response;
    } catch (err) {
      handleCaughtError(err);
      throw err;
    }
  }
);

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    requestStart: startLoading,
    requestFailed: loadingFailed,
    setAddUserStatus(state, { payload }: PayloadAction<OrganizationUser[]>) {},
    setOrganizationUsers(state, { payload }: PayloadAction<OrganizationUser[]>) {
      state.users = payload;
      state.loading = false;
      state.error = null;
    },
    removeUserFromOrganization(state, { payload }: PayloadAction<string>) {
      state.users = state.users.filter((user) => user.user_id !== payload);
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(inviteColleague.pending, (state, action) => {
      state.inviteColleagueStatus = 'loading';
      state.inviteColleagueError = '';
    });
    builder.addCase(inviteColleague.fulfilled, (state, action) => {
      state.inviteColleagueStatus = 'idle';
      state.inviteColleagueError = '';
      if (action.payload) {
        state.users.push(action.payload);
      }
    });
    builder.addCase(inviteColleague.rejected, (state, action) => {
      state.inviteColleagueStatus = 'failed';
      state.inviteColleagueError = action.error.message || '';
    });
  },
});

export const { requestStart, requestFailed, setOrganizationUsers, removeUserFromOrganization } = userManagementSlice.actions;
export default userManagementSlice.reducer;

export const getOrganizationUsers = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      dispatch(logout());
      return;
    }

    dispatch(requestStart());
    const response: AccountResponseMessage = await userManagementService.getOrganizationUsers(authToken);
    if (response.code === RESPONSE_CODE.SUCCESS && response.users) {
      dispatch(setOrganizationUsers(response.users));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const deactivateUser = (userId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      dispatch(logout());
      return;
    }

    dispatch(requestStart());

    const response: AccountResponseMessage = await userManagementService.deactivateUser(authToken, userId);

    if (response.code === RESPONSE_CODE.SUCCESS && response.organization_data) {
      dispatch(removeUserFromOrganization(userId));
      dispatch(getOrganizationUsers());
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};
//async function sendInvitation(authToken: string, email: string, language: string, userId: string) {

export const sendInvitation = (email: string, language: string, user_id: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      dispatch(logout());
      return;
    }

    dispatch(requestStart());

    const response: AccountResponseMessage = await userManagementService.sendInvitation(authToken, email, language, user_id);

    if (response.code === RESPONSE_CODE.SUCCESS && response.organization_data) {
      dispatch(getOrganizationUsers());
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};
