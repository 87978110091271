import { CalendarApi, CalendarResponseMessage, Meeting, MeetingType, MeetingNote, MeetingChatMessage, MeetingParticipant, AvailabilityRequest } from 'server';
import {startOfToday, endOfToday} from 'date-fns';
import { handleErrorResponse } from '_helpers';

const calendarApi = new CalendarApi();

async function getOpenMeetingRequests(authToken: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getOpenMeetingRequests(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export async function getUserMeetings(authToken: string, user_id: string, fromDate:Date = startOfToday(), toDate: Date = endOfToday()): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingsForUser(authToken, user_id, fromDate.toISOString(), toDate.toISOString());
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export async function deleteMeetingRequest(authToken: string, requestId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.deleteMeetingRequest(authToken, requestId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export async function getOrgMeetings(authToken: string, fromDate:Date = startOfToday(), toDate: Date = endOfToday()): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingsForOrg(authToken, fromDate.toISOString(), toDate.toISOString());
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getRequestById(authToken: string, requestId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getRequest(authToken, requestId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingById(authToken: string,  meetingId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeeting(authToken, meetingId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteMeeting(authToken: string,  meetingId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.deleteMeeting(authToken, meetingId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getCombinedAvailability(authToken: string, userIds: string[], durationInMinutes: number, monthsForward: number = 6) {
  try {
    const avlReq: AvailabilityRequest = {
      userIDs: userIds,
      limit_search_date: {
        Months: monthsForward
      },
      meeting_duration: durationInMinutes + ' minutes'
    }
    const { data } = await calendarApi.getAvailabilities(authToken, avlReq);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function createMeeting(authToken: string, startTime: string, endTime: string, requestId: string, clientUserId: string, participants: string[], meetingType?: MeetingType): Promise<CalendarResponseMessage> {
  try {
    const meetingParticipants: MeetingParticipant[] =  participants.map(p => ({
      "participant_id": p
    }));

    const meeting: Meeting = {
      jitsi_room: clientUserId,
      participants: meetingParticipants,
      request_id: requestId,
      start_time: startTime,
      end_time: endTime,
      type: meetingType,
    };

    const { data } = await calendarApi.createNewMeeting(authToken, meeting);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function updateMeetingTime(authToken: string, meetingId: string, startTime: string, endTime: string): Promise<CalendarResponseMessage> {
  try {
    const meeting: Meeting = {
      start_time: startTime,
      end_time: endTime
    };

    const { data } = await calendarApi.updateMeeting(authToken, meetingId, meeting);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingChat(authToken: string, meetingId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingChat(authToken, meetingId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingNotes(authToken: string, meetingId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingNotes(authToken, meetingId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingToDo(authToken: string, meetingId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingTodos(meetingId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addMeetingNote(authToken: string, meetingId: string, clientId: string, writerId: string, note: string): Promise<CalendarResponseMessage> {
  try {
    const meetingNote: MeetingNote = {
      note: note,
      meeting_id: meetingId,
      client_id: clientId,
      created_by: writerId,
    };
    const { data } = await calendarApi.addMeetingNote(authToken, meetingNote);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addMeetingTodo(authToken: string, meetingId: string, clientId: string, item: string, assignee: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.addMeetingTodos(meetingId, authToken, item, clientId, assignee);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function updateMeetingTodos(
  authToken: string,
  meetingId: string,
  todoId: string,
  item: string,
  isDone: boolean,
  assignee: string
): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.updateMeetingTodos(meetingId, todoId, authToken, item, isDone, assignee);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteMeetingTodos(authToken: string, meetingId: string, todoId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.deleteMeetingTodos(meetingId, todoId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getChatMessages(authToken: string, meetingId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingChat(authToken, meetingId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getChatHistoryMessages(authToken: string, clientId: string, from: Date, to: Date): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getChatHistoryForUser(authToken, clientId, from.toISOString(), to.toISOString());
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}


async function sendChatMessage(authToken: string, chatObj: MeetingChatMessage): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.addMeetingChatMessage(authToken, chatObj);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteMeetingChatMessage(authToken: string, id: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.deleteMeetingChatMessages(authToken, id);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}
async function updateMeetingChatMessage(authToken: string, chatObj: MeetingChatMessage): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.updateMeetingChatMessage(authToken, chatObj.id || '', chatObj);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}
async function updateMeetingNote(authToken: string, noteId: string, noteText: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.updateMeetingNote(authToken, noteId, noteText);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingTypes(authToken: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingTypes(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addMeetingType(authToken: string, name: string, slices: number): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.addMeetingType(authToken, name, 0, slices, 0);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteMeetingType(authToken: string, id: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.deleteMeetingType(id, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function inviteParticipant(authToken: string, meetingId: string, participantId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.addMeetingParticipants(authToken, meetingId, participantId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function setParticipantStatus(authToken: string, meetingId: string, participantId: string, statusId: number): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.updateMeetingParticipantRSVP(authToken, meetingId, participantId, statusId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}


const exportedMethods = {
  getCombinedAvailability,
  createMeeting,
  updateMeetingTime,
  deleteMeeting,

  getOpenMeetingRequests,
  getRequestById,
  getMeetingById,
  getUserMeetings,

  deleteMeetingRequest,  

  // Notes:
  getMeetingNotes,
  addMeetingNote,
  updateMeetingNote,

  // ToDos:
  getMeetingToDo,
  addMeetingTodo,
  updateMeetingTodos,
  deleteMeetingTodos,

  // Chat
  getMeetingChat,
  sendChatMessage,
  getChatMessages,
  getChatHistoryMessages,
  deleteMeetingChatMessage,
  updateMeetingChatMessage,

  // Meeting Types:
  addMeetingType,
  getMeetingTypes,
  deleteMeetingType,

  inviteParticipant,
  setParticipantStatus
};

export default exportedMethods;