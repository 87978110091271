import { handleCaughtError } from '_helpers';
import { CalendarApi, CalendarResponseMessage, OrgToDo } from '../../server';

const calendarApi = new CalendarApi();

function handleErrorResponse(e: any) {
  handleCaughtError(e);
  const error = e?.response?.data?.error || e.message || e.statusText;
  if (error) {
    return Promise.reject(error);
  }

  return Promise.reject();
}

async function getMyTodos(authToken: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMyTodos(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getClientTodos(authToken: string, clientId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getClientTodos(clientId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getMeetingToDo(authToken: string, meetingId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.getMeetingTodos(meetingId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addMeetingTodo(authToken: string, meetingId: string, item: string, clientId: string, assignee: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.addMeetingTodos(meetingId, authToken, item, clientId, assignee);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addNewTodo(authToken: string, todo: OrgToDo): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.addNewTodo(authToken, todo);

    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function updateTodo(authToken: string, todo: OrgToDo): Promise<CalendarResponseMessage> {
  try {
    if (!todo.id) {
      throw new Error('ID is missing in the task to update');
    }
    const { data } = await calendarApi.updateTodos(todo.id, authToken, todo);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteTodo(authToken: string, todoId: string): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.deleteTodos(todoId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function setTodoDone(authToken: string, todoId: string, isDone: boolean): Promise<CalendarResponseMessage> {
  try {
    const { data } = await calendarApi.setTodoDone(todoId, authToken, isDone);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export { getClientTodos, getMeetingToDo, addNewTodo, addMeetingTodo, updateTodo, deleteTodo, getMyTodos, setTodoDone };
