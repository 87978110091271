import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

import { RootState } from 'app/rootReducer';
import { validations as  validationRules} from '_helpers';
import { performLogin } from '../../authSlice';

type LoginFormData = {
  email: string;
  password: string;
  remember: boolean;
};

const LoginForm = () => {
  const { t } = useTranslation(['login', 'error-messages']);
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>();

  const errMessage = useSelector((state: RootState) => state.authentication.error);

  function authenticate_user(props: LoginFormData) {
    const { email, password, remember } = props;
    dispatch(performLogin(email, password, remember));
  }

  return (
    <Form className="login-form p32" onSubmit={handleSubmit(authenticate_user)}>
      <Form.Group as={Col}>
        <Form.Label className={errors?.email ? 'error-text' : ''} htmlFor="email">
          <Trans i18nKey="email" ns="login">
            Email address
          </Trans>
        </Form.Label>
        <Form.Control
          autoFocus
          required
          type="text"
          className={errors?.email ? 'error-input' : ''}
          {...register("email", validationRules.email)}
          id="email"
          name="email"
          placeholder={t('email')}
          aria-invalid={errors.email ? "true" : "false"}
        />
        {errors?.email && (
          <Form.Text muted>
            <Trans i18nKey="email_error_message" ns="login">
              {errors?.email?.message && <span role="alert" className="error-text">{t("error-messages:" + errors?.email?.message)}</span>}
            </Trans>
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group as={Col}>
        <Form.Label className={errors?.password ? 'error-text' : ''} htmlFor="password">
          <Trans i18nKey="password" ns="login">
            Password
          </Trans>
        </Form.Label>
        <Form.Control
          required
          type="password"
          autoComplete={"off"}
          {...register("password", validationRules.password)}
          className={errors?.password ? 'error-input' : ''}
          id="password"
          name="password"
          placeholder={t('password')}
          aria-invalid={errors.password ? "true" : "false"}
        />
        {errors?.password && (
          <Form.Text id="passwordHelpBlock" muted>
            {errors?.password?.message && <span role="alert" className="error-text">{t("error-messages:" + errors?.password?.message)}</span>}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group className="col" controlId="loginRememberMe">
        <Form.Check className="pl0" type="checkbox" id="remember">
          <Form.Check.Label className="custom-control custom-checkbox pl0 d-flex">
            <Form.Check.Input {...register("remember")} name="remember" className="custom-control-input" />
            <span className="custom-control-indicator"/>
            <span className="custom-control-description ml-2">{t('remember me')}</span>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
      
      <Form.Group className="col">
        <Button variant="primary" type="submit">
          <Trans i18nKey="login" ns="login">
            Login
          </Trans>
        </Button>
      </Form.Group>

      {errMessage && <p className="mt-4 ml12 error-text">{t(errMessage)}</p>}

      <Link className="ml12" to="/reset-password">
        <Trans i18nKey="forgot password link" ns="login">
          Forgot your password?
        </Trans>
      </Link>
    </Form>
  );
};

export default LoginForm;