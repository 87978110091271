export const validations = {
  email: {
    required: 'Required',
    maxLength: {
      value: 255,
      message: 'Maximum 255 charachters'
    },
    pattern: {
      value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
      message: 'invalid email address',
    },
  },
  password: {
    required: 'This field is required',
    minLength: { value: 8, message: 'Minimum 8 characters' },
    maxLength: { value: 24, message: 'Maximum 24 charachters' },
  },
  first_name: { required: 'Required', minLength: { value: 2, message: 'Minimum 2 charracters' }, maxLength: { value: 30, message: 'Maximum 30 charachters.' } },
  last_name: { required: 'Required', minLength: { value: 2, message: 'Minimum 2 charracters' }, maxLength: { value: 30, message: 'Maximum 30 charachters.' } },

  phone_number: {required: false, min: { value:1000000, message: 'Bitte geben sie ihre Telefonnummer ein'}, max: {value:9999999999, message: 'Bitte geben Sie die richtige Telefonnummer ein!'}},

  requiredLimitLength30: { required: true, minLength: { value: 2, message: 'Minimum 2 charracters' }, maxLength: { value: 30, message: 'Maximum 30 charachters.' } },
  requiredLimitLength50: { required: true, minLength: { value: 2, message: 'Minimum 2 charracters' }, maxLength: { value: 50, message: 'Maximum 30 charachters.' } }
};