import { Button } from 'antd';
import React, {ReactNode, useEffect, useState} from 'react';
import './Box.scss';

type BoxProps = { children: any; title: ReactNode; activeVariant: "lime" | "blue" | "teal" | "gold" | "danger"; icon?: string; innerPaddingClassName?: string; style?: React.CSSProperties, onClose?: ()=>void };

const Box: React.FC<BoxProps> = ({ children, title, activeVariant, icon, innerPaddingClassName = 'inner-pad', style, onClose}: BoxProps) => {
  const [active, setActive] = useState(false);

  const handleClickInside = () => {
    setActive(true);
  };

  const handleClickOutside = () => {
    setActive(false);
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  })

  return (
    <div  className={`box-container ${active && activeVariant}`} onFocus={handleClickInside}>
      <div className="box-title d-flex">
        <div>
          {icon && <i className={`mp-${icon}`} style={{marginRight: "5px"}}></i>}
          {title}
        </div>
        {onClose &&
          <Button  onClick={onClose} type="text" shape="circle" icon={<i className={`mp-close `} ></i>} />}
      </div>
      <div style={style} className={`box-content ${innerPaddingClassName}`}>
        {children}
      </div>
    </div>
  );
};

export default Box;
