import * as React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from 'app/rootReducer';
import { isExpired, setIsLoggedIn } from "../features/auth/authSlice";
import {useEffect} from "react";

interface PrivateRouteProps extends RouteProps {
  component: React.FC;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const dispatch = useDispatch();
  const { expiry, loading,  isLoggedIn, errorOnAuth, userid /* , roles  */ } = useSelector((state: RootState) => state.authentication);

  useEffect(() => {
      if (!loading) {
        const hasSessionExpired = expiry && isExpired(expiry);
        if ( errorOnAuth || !isLoggedIn || hasSessionExpired || !userid || userid === '') {
          dispatch(setIsLoggedIn(false));
        }
      }
  }, [loading, isLoggedIn, expiry, errorOnAuth, userid, dispatch]);

  return (
    <>
      { !isLoggedIn ?
        (<Redirect to="/login" />) :
        <Route {...rest} component={Component}  />}
    </>
  )
};

export default PrivateRoute;
