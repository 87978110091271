import { captureException } from "@sentry/react";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';

import { userService } from './interface';
import { AsyncRequest, RESPONSE_CODE, startLoading, loadingFailed } from '../asyncRequest';

/*
Checks the authentication hash code for validity
Updates the user's password after a reset request.
*/

interface HashState extends AsyncRequest {
  isHashValid: boolean | null;
}

const initialState: HashState = {
  isHashValid: null,
  loading: false,
  error: null,
};

const updatePasswordSlice = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {
    validateHashStart: startLoading,
    validateHashFailure: loadingFailed,
    validateHashSuccess(state, action: PayloadAction<number>) {
      const code = action.payload;
      state.isHashValid = code === RESPONSE_CODE.SUCCESS;
      state.loading = false;
      state.error = null;
    },
  },
});

export const { validateHashStart, validateHashSuccess, validateHashFailure } = updatePasswordSlice.actions;
export default updatePasswordSlice.reducer;

export const checkHashValidation = (hash: string): AppThunk => async (dispatch) => {
  try {
    dispatch(validateHashStart());
    const responseCode: number = await userService.validateHash(hash);
    dispatch(validateHashSuccess(responseCode));
  } catch (err) {
    captureException(err, {
      extra: {hash: hash}
    });
    dispatch(validateHashFailure(err));
  }
};

// ToDo: move to use the slice
// export const updatePassword = (hash: string, newPassword: string): AppThunk => async (dispatch) => {
//   try {
//     if (!newPassword) {
//       return;
//     }
//     dispatch(validateHashStart());
//     const responseCode: AuthenticateResponse = await userService.updatePassword(hash, newPassword);
//     dispatch(validateHashSuccess(responseCode));
//   } catch (err) {
//     dispatch(validateHashFailure(err));
//   }
// };
// 