import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app/rootReducer';
import useWebSocket from 'react-use-websocket';
import { WS_URL, SocketMessage } from './consts';
import { MeetingChatMessage } from 'server';
import { appendChatMessages } from 'features/schedule/chatSlice';

export const WSHandler = () => {
  const { usertoken } = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch();

  const { lastMessage } = useWebSocket(WS_URL, {
    share: true,
    retryOnError: true,
    reconnectAttempts: 50,
    reconnectInterval: 5000,
    queryParams: { usertoken: usertoken ?? '' },
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent);
      return true;
    }
  });

  useEffect(() => {
    if (lastMessage) {
      const msgs: string[] = lastMessage?.data.split('\n');
      try {
        msgs.forEach((msg) => {
          const payload: SocketMessage = JSON.parse(msg);

          console.log('received msg: ', payload);

          switch (payload.action) {
            case 'send-message':
              if (payload.chat_message) {
                let chatMsg: MeetingChatMessage = payload.chat_message;
                dispatch(appendChatMessages([chatMsg]));
              }
              break;
          }
        });
      } catch (err) {
        console.log(err, lastMessage);
      }
    }
  }, [dispatch, lastMessage]);

  return <></>;
};
