import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

function NoMatch() {
  let location = useLocation();

  return (
    <div className="p-5">
      <div className="p-5 mb-4 bg-warning rounded-3">
        <h1>Oops...</h1>
        <h3>
          The url path <code>{location.pathname}</code> does not exist.
        </h3>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/">
            <Button variant="primary">Take me back home.</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NoMatch;
