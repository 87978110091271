/* tslint:disable */
/* eslint-disable */
/**
 * MEETPLANS.
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@decid.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountAddress
 */
export interface AccountAddress {
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    country_code?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    zip?: string;
}
/**
 * used for role list for user
 * @export
 * @interface AccountPermission
 */
export interface AccountPermission {
    /**
     * 
     * @type {string}
     * @memberof AccountPermission
     */
    permission_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPermission
     */
    permission_name?: string;
}
/**
 * 
 * @export
 * @interface AccountResponseMessage
 */
export interface AccountResponseMessage {
    /**
     * 
     * @type {number}
     * @memberof AccountResponseMessage
     */
    code?: number;
    /**
     * hash validity
     * @type {boolean}
     * @memberof AccountResponseMessage
     */
    hashvalid?: boolean;
    /**
     * 
     * @type {Array<Label>}
     * @memberof AccountResponseMessage
     */
    labels?: Array<Label>;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseMessage
     */
    message?: string;
    /**
     * 
     * @type {OrgData}
     * @memberof AccountResponseMessage
     */
    organization_data?: OrgData;
    /**
     * All Organization User Accounts
     * @type {string}
     * @memberof AccountResponseMessage
     */
    orguserhash?: string;
    /**
     * In: body
     * @type {string}
     * @memberof AccountResponseMessage
     */
    role?: string;
    /**
     * In: body
     * @type {Array<AccountRole>}
     * @memberof AccountResponseMessage
     */
    roles?: Array<AccountRole>;
    /**
     * 
     * @type {OrganizationUser}
     * @memberof AccountResponseMessage
     */
    user?: OrganizationUser;
    /**
     * 
     * @type {OrganizationUserPersonalData}
     * @memberof AccountResponseMessage
     */
    user_personal_data?: OrganizationUserPersonalData;
    /**
     * All Permissions for user
     * @type {string}
     * @memberof AccountResponseMessage
     */
    userpermission?: string;
    /**
     * 
     * @type {Array<OrganizationUser>}
     * @memberof AccountResponseMessage
     */
    users?: Array<OrganizationUser>;
}
/**
 * used for role list for user
 * @export
 * @interface AccountRole
 */
export interface AccountRole {
    /**
     * 
     * @type {string}
     * @memberof AccountRole
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRole
     */
    role_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRole
     */
    role_name?: string;
}
/**
 * 
 * @export
 * @interface AccountRolePermission
 */
export interface AccountRolePermission {
    /**
     * 
     * @type {string}
     * @memberof AccountRolePermission
     */
    permission_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRolePermission
     */
    role_id?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRolePermission
     */
    role_permission_id?: string;
}
/**
 * 
 * @export
 * @interface Appointment
 */
export interface Appointment {
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    end?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    meeting_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    series_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    series_status?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    user_id?: string;
}
/**
 * 
 * @export
 * @interface AuthenticationBiz
 */
export interface AuthenticationBiz {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationBiz
     */
    displayname?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationBiz
     */
    oid?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationBiz
     */
    sitename?: string;
}
/**
 * 
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    apitoken?: string;
    /**
     * The response code In: body
     * @type {number}
     * @memberof AuthenticationResponse
     */
    code?: number;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    email?: string;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    expiry?: string;
    /**
     * In: body
     * @type {Array<MeetingRequest>}
     * @memberof AuthenticationResponse
     */
    meeting_request?: Array<MeetingRequest>;
    /**
     * The response message corresponding to the Code In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    message?: string;
    /**
     * 
     * @type {OrgData}
     * @memberof AuthenticationResponse
     */
    organization_data?: OrgData;
    /**
     * 
     * @type {OrganizationUser}
     * @memberof AuthenticationResponse
     */
    organization_user?: OrganizationUser;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    organizationid?: string;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    organizationtoken?: string;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    role?: string;
    /**
     * In: body
     * @type {Array<AccountRole>}
     * @memberof AuthenticationResponse
     */
    roles?: Array<AccountRole>;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    sscaccesstoken?: string;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    userid?: string;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    userrefreshtoken?: string;
    /**
     * In: body
     * @type {string}
     * @memberof AuthenticationResponse
     */
    usertoken?: string;
}
/**
 * 
 * @export
 * @interface AvailabilityRequest
 */
export interface AvailabilityRequest {
    /**
     * 
     * @type {Interval}
     * @memberof AvailabilityRequest
     */
    limit_search_date?: Interval;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityRequest
     */
    meeting_duration?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailabilityRequest
     */
    userIDs?: Array<string>;
}
/**
 * 
 * @export
 * @interface CalendarResponseMessage
 */
export interface CalendarResponseMessage {
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof CalendarResponseMessage
     */
    appointments?: Array<Appointment>;
    /**
     * 
     * @type {Array<MeetingChatMessage>}
     * @memberof CalendarResponseMessage
     */
    chat_messages?: Array<MeetingChatMessage>;
    /**
     * 
     * @type {number}
     * @memberof CalendarResponseMessage
     */
    code?: number;
    /**
     * 
     * @type {Array<MeetingNote>}
     * @memberof CalendarResponseMessage
     */
    meeting_notes?: Array<MeetingNote>;
    /**
     * 
     * @type {Array<MeetingRequest>}
     * @memberof CalendarResponseMessage
     */
    meeting_requests?: Array<MeetingRequest>;
    /**
     * 
     * @type {Array<MeetingStatusType>}
     * @memberof CalendarResponseMessage
     */
    meeting_status?: Array<MeetingStatusType>;
    /**
     * 
     * @type {Array<MeetingToDo>}
     * @memberof CalendarResponseMessage
     */
    meeting_todos?: Array<MeetingToDo>;
    /**
     * 
     * @type {Array<MeetingType>}
     * @memberof CalendarResponseMessage
     */
    meeting_types?: Array<MeetingType>;
    /**
     * 
     * @type {Array<Meeting>}
     * @memberof CalendarResponseMessage
     */
    meetings?: Array<Meeting>;
    /**
     * 
     * @type {string}
     * @memberof CalendarResponseMessage
     */
    message?: string;
    /**
     * 
     * @type {Array<ReportChartWeekData>}
     * @memberof CalendarResponseMessage
     */
    report_chart?: Array<ReportChartWeekData>;
    /**
     * 
     * @type {WeeklyReportData}
     * @memberof CalendarResponseMessage
     */
    report_data?: WeeklyReportData;
    /**
     * 
     * @type {Array<Series>}
     * @memberof CalendarResponseMessage
     */
    series?: Array<Series>;
    /**
     * 
     * @type {Array<Slice>}
     * @memberof CalendarResponseMessage
     */
    slices?: Array<Slice>;
    /**
     * 
     * @type {Array<Slot>}
     * @memberof CalendarResponseMessage
     */
    slot?: Array<Slot>;
    /**
     * 
     * @type {Array<OrgToDo>}
     * @memberof CalendarResponseMessage
     */
    todos?: Array<OrgToDo>;
}
/**
 * 
 * @export
 * @interface DataResponseMessage
 */
export interface DataResponseMessage {
    /**
     * 
     * @type {number}
     * @memberof DataResponseMessage
     */
    code?: number;
    /**
     * 
     * @type {Array<DownloadFileInfo>}
     * @memberof DataResponseMessage
     */
    files?: Array<DownloadFileInfo>;
    /**
     * 
     * @type {string}
     * @memberof DataResponseMessage
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DataResponseMessage
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface DownloadFileInfo
 */
export interface DownloadFileInfo {
    /**
     * 
     * @type {string}
     * @memberof DownloadFileInfo
     */
    download_url?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileInfo
     */
    file_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileInfo
     */
    full_name?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileInfo
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileInfo
     */
    user_id?: string;
}
/**
 * 
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    file_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    full_name?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    user_id?: string;
}
/**
 * 
 * @export
 * @interface Interval
 */
export interface Interval {
    /**
     * 
     * @type {number}
     * @memberof Interval
     */
    Days?: number;
    /**
     * 
     * @type {number}
     * @memberof Interval
     */
    Microseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof Interval
     */
    Months?: number;
    /**
     * 
     * @type {number}
     * @memberof Interval
     */
    Status?: number;
}
/**
 * Label -
 * @export
 * @interface Label
 */
export interface Label {
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    label_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Label
     */
    org_id?: string;
}
/**
 * 
 * @export
 * @interface Meeting
 */
export interface Meeting {
    /**
     * 
     * @type {number}
     * @memberof Meeting
     */
    actual_duration?: number;
    /**
     * 
     * @type {Array<MeetingChatMessage>}
     * @memberof Meeting
     */
    chat_messages?: Array<MeetingChatMessage>;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    end_time?: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    jitsi_password?: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    jitsi_room?: string;
    /**
     * 
     * @type {Array<MeetingNote>}
     * @memberof Meeting
     */
    notes?: Array<MeetingNote>;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    org_id?: string;
    /**
     * 
     * @type {Array<MeetingParticipant>}
     * @memberof Meeting
     */
    participants?: Array<MeetingParticipant>;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    request_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    start_time?: string;
    /**
     * 
     * @type {MeetingStatusType}
     * @memberof Meeting
     */
    status?: MeetingStatusType;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    status_type_id?: string;
    /**
     * 
     * @type {Array<MeetingToDo>}
     * @memberof Meeting
     */
    todos?: Array<MeetingToDo>;
    /**
     * 
     * @type {MeetingType}
     * @memberof Meeting
     */
    type?: MeetingType;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    type_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Meeting
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface MeetingChatMessage
 */
export interface MeetingChatMessage {
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    file_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    meeting_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    message_type?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    sender_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    sender_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    sender_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingChatMessage
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface MeetingNote
 */
export interface MeetingNote {
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    created_by?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    created_by_first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    created_by_last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    meeting_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingNote
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface MeetingParticipant
 */
export interface MeetingParticipant {
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    lang?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    meeting_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    participant_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    phone?: string;
    /**
     * 
     * @type {Array<AccountRole>}
     * @memberof MeetingParticipant
     */
    roles?: Array<AccountRole>;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    rsvp_status?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingParticipant
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface MeetingRequest
 */
export interface MeetingRequest {
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    end_time?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    first_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingRequest
     */
    has_meeting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    meeting_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    meeting_type?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    request_status?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    request_type?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRequest
     */
    user_id?: string;
}
/**
 * 
 * @export
 * @interface MeetingStatusType
 */
export interface MeetingStatusType {
    /**
     * 
     * @type {string}
     * @memberof MeetingStatusType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingStatusType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingStatusType
     */
    org_id?: string;
}
/**
 * 
 * @export
 * @interface MeetingToDo
 */
export interface MeetingToDo {
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    assignee?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    created_by?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingToDo
     */
    is_done?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    item?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    meeting_id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingToDo
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface MeetingType
 */
export interface MeetingType {
    /**
     * 
     * @type {string}
     * @memberof MeetingType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingType
     */
    org_id?: string;
    /**
     * 
     * @type {number}
     * @memberof MeetingType
     */
    post_slices?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingType
     */
    pre_slices?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingType
     */
    slices?: number;
}
/**
 * 
 * @export
 * @interface OrgData
 */
export interface OrgData {
    /**
     * 
     * @type {AccountAddress}
     * @memberof OrgData
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    base_url_client?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    base_url_consultant?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    favicon_url?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    home_path?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    org_name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrgData
     */
    settings?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OrgData
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface OrgToDo
 */
export interface OrgToDo {
    /**
     * 
     * @type {OrganizationUser}
     * @memberof OrgToDo
     */
    assignee?: OrganizationUser;
    /**
     * 
     * @type {OrganizationUser}
     * @memberof OrgToDo
     */
    client?: OrganizationUser;
    /**
     * 
     * @type {string}
     * @memberof OrgToDo
     */
    created_at?: string;
    /**
     * 
     * @type {OrganizationUser}
     * @memberof OrgToDo
     */
    created_by?: OrganizationUser;
    /**
     * 
     * @type {string}
     * @memberof OrgToDo
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgToDo
     */
    due_date?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgToDo
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrgToDo
     */
    is_done?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrgToDo
     */
    item?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgToDo
     */
    meeting_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgToDo
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface OrganizationSubdivisions
 */
export interface OrganizationSubdivisions {
    /**
     * 
     * @type {string}
     * @memberof OrganizationSubdivisions
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSubdivisions
     */
    deleted_at?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSubdivisions
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSubdivisions
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSubdivisions
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSubdivisions
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationSubdivisions
     */
    user_id?: string;
}
/**
 * 
 * @export
 * @interface OrganizationUser
 */
export interface OrganizationUser {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    activated_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUser
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    avatar_url?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    current_status?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    deactivated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    identifier_type?: string;
    /**
     * 
     * @type {Array<Label>}
     * @memberof OrganizationUser
     */
    labels?: Array<Label>;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    last_login?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    online_status?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    org_lang?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    org_name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    phone_country_code?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    pref_lang?: string;
    /**
     * 
     * @type {Array<OrganizationUserRole>}
     * @memberof OrganizationUser
     */
    roles?: Array<OrganizationUserRole>;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    social_sec_no?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    subdivision_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUser
     */
    translator_needed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    user_id?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrganizationUser
     */
    user_params?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface OrganizationUserPersonalData
 */
export interface OrganizationUserPersonalData {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    avatar_url?: string;
    /**
     * UserID            string `json:\"user_id,omitempty\"`
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    identifier_type?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    online_status?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    phone_country_code?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    pref_lang?: string;
    /**
     * 
     * @type {Array<AccountRole>}
     * @memberof OrganizationUserPersonalData
     */
    roles?: Array<AccountRole>;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserPersonalData
     */
    social_sec_no?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OrganizationUserPersonalData
     */
    user_params?: { [key: string]: string; };
}
/**
 * used for role list for user
 * @export
 * @interface OrganizationUserRole
 */
export interface OrganizationUserRole {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserRole
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserRole
     */
    role_id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUserRole
     */
    user_id?: string;
}
/**
 * 
 * @export
 * @interface RepInfo
 */
export interface RepInfo {
    /**
     * 
     * @type {string}
     * @memberof RepInfo
     */
    cnt?: string;
    /**
     * 
     * @type {string}
     * @memberof RepInfo
     */
    org_id?: string;
    /**
     * 
     * @type {number}
     * @memberof RepInfo
     */
    status_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RepInfo
     */
    week_id?: string;
}
/**
 * 
 * @export
 * @interface ReportChartWeekData
 */
export interface ReportChartWeekData {
    /**
     * 
     * @type {number}
     * @memberof ReportChartWeekData
     */
    deleted_req?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportChartWeekData
     */
    meeting_mins?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportChartWeekData
     */
    total_meetings?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportChartWeekData
     */
    total_req?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportChartWeekData
     */
    unhandled_req?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportChartWeekData
     */
    week_year?: string;
}
/**
 * 
 * @export
 * @interface Series
 */
export interface Series {
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    deleted_at?: string;
    /**
     * 
     * @type {Interval}
     * @memberof Series
     */
    duration?: Interval;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    org_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    repeat_until?: string;
    /**
     * 
     * @type {Interval}
     * @memberof Series
     */
    repetition_interval?: Interval;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    series_type?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Series
     */
    user_id?: string;
}
/**
 * 
 * @export
 * @interface Slice
 */
export interface Slice {
    /**
     * 
     * @type {string}
     * @memberof Slice
     */
    series_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Slice
     */
    start?: string;
}
/**
 * 
 * @export
 * @interface Slot
 */
export interface Slot {
    /**
     * 
     * @type {Array<Slice>}
     * @memberof Slot
     */
    slices?: Array<Slice>;
}
/**
 * A UUID is a 128 bit (16 byte) Universal Unique IDentifier as defined in RFC 4122.
 * @export
 * @interface UUID
 */
export interface UUID extends Array<number> {
}
/**
 * 
 * @export
 * @interface WeeklyReportData
 */
export interface WeeklyReportData {
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportData
     */
    total_reqs_opened_last_week?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportData
     */
    total_reqs_opened_this_week?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportData
     */
    total_requests?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportData
     */
    total_unhandled_requests?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportData
     */
    unhandled_requests_created_last_week?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportData
     */
    unhandled_requests_created_this_week?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportData
     */
    weekly_total_meetings_from_requests?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportData
     */
    weekly_total_scheduled_meeting_minutes?: number;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new User based on the invite hash
         * @param {string} hash The hash from the invite email
         * @param {string} identifier user identifier
         * @param {string} identifierType Identifier type
         * @param {string} password User password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser: async (hash: string, identifier: string, identifierType: string, password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('activateUser', 'hash', hash)
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('activateUser', 'identifier', identifier)
            // verify required parameter 'identifierType' is not null or undefined
            assertParamExists('activateUser', 'identifierType', identifierType)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('activateUser', 'password', password)
            const localVarPath = `/accounts/public/user/activate/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (identifier !== undefined) { 
                localVarFormParams.set('identifier', identifier as any);
            }
    
            if (identifierType !== undefined) { 
                localVarFormParams.set('identifier_type', identifierType as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add label to user.
         * @param {string} userId user ID
         * @param {string} authorization an authorization header
         * @param {string} labelID label ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLabelToUser: async (userId: string, authorization: string, labelID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addLabelToUser', 'userId', userId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addLabelToUser', 'authorization', authorization)
            // verify required parameter 'labelID' is not null or undefined
            assertParamExists('addLabelToUser', 'labelID', labelID)
            const localVarPath = `/accounts/user/{userId}/user-labels`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (labelID !== undefined) { 
                localVarFormParams.set('labelID', labelID as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a new user to the organization, and send the user an email invitation
         * @param {string} authorization an authorization header
         * @param {string} email The email of the user to invite
         * @param {string} language The language to be used when sending
         * @param {string} firstName Users first name
         * @param {string} lastName Users last name
         * @param {string} roleID Users role
         * @param {string} subDivID The subdivision of an organization that the user belongs to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewOrgUser: async (authorization: string, email: string, language: string, firstName: string, lastName: string, roleID: string, subDivID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addNewOrgUser', 'authorization', authorization)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('addNewOrgUser', 'email', email)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('addNewOrgUser', 'language', language)
            // verify required parameter 'firstName' is not null or undefined
            assertParamExists('addNewOrgUser', 'firstName', firstName)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('addNewOrgUser', 'lastName', lastName)
            // verify required parameter 'roleID' is not null or undefined
            assertParamExists('addNewOrgUser', 'roleID', roleID)
            // verify required parameter 'subDivID' is not null or undefined
            assertParamExists('addNewOrgUser', 'subDivID', subDivID)
            const localVarPath = `/accounts/add-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
            if (language !== undefined) { 
                localVarFormParams.set('language', language as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.set('firstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.set('lastName', lastName as any);
            }
    
            if (roleID !== undefined) { 
                localVarFormParams.set('roleID', roleID as any);
            }
    
            if (subDivID !== undefined) { 
                localVarFormParams.set('subDivID', subDivID as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates add permission to role
         * @param {string} roleId role id
         * @param {string} authorization an authorization header
         * @param {string} permissionName permission name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPermissionToRole: async (roleId: string, authorization: string, permissionName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('addPermissionToRole', 'roleId', roleId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addPermissionToRole', 'authorization', authorization)
            // verify required parameter 'permissionName' is not null or undefined
            assertParamExists('addPermissionToRole', 'permissionName', permissionName)
            const localVarPath = `/accounts/roles/{roleId}/permissions`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (permissionName !== undefined) { 
                localVarFormParams.set('permissionName', permissionName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add role to user
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {string} roleId Role type string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRole: async (userId: string, authorization: string, roleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addUserRole', 'userId', userId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addUserRole', 'authorization', authorization)
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('addUserRole', 'roleId', roleId)
            const localVarPath = `/accounts/user/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (roleId !== undefined) { 
                localVarFormParams.set('roleId', roleId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves organization white labeling images URLs after a user is authenticated
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticatedWhiteLabel: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('authenticatedWhiteLabel', 'authorization', authorization)
            const localVarPath = `/accounts/whitelabel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change password
         * @param {string} authorization organization authorization header
         * @param {string} password new password
         * @param {string} oldpassword old password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (authorization: string, password: string, oldpassword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('changePassword', 'authorization', authorization)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('changePassword', 'password', password)
            // verify required parameter 'oldpassword' is not null or undefined
            assertParamExists('changePassword', 'oldpassword', oldpassword)
            const localVarPath = `/accounts/user/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (oldpassword !== undefined) { 
                localVarFormParams.set('oldpassword', oldpassword as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Client Sign Up
         * @param {string} identifierType possible values are phone_passcode or email_passcode
         * @param {string} firstName users first name
         * @param {string} lastName users last name
         * @param {string} note User has the posibility to add a note when signing up. This note will be attached to thw first meeting request
         * @param {string} [email] Client user e-mail address.
         * @param {string} [phoneCountryCode] phone country code
         * @param {string} [phoneNumber] formatted phone number
         * @param {string} [socialSecurityNo] client user social security number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSignUp: async (identifierType: string, firstName: string, lastName: string, note: string, email?: string, phoneCountryCode?: string, phoneNumber?: string, socialSecurityNo?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifierType' is not null or undefined
            assertParamExists('clientSignUp', 'identifierType', identifierType)
            // verify required parameter 'firstName' is not null or undefined
            assertParamExists('clientSignUp', 'firstName', firstName)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('clientSignUp', 'lastName', lastName)
            // verify required parameter 'note' is not null or undefined
            assertParamExists('clientSignUp', 'note', note)
            const localVarPath = `/accounts/public/user/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (identifierType !== undefined) { 
                localVarFormParams.set('identifierType', identifierType as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
            if (phoneCountryCode !== undefined) { 
                localVarFormParams.set('phoneCountryCode', phoneCountryCode as any);
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.set('phoneNumber', phoneNumber as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.set('firstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.set('lastName', lastName as any);
            }
    
            if (socialSecurityNo !== undefined) { 
                localVarFormParams.set('socialSecurityNo', socialSecurityNo as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.set('note', note as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create label in organization.
         * @param {string} authorization an authorization header
         * @param {string} name The name of the newly created label
         * @param {string} category Category of the newly created label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel: async (authorization: string, name: string, category: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createLabel', 'authorization', authorization)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createLabel', 'name', name)
            // verify required parameter 'category' is not null or undefined
            assertParamExists('createLabel', 'category', category)
            const localVarPath = `/accounts/user-labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (name !== undefined) { 
                localVarFormParams.set('name', name as any);
            }
    
            if (category !== undefined) { 
                localVarFormParams.set('category', category as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new organization based on the given data
         * @param {string} authorization an authorization header
         * @param {string} organizationname The name of the organization for which the user to will be created
         * @param {string} language The language to use for emailing process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (authorization: string, organizationname: string, language: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createOrganization', 'authorization', authorization)
            // verify required parameter 'organizationname' is not null or undefined
            assertParamExists('createOrganization', 'organizationname', organizationname)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('createOrganization', 'language', language)
            const localVarPath = `/accounts/create/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (organizationname !== undefined) { 
                localVarFormParams.set('organizationname', organizationname as any);
            }
    
            if (language !== undefined) { 
                localVarFormParams.set('language', language as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create permission
         * @param {string} authorization an authorization header
         * @param {string} permissionName permission name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermission: async (authorization: string, permissionName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createPermission', 'authorization', authorization)
            // verify required parameter 'permissionName' is not null or undefined
            assertParamExists('createPermission', 'permissionName', permissionName)
            const localVarPath = `/accounts/create/permission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (permissionName !== undefined) { 
                localVarFormParams.set('permissionName', permissionName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deactivate user account
         * @param {string} authorization organization authorization header
         * @param {string} userId The ID of the user account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser: async (authorization: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deactivateUser', 'authorization', authorization)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deactivateUser', 'userId', userId)
            const localVarPath = `/accounts/user/{userId}/deactivate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete label by ID.
         * @param {string} labelId id of a label
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel: async (labelId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'labelId' is not null or undefined
            assertParamExists('deleteLabel', 'labelId', labelId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteLabel', 'authorization', authorization)
            const localVarPath = `/accounts/user-labels/{labelId}`
                .replace(`{${"labelId"}}`, encodeURIComponent(String(labelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete label from user.
         * @param {string} userId user ID
         * @param {string} labelId label ID
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelFromUser: async (userId: string, labelId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteLabelFromUser', 'userId', userId)
            // verify required parameter 'labelId' is not null or undefined
            assertParamExists('deleteLabelFromUser', 'labelId', labelId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteLabelFromUser', 'authorization', authorization)
            const localVarPath = `/accounts/user/{userId}/user-labels/{labelId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"labelId"}}`, encodeURIComponent(String(labelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove role from user
         * @param {string} roleId Role type string
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRole: async (roleId: string, userId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteUserRole', 'roleId', roleId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserRole', 'userId', userId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteUserRole', 'authorization', authorization)
            const localVarPath = `/accounts/user/{userId}/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the given user\'s labels
         * @param {string} userId user id
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelByUser: async (userId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getLabelByUser', 'userId', userId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getLabelByUser', 'authorization', authorization)
            const localVarPath = `/accounts/user/{userId}/user-labels`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get labels within organization.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getLabels', 'authorization', authorization)
            const localVarPath = `/accounts/user-labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get organization data
         * @param {string} authorization organization authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgData: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getOrgData', 'authorization', authorization)
            const localVarPath = `/accounts/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization roles.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationRoles: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getOrganizationRoles', 'authorization', authorization)
            const localVarPath = `/accounts/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a organization user
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUser: async (userId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOrganizationUser', 'userId', userId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getOrganizationUser', 'authorization', authorization)
            const localVarPath = `/accounts/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all organization employees (no clients)
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getOrganizationUsers', 'authorization', authorization)
            const localVarPath = `/accounts/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user data
         * @param {string} authorization organization authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalUserData: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getPersonalUserData', 'authorization', authorization)
            const localVarPath = `/accounts/user/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves user roles
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles: async (userId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserRoles', 'userId', userId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUserRoles', 'authorization', authorization)
            const localVarPath = `/accounts/user/{userId}/roles`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches all organization users (consultant or clients) by name
         * @param {string} authorization an authorization header
         * @param {string} name possible name of the user
         * @param {string} role filter by the user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAnyOrganizationUserByName: async (authorization: string, name: string, role: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('searchAnyOrganizationUserByName', 'authorization', authorization)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('searchAnyOrganizationUserByName', 'name', name)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('searchAnyOrganizationUserByName', 'role', role)
            const localVarPath = `/accounts/all/users/search/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all organization users
         * @param {string} authorization an authorization header
         * @param {string} term user info to search by, such as name, social sec number, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClient: async (authorization: string, term: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('searchClient', 'authorization', authorization)
            // verify required parameter 'term' is not null or undefined
            assertParamExists('searchClient', 'term', term)
            const localVarPath = `/accounts/client/search/{term}`
                .replace(`{${"term"}}`, encodeURIComponent(String(term)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all organization users
         * @param {string} authorization an authorization header
         * @param {string} name possible name of the user
         * @param {string} label label\&#39;s name or label\&#39;s category which the user has been tagged by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrganizationUsers: async (authorization: string, name: string, label: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('searchOrganizationUsers', 'authorization', authorization)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('searchOrganizationUsers', 'name', name)
            // verify required parameter 'label' is not null or undefined
            assertParamExists('searchOrganizationUsers', 'label', label)
            const localVarPath = `/accounts/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (label !== undefined) {
                localVarQueryParameter['label'] = label;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send user invite
         * @param {string} authorization an authorization header
         * @param {string} email The email of the user to invite
         * @param {string} language The language to be used when sending
         * @param {string} userID Users identification string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite: async (authorization: string, email: string, language: string, userID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('sendInvite', 'authorization', authorization)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('sendInvite', 'email', email)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('sendInvite', 'language', language)
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('sendInvite', 'userID', userID)
            const localVarPath = `/accounts/send-invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
            if (language !== undefined) { 
                localVarFormParams.set('language', language as any);
            }
    
            if (userID !== undefined) { 
                localVarFormParams.set('userID', userID as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update label by ID.
         * @param {string} labelId id of a label
         * @param {string} authorization an authorization header
         * @param {string} name The name of the newly created label
         * @param {string} category Category of the newly created label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel: async (labelId: string, authorization: string, name: string, category: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'labelId' is not null or undefined
            assertParamExists('updateLabel', 'labelId', labelId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateLabel', 'authorization', authorization)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('updateLabel', 'name', name)
            // verify required parameter 'category' is not null or undefined
            assertParamExists('updateLabel', 'category', category)
            const localVarPath = `/accounts/user-labels/{labelId}`
                .replace(`{${"labelId"}}`, encodeURIComponent(String(labelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (name !== undefined) { 
                localVarFormParams.set('name', name as any);
            }
    
            if (category !== undefined) { 
                localVarFormParams.set('category', category as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update organization data
         * @param {string} authorization organization authorization header
         * @param {OrgData} data Organization data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgData: async (authorization: string, data: OrgData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateOrgData', 'authorization', authorization)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('updateOrgData', 'data', data)
            const localVarPath = `/accounts/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user data
         * @param {string} authorization organization authorization header
         * @param {OrganizationUserPersonalData} data An OrganizationUserPersonalData Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalUserData: async (authorization: string, data: OrganizationUserPersonalData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updatePersonalUserData', 'authorization', authorization)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('updatePersonalUserData', 'data', data)
            const localVarPath = `/accounts/user/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user activity status (available / in a meeting / potentially offline)
         * @param {string} authorization organization authorization header
         * @param {string} onlineStatus user activity status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatus: async (authorization: string, onlineStatus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateUserStatus', 'authorization', authorization)
            // verify required parameter 'onlineStatus' is not null or undefined
            assertParamExists('updateUserStatus', 'onlineStatus', onlineStatus)
            const localVarPath = `/accounts/user/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (onlineStatus !== undefined) { 
                localVarFormParams.set('onlineStatus', onlineStatus as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validating the hash which has been sent to the new user e-mail
         * @param {string} hash The hash from the invite email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateInviteHash: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('validateInviteHash', 'hash', hash)
            const localVarPath = `/accounts/public/user/validate-invite-hash/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves organization white labeling images URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whiteLabel: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/public/whitelabel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new User based on the invite hash
         * @param {string} hash The hash from the invite email
         * @param {string} identifier user identifier
         * @param {string} identifierType Identifier type
         * @param {string} password User password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateUser(hash: string, identifier: string, identifierType: string, password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateUser(hash, identifier, identifierType, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add label to user.
         * @param {string} userId user ID
         * @param {string} authorization an authorization header
         * @param {string} labelID label ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLabelToUser(userId: string, authorization: string, labelID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLabelToUser(userId, authorization, labelID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds a new user to the organization, and send the user an email invitation
         * @param {string} authorization an authorization header
         * @param {string} email The email of the user to invite
         * @param {string} language The language to be used when sending
         * @param {string} firstName Users first name
         * @param {string} lastName Users last name
         * @param {string} roleID Users role
         * @param {string} subDivID The subdivision of an organization that the user belongs to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewOrgUser(authorization: string, email: string, language: string, firstName: string, lastName: string, roleID: string, subDivID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewOrgUser(authorization, email, language, firstName, lastName, roleID, subDivID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates add permission to role
         * @param {string} roleId role id
         * @param {string} authorization an authorization header
         * @param {string} permissionName permission name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPermissionToRole(roleId: string, authorization: string, permissionName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPermissionToRole(roleId, authorization, permissionName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add role to user
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {string} roleId Role type string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserRole(userId: string, authorization: string, roleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserRole(userId, authorization, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves organization white labeling images URLs after a user is authenticated
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticatedWhiteLabel(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticatedWhiteLabel(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change password
         * @param {string} authorization organization authorization header
         * @param {string} password new password
         * @param {string} oldpassword old password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(authorization: string, password: string, oldpassword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(authorization, password, oldpassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Client Sign Up
         * @param {string} identifierType possible values are phone_passcode or email_passcode
         * @param {string} firstName users first name
         * @param {string} lastName users last name
         * @param {string} note User has the posibility to add a note when signing up. This note will be attached to thw first meeting request
         * @param {string} [email] Client user e-mail address.
         * @param {string} [phoneCountryCode] phone country code
         * @param {string} [phoneNumber] formatted phone number
         * @param {string} [socialSecurityNo] client user social security number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientSignUp(identifierType: string, firstName: string, lastName: string, note: string, email?: string, phoneCountryCode?: string, phoneNumber?: string, socialSecurityNo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientSignUp(identifierType, firstName, lastName, note, email, phoneCountryCode, phoneNumber, socialSecurityNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create label in organization.
         * @param {string} authorization an authorization header
         * @param {string} name The name of the newly created label
         * @param {string} category Category of the newly created label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLabel(authorization: string, name: string, category: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLabel(authorization, name, category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new organization based on the given data
         * @param {string} authorization an authorization header
         * @param {string} organizationname The name of the organization for which the user to will be created
         * @param {string} language The language to use for emailing process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(authorization: string, organizationname: string, language: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganization(authorization, organizationname, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create permission
         * @param {string} authorization an authorization header
         * @param {string} permissionName permission name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPermission(authorization: string, permissionName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPermission(authorization, permissionName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deactivate user account
         * @param {string} authorization organization authorization header
         * @param {string} userId The ID of the user account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateUser(authorization: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateUser(authorization, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete label by ID.
         * @param {string} labelId id of a label
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLabel(labelId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLabel(labelId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete label from user.
         * @param {string} userId user ID
         * @param {string} labelId label ID
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLabelFromUser(userId: string, labelId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLabelFromUser(userId, labelId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove role from user
         * @param {string} roleId Role type string
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserRole(roleId: string, userId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserRole(roleId, userId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the given user\'s labels
         * @param {string} userId user id
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabelByUser(userId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabelByUser(userId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get labels within organization.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabels(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabels(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get organization data
         * @param {string} authorization organization authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgData(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgData(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get organization roles.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationRoles(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationRoles(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a organization user
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUser(userId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationUser(userId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all organization employees (no clients)
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationUsers(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user data
         * @param {string} authorization organization authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalUserData(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalUserData(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves user roles
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRoles(userId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRoles(userId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Searches all organization users (consultant or clients) by name
         * @param {string} authorization an authorization header
         * @param {string} name possible name of the user
         * @param {string} role filter by the user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAnyOrganizationUserByName(authorization: string, name: string, role: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAnyOrganizationUserByName(authorization, name, role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all organization users
         * @param {string} authorization an authorization header
         * @param {string} term user info to search by, such as name, social sec number, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchClient(authorization: string, term: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchClient(authorization, term, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves all organization users
         * @param {string} authorization an authorization header
         * @param {string} name possible name of the user
         * @param {string} label label\&#39;s name or label\&#39;s category which the user has been tagged by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchOrganizationUsers(authorization: string, name: string, label: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchOrganizationUsers(authorization, name, label, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send user invite
         * @param {string} authorization an authorization header
         * @param {string} email The email of the user to invite
         * @param {string} language The language to be used when sending
         * @param {string} userID Users identification string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInvite(authorization: string, email: string, language: string, userID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendInvite(authorization, email, language, userID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update label by ID.
         * @param {string} labelId id of a label
         * @param {string} authorization an authorization header
         * @param {string} name The name of the newly created label
         * @param {string} category Category of the newly created label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLabel(labelId: string, authorization: string, name: string, category: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLabel(labelId, authorization, name, category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update organization data
         * @param {string} authorization organization authorization header
         * @param {OrgData} data Organization data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrgData(authorization: string, data: OrgData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrgData(authorization, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user data
         * @param {string} authorization organization authorization header
         * @param {OrganizationUserPersonalData} data An OrganizationUserPersonalData Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonalUserData(authorization: string, data: OrganizationUserPersonalData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonalUserData(authorization, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user activity status (available / in a meeting / potentially offline)
         * @param {string} authorization organization authorization header
         * @param {string} onlineStatus user activity status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserStatus(authorization: string, onlineStatus: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserStatus(authorization, onlineStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validating the hash which has been sent to the new user e-mail
         * @param {string} hash The hash from the invite email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateInviteHash(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateInviteHash(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves organization white labeling images URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whiteLabel(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whiteLabel(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * Creates a new User based on the invite hash
         * @param {string} hash The hash from the invite email
         * @param {string} identifier user identifier
         * @param {string} identifierType Identifier type
         * @param {string} password User password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUser(hash: string, identifier: string, identifierType: string, password: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.activateUser(hash, identifier, identifierType, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add label to user.
         * @param {string} userId user ID
         * @param {string} authorization an authorization header
         * @param {string} labelID label ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLabelToUser(userId: string, authorization: string, labelID: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.addLabelToUser(userId, authorization, labelID, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a new user to the organization, and send the user an email invitation
         * @param {string} authorization an authorization header
         * @param {string} email The email of the user to invite
         * @param {string} language The language to be used when sending
         * @param {string} firstName Users first name
         * @param {string} lastName Users last name
         * @param {string} roleID Users role
         * @param {string} subDivID The subdivision of an organization that the user belongs to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewOrgUser(authorization: string, email: string, language: string, firstName: string, lastName: string, roleID: string, subDivID: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.addNewOrgUser(authorization, email, language, firstName, lastName, roleID, subDivID, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates add permission to role
         * @param {string} roleId role id
         * @param {string} authorization an authorization header
         * @param {string} permissionName permission name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPermissionToRole(roleId: string, authorization: string, permissionName: string, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.addPermissionToRole(roleId, authorization, permissionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Add role to user
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {string} roleId Role type string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRole(userId: string, authorization: string, roleId: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.addUserRole(userId, authorization, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves organization white labeling images URLs after a user is authenticated
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticatedWhiteLabel(authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.authenticatedWhiteLabel(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Change password
         * @param {string} authorization organization authorization header
         * @param {string} password new password
         * @param {string} oldpassword old password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(authorization: string, password: string, oldpassword: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.changePassword(authorization, password, oldpassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Client Sign Up
         * @param {string} identifierType possible values are phone_passcode or email_passcode
         * @param {string} firstName users first name
         * @param {string} lastName users last name
         * @param {string} note User has the posibility to add a note when signing up. This note will be attached to thw first meeting request
         * @param {string} [email] Client user e-mail address.
         * @param {string} [phoneCountryCode] phone country code
         * @param {string} [phoneNumber] formatted phone number
         * @param {string} [socialSecurityNo] client user social security number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSignUp(identifierType: string, firstName: string, lastName: string, note: string, email?: string, phoneCountryCode?: string, phoneNumber?: string, socialSecurityNo?: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.clientSignUp(identifierType, firstName, lastName, note, email, phoneCountryCode, phoneNumber, socialSecurityNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create label in organization.
         * @param {string} authorization an authorization header
         * @param {string} name The name of the newly created label
         * @param {string} category Category of the newly created label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel(authorization: string, name: string, category: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.createLabel(authorization, name, category, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new organization based on the given data
         * @param {string} authorization an authorization header
         * @param {string} organizationname The name of the organization for which the user to will be created
         * @param {string} language The language to use for emailing process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(authorization: string, organizationname: string, language: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.createOrganization(authorization, organizationname, language, options).then((request) => request(axios, basePath));
        },
        /**
         * create permission
         * @param {string} authorization an authorization header
         * @param {string} permissionName permission name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPermission(authorization: string, permissionName: string, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.createPermission(authorization, permissionName, options).then((request) => request(axios, basePath));
        },
        /**
         * Deactivate user account
         * @param {string} authorization organization authorization header
         * @param {string} userId The ID of the user account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser(authorization: string, userId: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.deactivateUser(authorization, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete label by ID.
         * @param {string} labelId id of a label
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabel(labelId: string, authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.deleteLabel(labelId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete label from user.
         * @param {string} userId user ID
         * @param {string} labelId label ID
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabelFromUser(userId: string, labelId: string, authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.deleteLabelFromUser(userId, labelId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove role from user
         * @param {string} roleId Role type string
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRole(roleId: string, userId: string, authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.deleteUserRole(roleId, userId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the given user\'s labels
         * @param {string} userId user id
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabelByUser(userId: string, authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.getLabelByUser(userId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get labels within organization.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabels(authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.getLabels(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get organization data
         * @param {string} authorization organization authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgData(authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.getOrgData(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get organization roles.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationRoles(authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.getOrganizationRoles(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a organization user
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUser(userId: string, authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.getOrganizationUser(userId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all organization employees (no clients)
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers(authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.getOrganizationUsers(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user data
         * @param {string} authorization organization authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalUserData(authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.getPersonalUserData(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves user roles
         * @param {string} userId The id (UUID) of the User to be fetched
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRoles(userId: string, authorization: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.getUserRoles(userId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches all organization users (consultant or clients) by name
         * @param {string} authorization an authorization header
         * @param {string} name possible name of the user
         * @param {string} role filter by the user role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAnyOrganizationUserByName(authorization: string, name: string, role: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.searchAnyOrganizationUserByName(authorization, name, role, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all organization users
         * @param {string} authorization an authorization header
         * @param {string} term user info to search by, such as name, social sec number, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchClient(authorization: string, term: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.searchClient(authorization, term, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all organization users
         * @param {string} authorization an authorization header
         * @param {string} name possible name of the user
         * @param {string} label label\&#39;s name or label\&#39;s category which the user has been tagged by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOrganizationUsers(authorization: string, name: string, label: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.searchOrganizationUsers(authorization, name, label, options).then((request) => request(axios, basePath));
        },
        /**
         * Send user invite
         * @param {string} authorization an authorization header
         * @param {string} email The email of the user to invite
         * @param {string} language The language to be used when sending
         * @param {string} userID Users identification string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite(authorization: string, email: string, language: string, userID: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.sendInvite(authorization, email, language, userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update label by ID.
         * @param {string} labelId id of a label
         * @param {string} authorization an authorization header
         * @param {string} name The name of the newly created label
         * @param {string} category Category of the newly created label
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel(labelId: string, authorization: string, name: string, category: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.updateLabel(labelId, authorization, name, category, options).then((request) => request(axios, basePath));
        },
        /**
         * Update organization data
         * @param {string} authorization organization authorization header
         * @param {OrgData} data Organization data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgData(authorization: string, data: OrgData, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.updateOrgData(authorization, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user data
         * @param {string} authorization organization authorization header
         * @param {OrganizationUserPersonalData} data An OrganizationUserPersonalData Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalUserData(authorization: string, data: OrganizationUserPersonalData, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.updatePersonalUserData(authorization, data, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user activity status (available / in a meeting / potentially offline)
         * @param {string} authorization organization authorization header
         * @param {string} onlineStatus user activity status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatus(authorization: string, onlineStatus: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.updateUserStatus(authorization, onlineStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Validating the hash which has been sent to the new user e-mail
         * @param {string} hash The hash from the invite email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateInviteHash(hash: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.validateInviteHash(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves organization white labeling images URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whiteLabel(options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.whiteLabel(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * Creates a new User based on the invite hash
     * @param {string} hash The hash from the invite email
     * @param {string} identifier user identifier
     * @param {string} identifierType Identifier type
     * @param {string} password User password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public activateUser(hash: string, identifier: string, identifierType: string, password: string, options?: any) {
        return AccountsApiFp(this.configuration).activateUser(hash, identifier, identifierType, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add label to user.
     * @param {string} userId user ID
     * @param {string} authorization an authorization header
     * @param {string} labelID label ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public addLabelToUser(userId: string, authorization: string, labelID: string, options?: any) {
        return AccountsApiFp(this.configuration).addLabelToUser(userId, authorization, labelID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds a new user to the organization, and send the user an email invitation
     * @param {string} authorization an authorization header
     * @param {string} email The email of the user to invite
     * @param {string} language The language to be used when sending
     * @param {string} firstName Users first name
     * @param {string} lastName Users last name
     * @param {string} roleID Users role
     * @param {string} subDivID The subdivision of an organization that the user belongs to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public addNewOrgUser(authorization: string, email: string, language: string, firstName: string, lastName: string, roleID: string, subDivID: string, options?: any) {
        return AccountsApiFp(this.configuration).addNewOrgUser(authorization, email, language, firstName, lastName, roleID, subDivID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates add permission to role
     * @param {string} roleId role id
     * @param {string} authorization an authorization header
     * @param {string} permissionName permission name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public addPermissionToRole(roleId: string, authorization: string, permissionName: string, options?: any) {
        return AccountsApiFp(this.configuration).addPermissionToRole(roleId, authorization, permissionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add role to user
     * @param {string} userId The id (UUID) of the User to be fetched
     * @param {string} authorization an authorization header
     * @param {string} roleId Role type string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public addUserRole(userId: string, authorization: string, roleId: string, options?: any) {
        return AccountsApiFp(this.configuration).addUserRole(userId, authorization, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves organization white labeling images URLs after a user is authenticated
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public authenticatedWhiteLabel(authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).authenticatedWhiteLabel(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change password
     * @param {string} authorization organization authorization header
     * @param {string} password new password
     * @param {string} oldpassword old password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public changePassword(authorization: string, password: string, oldpassword: string, options?: any) {
        return AccountsApiFp(this.configuration).changePassword(authorization, password, oldpassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Client Sign Up
     * @param {string} identifierType possible values are phone_passcode or email_passcode
     * @param {string} firstName users first name
     * @param {string} lastName users last name
     * @param {string} note User has the posibility to add a note when signing up. This note will be attached to thw first meeting request
     * @param {string} [email] Client user e-mail address.
     * @param {string} [phoneCountryCode] phone country code
     * @param {string} [phoneNumber] formatted phone number
     * @param {string} [socialSecurityNo] client user social security number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public clientSignUp(identifierType: string, firstName: string, lastName: string, note: string, email?: string, phoneCountryCode?: string, phoneNumber?: string, socialSecurityNo?: string, options?: any) {
        return AccountsApiFp(this.configuration).clientSignUp(identifierType, firstName, lastName, note, email, phoneCountryCode, phoneNumber, socialSecurityNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create label in organization.
     * @param {string} authorization an authorization header
     * @param {string} name The name of the newly created label
     * @param {string} category Category of the newly created label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createLabel(authorization: string, name: string, category: string, options?: any) {
        return AccountsApiFp(this.configuration).createLabel(authorization, name, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new organization based on the given data
     * @param {string} authorization an authorization header
     * @param {string} organizationname The name of the organization for which the user to will be created
     * @param {string} language The language to use for emailing process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createOrganization(authorization: string, organizationname: string, language: string, options?: any) {
        return AccountsApiFp(this.configuration).createOrganization(authorization, organizationname, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create permission
     * @param {string} authorization an authorization header
     * @param {string} permissionName permission name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public createPermission(authorization: string, permissionName: string, options?: any) {
        return AccountsApiFp(this.configuration).createPermission(authorization, permissionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deactivate user account
     * @param {string} authorization organization authorization header
     * @param {string} userId The ID of the user account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deactivateUser(authorization: string, userId: string, options?: any) {
        return AccountsApiFp(this.configuration).deactivateUser(authorization, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete label by ID.
     * @param {string} labelId id of a label
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteLabel(labelId: string, authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).deleteLabel(labelId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete label from user.
     * @param {string} userId user ID
     * @param {string} labelId label ID
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteLabelFromUser(userId: string, labelId: string, authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).deleteLabelFromUser(userId, labelId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove role from user
     * @param {string} roleId Role type string
     * @param {string} userId The id (UUID) of the User to be fetched
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteUserRole(roleId: string, userId: string, authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).deleteUserRole(roleId, userId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the given user\'s labels
     * @param {string} userId user id
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getLabelByUser(userId: string, authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).getLabelByUser(userId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get labels within organization.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getLabels(authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).getLabels(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get organization data
     * @param {string} authorization organization authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getOrgData(authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).getOrgData(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get organization roles.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getOrganizationRoles(authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).getOrganizationRoles(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a organization user
     * @param {string} userId The id (UUID) of the User to be fetched
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getOrganizationUser(userId: string, authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).getOrganizationUser(userId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all organization employees (no clients)
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getOrganizationUsers(authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).getOrganizationUsers(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user data
     * @param {string} authorization organization authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getPersonalUserData(authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).getPersonalUserData(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves user roles
     * @param {string} userId The id (UUID) of the User to be fetched
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getUserRoles(userId: string, authorization: string, options?: any) {
        return AccountsApiFp(this.configuration).getUserRoles(userId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches all organization users (consultant or clients) by name
     * @param {string} authorization an authorization header
     * @param {string} name possible name of the user
     * @param {string} role filter by the user role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public searchAnyOrganizationUserByName(authorization: string, name: string, role: string, options?: any) {
        return AccountsApiFp(this.configuration).searchAnyOrganizationUserByName(authorization, name, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all organization users
     * @param {string} authorization an authorization header
     * @param {string} term user info to search by, such as name, social sec number, phone, email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public searchClient(authorization: string, term: string, options?: any) {
        return AccountsApiFp(this.configuration).searchClient(authorization, term, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all organization users
     * @param {string} authorization an authorization header
     * @param {string} name possible name of the user
     * @param {string} label label\&#39;s name or label\&#39;s category which the user has been tagged by.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public searchOrganizationUsers(authorization: string, name: string, label: string, options?: any) {
        return AccountsApiFp(this.configuration).searchOrganizationUsers(authorization, name, label, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send user invite
     * @param {string} authorization an authorization header
     * @param {string} email The email of the user to invite
     * @param {string} language The language to be used when sending
     * @param {string} userID Users identification string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public sendInvite(authorization: string, email: string, language: string, userID: string, options?: any) {
        return AccountsApiFp(this.configuration).sendInvite(authorization, email, language, userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update label by ID.
     * @param {string} labelId id of a label
     * @param {string} authorization an authorization header
     * @param {string} name The name of the newly created label
     * @param {string} category Category of the newly created label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateLabel(labelId: string, authorization: string, name: string, category: string, options?: any) {
        return AccountsApiFp(this.configuration).updateLabel(labelId, authorization, name, category, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update organization data
     * @param {string} authorization organization authorization header
     * @param {OrgData} data Organization data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateOrgData(authorization: string, data: OrgData, options?: any) {
        return AccountsApiFp(this.configuration).updateOrgData(authorization, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user data
     * @param {string} authorization organization authorization header
     * @param {OrganizationUserPersonalData} data An OrganizationUserPersonalData Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updatePersonalUserData(authorization: string, data: OrganizationUserPersonalData, options?: any) {
        return AccountsApiFp(this.configuration).updatePersonalUserData(authorization, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user activity status (available / in a meeting / potentially offline)
     * @param {string} authorization organization authorization header
     * @param {string} onlineStatus user activity status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateUserStatus(authorization: string, onlineStatus: string, options?: any) {
        return AccountsApiFp(this.configuration).updateUserStatus(authorization, onlineStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validating the hash which has been sent to the new user e-mail
     * @param {string} hash The hash from the invite email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public validateInviteHash(hash: string, options?: any) {
        return AccountsApiFp(this.configuration).validateInviteHash(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves organization white labeling images URLs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public whiteLabel(options?: any) {
        return AccountsApiFp(this.configuration).whiteLabel(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Step 1 of client login with OTP - Requesting passcode based on e-mail
         * @param {string} email user e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOTPEmail: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('authOTPEmail', 'email', email)
            const localVarPath = `/auth/otp/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Step 1 of client login with OTP - Requesting passcode based on phone number
         * @param {string} phoneCountryCode phone country code
         * @param {string} phoneNumber formatted phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOTPSms: async (phoneCountryCode: string, phoneNumber: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneCountryCode' is not null or undefined
            assertParamExists('authOTPSms', 'phoneCountryCode', phoneCountryCode)
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('authOTPSms', 'phoneNumber', phoneNumber)
            const localVarPath = `/auth/otp/sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (phoneCountryCode !== undefined) { 
                localVarFormParams.set('phoneCountryCode', phoneCountryCode as any);
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.set('phoneNumber', phoneNumber as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Step 2 of OTP auth. Passcode for initial client registration and social security number are supplied and normal authtoken is returned.
         * @param {string} passcode one time passcode to log in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateClientOTP: async (passcode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passcode' is not null or undefined
            assertParamExists('authenticateClientOTP', 'passcode', passcode)
            const localVarPath = `/auth/client-passcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (passcode !== undefined) { 
                localVarFormParams.set('passcode', passcode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authenticates the users for the given organization
         * @param {string} email The email of the user to create
         * @param {string} password The password for the user to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser: async (email: string, password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('authenticateUser', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('authenticateUser', 'password', password)
            const localVarPath = `/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Step 2 of OTP auth. Passcode is supplied and normal authtoken is returned
         * @param {string} passcode one time passcode to log in
         * @param {boolean} [remember] whether to extend the expiration token to 7 days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserOTP: async (passcode: string, remember?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passcode' is not null or undefined
            assertParamExists('authenticateUserOTP', 'passcode', passcode)
            const localVarPath = `/auth/passcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (passcode !== undefined) { 
                localVarFormParams.set('passcode', passcode as any);
            }
    
            if (remember !== undefined) { 
                localVarFormParams.set('remember', remember as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint used in order to request a password reset
         * @param {string} lang The language to load in the form en, de
         * @param {string} email The email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (lang: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('resetPassword', 'lang', lang)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resetPassword', 'email', email)
            const localVarPath = `/auth/password/reset/{lang}`
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (email !== undefined) { 
                localVarFormParams.set('email', email as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint used to reset a password
         * @param {string} hash The old password for the user
         * @param {string} password The password for the user to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (hash: string, password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('updatePassword', 'hash', hash)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('updatePassword', 'password', password)
            const localVarPath = `/auth/password/update/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint used to reset a password
         * @param {string} hash The old password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordResetHash: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('validatePasswordResetHash', 'hash', hash)
            const localVarPath = `/auth/password/validate/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Step 1 of client login with OTP - Requesting passcode based on e-mail
         * @param {string} email user e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOTPEmail(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOTPEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Step 1 of client login with OTP - Requesting passcode based on phone number
         * @param {string} phoneCountryCode phone country code
         * @param {string} phoneNumber formatted phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authOTPSms(phoneCountryCode: string, phoneNumber: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authOTPSms(phoneCountryCode, phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Step 2 of OTP auth. Passcode for initial client registration and social security number are supplied and normal authtoken is returned.
         * @param {string} passcode one time passcode to log in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateClientOTP(passcode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateClientOTP(passcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authenticates the users for the given organization
         * @param {string} email The email of the user to create
         * @param {string} password The password for the user to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUser(email: string, password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUser(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Step 2 of OTP auth. Passcode is supplied and normal authtoken is returned
         * @param {string} passcode one time passcode to log in
         * @param {boolean} [remember] whether to extend the expiration token to 7 days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUserOTP(passcode: string, remember?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticateUserOTP(passcode, remember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint used in order to request a password reset
         * @param {string} lang The language to load in the form en, de
         * @param {string} email The email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(lang: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(lang, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint used to reset a password
         * @param {string} hash The old password for the user
         * @param {string} password The password for the user to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(hash: string, password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(hash, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint used to reset a password
         * @param {string} hash The old password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePasswordResetHash(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePasswordResetHash(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Step 1 of client login with OTP - Requesting passcode based on e-mail
         * @param {string} email user e-mail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOTPEmail(email: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.authOTPEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Step 1 of client login with OTP - Requesting passcode based on phone number
         * @param {string} phoneCountryCode phone country code
         * @param {string} phoneNumber formatted phone number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authOTPSms(phoneCountryCode: string, phoneNumber: string, options?: any): AxiosPromise<AccountResponseMessage> {
            return localVarFp.authOTPSms(phoneCountryCode, phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Step 2 of OTP auth. Passcode for initial client registration and social security number are supplied and normal authtoken is returned.
         * @param {string} passcode one time passcode to log in
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateClientOTP(passcode: string, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.authenticateClientOTP(passcode, options).then((request) => request(axios, basePath));
        },
        /**
         * Authenticates the users for the given organization
         * @param {string} email The email of the user to create
         * @param {string} password The password for the user to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(email: string, password: string, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.authenticateUser(email, password, options).then((request) => request(axios, basePath));
        },
        /**
         * Step 2 of OTP auth. Passcode is supplied and normal authtoken is returned
         * @param {string} passcode one time passcode to log in
         * @param {boolean} [remember] whether to extend the expiration token to 7 days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserOTP(passcode: string, remember?: boolean, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.authenticateUserOTP(passcode, remember, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint used in order to request a password reset
         * @param {string} lang The language to load in the form en, de
         * @param {string} email The email of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(lang: string, email: string, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.resetPassword(lang, email, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint used to reset a password
         * @param {string} hash The old password for the user
         * @param {string} password The password for the user to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(hash: string, password: string, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.updatePassword(hash, password, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint used to reset a password
         * @param {string} hash The old password for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordResetHash(hash: string, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.validatePasswordResetHash(hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Step 1 of client login with OTP - Requesting passcode based on e-mail
     * @param {string} email user e-mail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authOTPEmail(email: string, options?: any) {
        return AuthenticationApiFp(this.configuration).authOTPEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Step 1 of client login with OTP - Requesting passcode based on phone number
     * @param {string} phoneCountryCode phone country code
     * @param {string} phoneNumber formatted phone number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authOTPSms(phoneCountryCode: string, phoneNumber: string, options?: any) {
        return AuthenticationApiFp(this.configuration).authOTPSms(phoneCountryCode, phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Step 2 of OTP auth. Passcode for initial client registration and social security number are supplied and normal authtoken is returned.
     * @param {string} passcode one time passcode to log in
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticateClientOTP(passcode: string, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticateClientOTP(passcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authenticates the users for the given organization
     * @param {string} email The email of the user to create
     * @param {string} password The password for the user to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticateUser(email: string, password: string, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticateUser(email, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Step 2 of OTP auth. Passcode is supplied and normal authtoken is returned
     * @param {string} passcode one time passcode to log in
     * @param {boolean} [remember] whether to extend the expiration token to 7 days
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticateUserOTP(passcode: string, remember?: boolean, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticateUserOTP(passcode, remember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint used in order to request a password reset
     * @param {string} lang The language to load in the form en, de
     * @param {string} email The email of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public resetPassword(lang: string, email: string, options?: any) {
        return AuthenticationApiFp(this.configuration).resetPassword(lang, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint used to reset a password
     * @param {string} hash The old password for the user
     * @param {string} password The password for the user to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public updatePassword(hash: string, password: string, options?: any) {
        return AuthenticationApiFp(this.configuration).updatePassword(hash, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint used to reset a password
     * @param {string} hash The old password for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public validatePasswordResetHash(hash: string, options?: any) {
        return AuthenticationApiFp(this.configuration).validatePasswordResetHash(hash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new meeting chat message.
         * @param {string} authorization an authorization header
         * @param {MeetingChatMessage} chatObj Cchat object. Only the text amd meeting_id fields need to be filled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingChatMessage: async (authorization: string, chatObj: MeetingChatMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addMeetingChatMessage', 'authorization', authorization)
            // verify required parameter 'chatObj' is not null or undefined
            assertParamExists('addMeetingChatMessage', 'chatObj', chatObj)
            const localVarPath = `/calendar/meetings/chat/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new meeting note. Only requires the note field of the MeetingNote object to be filled. All other attributes are disregarded.
         * @param {string} authorization an authorization header
         * @param {MeetingNote} noteObj Note object. Fill in the note, meeting_id, created_by and client_id fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingNote: async (authorization: string, noteObj: MeetingNote, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addMeetingNote', 'authorization', authorization)
            // verify required parameter 'noteObj' is not null or undefined
            assertParamExists('addMeetingNote', 'noteObj', noteObj)
            const localVarPath = `/calendar/meetings/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a participant to a meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are adding the participant to
         * @param {string} userId The userID of the participant being added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingParticipants: async (authorization: string, meetingId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addMeetingParticipants', 'authorization', authorization)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('addMeetingParticipants', 'meetingId', meetingId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addMeetingParticipants', 'userId', userId)
            const localVarPath = `/calendar/meetings/{meeting_id}/participants/{user_id}`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add ToDo to meeting.
         * @param {string} meetingId ID of a meeting
         * @param {string} authorization an authorization header
         * @param {string} item description of ToDo
         * @param {string} clientId ID of the client
         * @param {string} assignee userID of assignee has to do the ToDo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingTodos: async (meetingId: string, authorization: string, item: string, clientId: string, assignee: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('addMeetingTodos', 'meetingId', meetingId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addMeetingTodos', 'authorization', authorization)
            // verify required parameter 'item' is not null or undefined
            assertParamExists('addMeetingTodos', 'item', item)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('addMeetingTodos', 'clientId', clientId)
            // verify required parameter 'assignee' is not null or undefined
            assertParamExists('addMeetingTodos', 'assignee', assignee)
            const localVarPath = `/calendar/meetings/{meeting_id}/todos`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (item !== undefined) { 
                localVarFormParams.set('item', item as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (assignee !== undefined) { 
                localVarFormParams.set('assignee', assignee as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create meeting-type.
         * @param {string} authorization an authorization header
         * @param {string} name name of the meeting-type
         * @param {number} preSlices number of pre-slices.
         * @param {number} slices number of slices.
         * @param {number} postSlices number of post-slices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingType: async (authorization: string, name: string, preSlices: number, slices: number, postSlices: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addMeetingType', 'authorization', authorization)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addMeetingType', 'name', name)
            // verify required parameter 'preSlices' is not null or undefined
            assertParamExists('addMeetingType', 'preSlices', preSlices)
            // verify required parameter 'slices' is not null or undefined
            assertParamExists('addMeetingType', 'slices', slices)
            // verify required parameter 'postSlices' is not null or undefined
            assertParamExists('addMeetingType', 'postSlices', postSlices)
            const localVarPath = `/calendar/meetings/def/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (name !== undefined) { 
                localVarFormParams.set('name', name as any);
            }
    
            if (preSlices !== undefined) { 
                localVarFormParams.set('preSlices', preSlices as any);
            }
    
            if (slices !== undefined) { 
                localVarFormParams.set('slices', slices as any);
            }
    
            if (postSlices !== undefined) { 
                localVarFormParams.set('postSlices', postSlices as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new ToDo task.
         * @param {string} authorization an authorization header
         * @param {OrgToDo} orgTodoObj A ToDo Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewTodo: async (authorization: string, orgTodoObj: OrgToDo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addNewTodo', 'authorization', authorization)
            // verify required parameter 'orgTodoObj' is not null or undefined
            assertParamExists('addNewTodo', 'orgTodoObj', orgTodoObj)
            const localVarPath = `/calendar/todos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgTodoObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Meeting Request.
         * @param {string} authorization an authorization header
         * @param {string} meetingType The meeting type
         * @param {string} [note] The request description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingRequest: async (authorization: string, meetingType: string, note?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createMeetingRequest', 'authorization', authorization)
            // verify required parameter 'meetingType' is not null or undefined
            assertParamExists('createMeetingRequest', 'meetingType', meetingType)
            const localVarPath = `/calendar/meetings/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (meetingType !== undefined) { 
                localVarFormParams.set('meeting_type', meetingType as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.set('note', note as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Special Meeting Request - such as contact-back or a reschedule existing meeting.
         * @param {string} authorization an authorization header
         * @param {string} requestType The special request type
         * @param {MeetingRequest} requestObj Request Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingSpecialRequest: async (authorization: string, requestType: string, requestObj: MeetingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createMeetingSpecialRequest', 'authorization', authorization)
            // verify required parameter 'requestType' is not null or undefined
            assertParamExists('createMeetingSpecialRequest', 'requestType', requestType)
            // verify required parameter 'requestObj' is not null or undefined
            assertParamExists('createMeetingSpecialRequest', 'requestObj', requestObj)
            const localVarPath = `/calendar/meetings/special/request/{request_type}`
                .replace(`{${"request_type"}}`, encodeURIComponent(String(requestType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new meeting
         * @param {string} authorization an authorization header
         * @param {Meeting} meetingObj Meeting Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewMeeting: async (authorization: string, meetingObj: Meeting, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('createNewMeeting', 'authorization', authorization)
            // verify required parameter 'meetingObj' is not null or undefined
            assertParamExists('createNewMeeting', 'meetingObj', meetingObj)
            const localVarPath = `/calendar/meeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId ID of a meeting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeeting: async (authorization: string, meetingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteMeeting', 'authorization', authorization)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('deleteMeeting', 'meetingId', meetingId)
            const localVarPath = `/calendar/meetings/{meeting_id}`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete meeting chat message
         * @param {string} authorization an authorization header
         * @param {string} messageId The meeting ID of the message being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingChatMessages: async (authorization: string, messageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteMeetingChatMessages', 'authorization', authorization)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('deleteMeetingChatMessages', 'messageId', messageId)
            const localVarPath = `/calendar/meetings/chat/message/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete meeting note
         * @param {string} authorization an authorization header
         * @param {string} noteId The meeting ID for which the notes will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingNote: async (authorization: string, noteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteMeetingNote', 'authorization', authorization)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('deleteMeetingNote', 'noteId', noteId)
            const localVarPath = `/calendar/meetings/notes/{note_id}`
                .replace(`{${"note_id"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Meeting Request. This action can be performed by clients and consultants.
         * @param {string} authorization an authorization header
         * @param {string} requestId The id of the meeting request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingRequest: async (authorization: string, requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteMeetingRequest', 'authorization', authorization)
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('deleteMeetingRequest', 'requestId', requestId)
            const localVarPath = `/calendar/meetings/requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete ToDo
         * @param {string} meetingId ID of a meeting
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingTodos: async (meetingId: string, todoId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('deleteMeetingTodos', 'meetingId', meetingId)
            // verify required parameter 'todoId' is not null or undefined
            assertParamExists('deleteMeetingTodos', 'todoId', todoId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteMeetingTodos', 'authorization', authorization)
            const localVarPath = `/calendar/meetings/{meeting_id}/todos/{todo_id}`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)))
                .replace(`{${"todo_id"}}`, encodeURIComponent(String(todoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete meeting-type.
         * @param {string} meetingTypeId meeting-type id.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingType: async (meetingTypeId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingTypeId' is not null or undefined
            assertParamExists('deleteMeetingType', 'meetingTypeId', meetingTypeId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteMeetingType', 'authorization', authorization)
            const localVarPath = `/calendar/meetings/def/types/{meeting_type_id}`
                .replace(`{${"meeting_type_id"}}`, encodeURIComponent(String(meetingTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete ToDo
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTodos: async (todoId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoId' is not null or undefined
            assertParamExists('deleteTodos', 'todoId', todoId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteTodos', 'authorization', authorization)
            const localVarPath = `/calendar/todos/{todo_id}`
                .replace(`{${"todo_id"}}`, encodeURIComponent(String(todoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get availabilities for one or more consultant.
         * @param {string} authorization an authorization header
         * @param {AvailabilityRequest} availabilityRequest Availability request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailabilities: async (authorization: string, availabilityRequest: AvailabilityRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getAvailabilities', 'authorization', authorization)
            // verify required parameter 'availabilityRequest' is not null or undefined
            assertParamExists('getAvailabilities', 'availabilityRequest', availabilityRequest)
            const localVarPath = `/calendar/meetings/availabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(availabilityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chat history for the specified user_id (over all the meetings)
         * @param {string} authorization an authorization header
         * @param {string} userId The client ID for which the chat history will be retrieved
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatHistoryForUser: async (authorization: string, userId: string, fromDate: string, toDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getChatHistoryForUser', 'authorization', authorization)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getChatHistoryForUser', 'userId', userId)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getChatHistoryForUser', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getChatHistoryForUser', 'toDate', toDate)
            const localVarPath = `/calendar/chat/{user_id}/{from_date}/{to_date}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"from_date"}}`, encodeURIComponent(String(fromDate)))
                .replace(`{${"to_date"}}`, encodeURIComponent(String(toDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all open meeting requests from a client, including the scheduled meetings for these requests.
         * @param {string} authorization an authorization header
         * @param {string} clientId ID of a client to get the requests for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientRequests: async (authorization: string, clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getClientRequests', 'authorization', authorization)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getClientRequests', 'clientId', clientId)
            const localVarPath = `/calendar/meetings/requests/client/{client_id}`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all TODOS for a specific client
         * @param {string} clientId client user id for which are the todos.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientTodos: async (clientId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getClientTodos', 'clientId', clientId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getClientTodos', 'authorization', authorization)
            const localVarPath = `/calendar/todos/client/{client_id}`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all closed meeting requests.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClosedRequests: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getClosedRequests', 'authorization', authorization)
            const localVarPath = `/calendar/meetings/requests/closed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a single meeting by its ID
         * @param {string} authorization an authorization header
         * @param {string} meetingId Id of a meeting to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeeting: async (authorization: string, meetingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMeeting', 'authorization', authorization)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('getMeeting', 'meetingId', meetingId)
            const localVarPath = `/calendar/meetings/{meeting_id}`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get meeting chat for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID for which the chat will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingChat: async (authorization: string, meetingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMeetingChat', 'authorization', authorization)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('getMeetingChat', 'meetingId', meetingId)
            const localVarPath = `/calendar/meetings/{meeting_id}/chat`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get meeting notes for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID for which the notes will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingNotes: async (authorization: string, meetingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMeetingNotes', 'authorization', authorization)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('getMeetingNotes', 'meetingId', meetingId)
            const localVarPath = `/calendar/meetings/{meeting_id}/notes`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get TODOS for meeting
         * @param {string} meetingId ID of a meeting
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingTodos: async (meetingId: string, authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('getMeetingTodos', 'meetingId', meetingId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMeetingTodos', 'authorization', authorization)
            const localVarPath = `/calendar/meetings/{meeting_id}/todos`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Meeting-types.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingTypes: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMeetingTypes', 'authorization', authorization)
            const localVarPath = `/calendar/meetings/def/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get meetings for an organization
         * @param {string} authorization an authorization header
         * @param {string} fromDate Start of range of meetings being requested in RFC 3339
         * @param {string} toDate End of range of meetings being requested in RFC 3339
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsForOrg: async (authorization: string, fromDate: string, toDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMeetingsForOrg', 'authorization', authorization)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getMeetingsForOrg', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getMeetingsForOrg', 'toDate', toDate)
            const localVarPath = `/calendar/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = toDate;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get meetings for a user
         * @param {string} authorization an authorization header
         * @param {string} userId user_id to get the meetings for (clients can only see theirs)
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsForUser: async (authorization: string, userId: string, fromDate: string, toDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMeetingsForUser', 'authorization', authorization)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMeetingsForUser', 'userId', userId)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getMeetingsForUser', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getMeetingsForUser', 'toDate', toDate)
            const localVarPath = `/calendar/{user_id}/meetings/{from_date}/{to_date}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"from_date"}}`, encodeURIComponent(String(fromDate)))
                .replace(`{${"to_date"}}`, encodeURIComponent(String(toDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all the open TODOS assigned to the connected user (consultant/client) or of the whole organization (admin)
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTodos: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getMyTodos', 'authorization', authorization)
            const localVarPath = `/calendar/todos/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get note history for the specified user_id (over all the meetings)
         * @param {string} authorization an authorization header
         * @param {string} userId The client ID for which the chat history will be retrieved
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistoryForUser: async (authorization: string, userId: string, fromDate: string, toDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getNoteHistoryForUser', 'authorization', authorization)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getNoteHistoryForUser', 'userId', userId)
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getNoteHistoryForUser', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getNoteHistoryForUser', 'toDate', toDate)
            const localVarPath = `/calendar/notes/{user_id}/{from_date}/{to_date}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"from_date"}}`, encodeURIComponent(String(fromDate)))
                .replace(`{${"to_date"}}`, encodeURIComponent(String(toDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all open meeting requests.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenMeetingRequests: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getOpenMeetingRequests', 'authorization', authorization)
            const localVarPath = `/calendar/meetings/requests/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the weekly report data.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getReport', 'authorization', authorization)
            const localVarPath = `/calendar/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns 3 months of weekly report data
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportChart: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getReportChart', 'authorization', authorization)
            const localVarPath = `/calendar/reportchart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get request by ID.
         * @param {string} authorization an authorization header
         * @param {string} requestId ID of a request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequest: async (authorization: string, requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getRequest', 'authorization', authorization)
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('getRequest', 'requestId', requestId)
            const localVarPath = `/calendar/meetings/requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get range or requests.
         * @param {string} authorization an authorization header
         * @param {string} from The starting date for the range query
         * @param {string} to The end date for the range query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestsRange: async (authorization: string, from: string, to: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getRequestsRange', 'authorization', authorization)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getRequestsRange', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getRequestsRange', 'to', to)
            const localVarPath = `/calendar/meetings/requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (from !== undefined) { 
                localVarFormParams.set('from', from as any);
            }
    
            if (to !== undefined) { 
                localVarFormParams.set('to', to as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a participant from a meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are removing the participant from
         * @param {string} userId The userID of the participant being removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMeetingParticipants: async (authorization: string, meetingId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('removeMeetingParticipants', 'authorization', authorization)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('removeMeetingParticipants', 'meetingId', meetingId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeMeetingParticipants', 'userId', userId)
            const localVarPath = `/calendar/meetings/{meeting_id}/participants/{user_id}`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set a ToDo as done/not done. This can also be set by clients
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {boolean} isDone state of a ToDo item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTodoDone: async (todoId: string, authorization: string, isDone: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoId' is not null or undefined
            assertParamExists('setTodoDone', 'todoId', todoId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('setTodoDone', 'authorization', authorization)
            // verify required parameter 'isDone' is not null or undefined
            assertParamExists('setTodoDone', 'isDone', isDone)
            const localVarPath = `/calendar/todos/done/{todo_id}`
                .replace(`{${"todo_id"}}`, encodeURIComponent(String(todoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (isDone !== undefined) { 
                localVarFormParams.set('isDone', isDone as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId ID of a meeting which will be updated
         * @param {Meeting} meetingObj Meeting Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting: async (authorization: string, meetingId: string, meetingObj: Meeting, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateMeeting', 'authorization', authorization)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('updateMeeting', 'meetingId', meetingId)
            // verify required parameter 'meetingObj' is not null or undefined
            assertParamExists('updateMeeting', 'meetingObj', meetingObj)
            const localVarPath = `/calendar/meetings/{meeting_id}`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update specific note for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} messageId The meeting ID of the message being updated
         * @param {MeetingChatMessage} chatObj Chat message. Ensure that both ID and text fields of the object are filled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingChatMessage: async (authorization: string, messageId: string, chatObj: MeetingChatMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateMeetingChatMessage', 'authorization', authorization)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('updateMeetingChatMessage', 'messageId', messageId)
            // verify required parameter 'chatObj' is not null or undefined
            assertParamExists('updateMeetingChatMessage', 'chatObj', chatObj)
            const localVarPath = `/calendar/meetings/chat/message/{message_id}`
                .replace(`{${"message_id"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update specific note for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} noteId The meeting ID for which the notes will be retrieved
         * @param {string} note Text field for any notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingNote: async (authorization: string, noteId: string, note: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateMeetingNote', 'authorization', authorization)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateMeetingNote', 'noteId', noteId)
            // verify required parameter 'note' is not null or undefined
            assertParamExists('updateMeetingNote', 'note', note)
            const localVarPath = `/calendar/meetings/notes/{note_id}`
                .replace(`{${"note_id"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (note !== undefined) { 
                localVarFormParams.set('note', note as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a participant status
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are removing the participant from
         * @param {string} userId The userID of the participant being removed
         * @param {number} statusId The RSVP status ID to update for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingParticipantRSVP: async (authorization: string, meetingId: string, userId: string, statusId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateMeetingParticipantRSVP', 'authorization', authorization)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('updateMeetingParticipantRSVP', 'meetingId', meetingId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateMeetingParticipantRSVP', 'userId', userId)
            // verify required parameter 'statusId' is not null or undefined
            assertParamExists('updateMeetingParticipantRSVP', 'statusId', statusId)
            const localVarPath = `/calendar/meetings/{meeting_id}/participants/{user_id}/{status_id}`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"status_id"}}`, encodeURIComponent(String(statusId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Meeting Request.
         * @param {string} authorization an authorization header
         * @param {string} requestId ID of a request
         * @param {string} [startTime] Requested start time. Accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} [endTime] Requested end time. Accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} [meetingType] The meeting type
         * @param {string} [meetingID] Referenced meeting ID.
         * @param {string} [note] text field for notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingRequest: async (authorization: string, requestId: string, startTime?: string, endTime?: string, meetingType?: string, meetingID?: string, note?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateMeetingRequest', 'authorization', authorization)
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('updateMeetingRequest', 'requestId', requestId)
            const localVarPath = `/calendar/meetings/requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (startTime !== undefined) { 
                localVarFormParams.set('startTime', startTime as any);
            }
    
            if (endTime !== undefined) { 
                localVarFormParams.set('endTime', endTime as any);
            }
    
            if (meetingType !== undefined) { 
                localVarFormParams.set('meetingType', meetingType as any);
            }
    
            if (meetingID !== undefined) { 
                localVarFormParams.set('meetingID', meetingID as any);
            }
    
            if (note !== undefined) { 
                localVarFormParams.set('note', note as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update ToDo
         * @param {string} meetingId ID of a meeting
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {string} item description of ToDo
         * @param {boolean} isDone state of a ToDo item.
         * @param {string} assignee userID of assignee has to do the ToDo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingTodos: async (meetingId: string, todoId: string, authorization: string, item: string, isDone: boolean, assignee: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('updateMeetingTodos', 'meetingId', meetingId)
            // verify required parameter 'todoId' is not null or undefined
            assertParamExists('updateMeetingTodos', 'todoId', todoId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateMeetingTodos', 'authorization', authorization)
            // verify required parameter 'item' is not null or undefined
            assertParamExists('updateMeetingTodos', 'item', item)
            // verify required parameter 'isDone' is not null or undefined
            assertParamExists('updateMeetingTodos', 'isDone', isDone)
            // verify required parameter 'assignee' is not null or undefined
            assertParamExists('updateMeetingTodos', 'assignee', assignee)
            const localVarPath = `/calendar/meetings/{meeting_id}/todos/{todo_id}`
                .replace(`{${"meeting_id"}}`, encodeURIComponent(String(meetingId)))
                .replace(`{${"todo_id"}}`, encodeURIComponent(String(todoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (item !== undefined) { 
                localVarFormParams.set('item', item as any);
            }
    
            if (isDone !== undefined) { 
                localVarFormParams.set('isDone', isDone as any);
            }
    
            if (assignee !== undefined) { 
                localVarFormParams.set('assignee', assignee as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update meeting-type.
         * @param {string} meetingTypeId meeting-type id.
         * @param {string} authorization an authorization header
         * @param {string} [name] name of the meeting-type
         * @param {number} [preSlices] number of pre-slices.
         * @param {number} [slices] number of slices.
         * @param {number} [postSlices] number of post-slices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingType: async (meetingTypeId: string, authorization: string, name?: string, preSlices?: number, slices?: number, postSlices?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingTypeId' is not null or undefined
            assertParamExists('updateMeetingType', 'meetingTypeId', meetingTypeId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateMeetingType', 'authorization', authorization)
            const localVarPath = `/calendar/meetings/def/types/{meeting_type_id}`
                .replace(`{${"meeting_type_id"}}`, encodeURIComponent(String(meetingTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (name !== undefined) { 
                localVarFormParams.set('name', name as any);
            }
    
            if (preSlices !== undefined) { 
                localVarFormParams.set('preSlices', preSlices as any);
            }
    
            if (slices !== undefined) { 
                localVarFormParams.set('slices', slices as any);
            }
    
            if (postSlices !== undefined) { 
                localVarFormParams.set('postSlices', postSlices as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update ToDo
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {OrgToDo} orgTodoObj A ToDo Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTodos: async (todoId: string, authorization: string, orgTodoObj: OrgToDo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoId' is not null or undefined
            assertParamExists('updateTodos', 'todoId', todoId)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateTodos', 'authorization', authorization)
            // verify required parameter 'orgTodoObj' is not null or undefined
            assertParamExists('updateTodos', 'orgTodoObj', orgTodoObj)
            const localVarPath = `/calendar/todos/{todo_id}`
                .replace(`{${"todo_id"}}`, encodeURIComponent(String(todoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgTodoObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new meeting chat message.
         * @param {string} authorization an authorization header
         * @param {MeetingChatMessage} chatObj Cchat object. Only the text amd meeting_id fields need to be filled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMeetingChatMessage(authorization: string, chatObj: MeetingChatMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMeetingChatMessage(authorization, chatObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add new meeting note. Only requires the note field of the MeetingNote object to be filled. All other attributes are disregarded.
         * @param {string} authorization an authorization header
         * @param {MeetingNote} noteObj Note object. Fill in the note, meeting_id, created_by and client_id fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMeetingNote(authorization: string, noteObj: MeetingNote, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMeetingNote(authorization, noteObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a participant to a meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are adding the participant to
         * @param {string} userId The userID of the participant being added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMeetingParticipants(authorization: string, meetingId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMeetingParticipants(authorization, meetingId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add ToDo to meeting.
         * @param {string} meetingId ID of a meeting
         * @param {string} authorization an authorization header
         * @param {string} item description of ToDo
         * @param {string} clientId ID of the client
         * @param {string} assignee userID of assignee has to do the ToDo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMeetingTodos(meetingId: string, authorization: string, item: string, clientId: string, assignee: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMeetingTodos(meetingId, authorization, item, clientId, assignee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create meeting-type.
         * @param {string} authorization an authorization header
         * @param {string} name name of the meeting-type
         * @param {number} preSlices number of pre-slices.
         * @param {number} slices number of slices.
         * @param {number} postSlices number of post-slices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMeetingType(authorization: string, name: string, preSlices: number, slices: number, postSlices: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMeetingType(authorization, name, preSlices, slices, postSlices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a new ToDo task.
         * @param {string} authorization an authorization header
         * @param {OrgToDo} orgTodoObj A ToDo Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNewTodo(authorization: string, orgTodoObj: OrgToDo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNewTodo(authorization, orgTodoObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Meeting Request.
         * @param {string} authorization an authorization header
         * @param {string} meetingType The meeting type
         * @param {string} [note] The request description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeetingRequest(authorization: string, meetingType: string, note?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeetingRequest(authorization, meetingType, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Special Meeting Request - such as contact-back or a reschedule existing meeting.
         * @param {string} authorization an authorization header
         * @param {string} requestType The special request type
         * @param {MeetingRequest} requestObj Request Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeetingSpecialRequest(authorization: string, requestType: string, requestObj: MeetingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeetingSpecialRequest(authorization, requestType, requestObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new meeting
         * @param {string} authorization an authorization header
         * @param {Meeting} meetingObj Meeting Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewMeeting(authorization: string, meetingObj: Meeting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewMeeting(authorization, meetingObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId ID of a meeting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeeting(authorization: string, meetingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeeting(authorization, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete meeting chat message
         * @param {string} authorization an authorization header
         * @param {string} messageId The meeting ID of the message being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeetingChatMessages(authorization: string, messageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeetingChatMessages(authorization, messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete meeting note
         * @param {string} authorization an authorization header
         * @param {string} noteId The meeting ID for which the notes will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeetingNote(authorization: string, noteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeetingNote(authorization, noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Meeting Request. This action can be performed by clients and consultants.
         * @param {string} authorization an authorization header
         * @param {string} requestId The id of the meeting request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeetingRequest(authorization: string, requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeetingRequest(authorization, requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete ToDo
         * @param {string} meetingId ID of a meeting
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeetingTodos(meetingId: string, todoId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeetingTodos(meetingId, todoId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete meeting-type.
         * @param {string} meetingTypeId meeting-type id.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeetingType(meetingTypeId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeetingType(meetingTypeId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete ToDo
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTodos(todoId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTodos(todoId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get availabilities for one or more consultant.
         * @param {string} authorization an authorization header
         * @param {AvailabilityRequest} availabilityRequest Availability request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailabilities(authorization: string, availabilityRequest: AvailabilityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailabilities(authorization, availabilityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get chat history for the specified user_id (over all the meetings)
         * @param {string} authorization an authorization header
         * @param {string} userId The client ID for which the chat history will be retrieved
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatHistoryForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatHistoryForUser(authorization, userId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all open meeting requests from a client, including the scheduled meetings for these requests.
         * @param {string} authorization an authorization header
         * @param {string} clientId ID of a client to get the requests for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientRequests(authorization: string, clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientRequests(authorization, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all TODOS for a specific client
         * @param {string} clientId client user id for which are the todos.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientTodos(clientId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientTodos(clientId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all closed meeting requests.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClosedRequests(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClosedRequests(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a single meeting by its ID
         * @param {string} authorization an authorization header
         * @param {string} meetingId Id of a meeting to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeeting(authorization: string, meetingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeeting(authorization, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get meeting chat for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID for which the chat will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingChat(authorization: string, meetingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingChat(authorization, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get meeting notes for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID for which the notes will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingNotes(authorization: string, meetingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingNotes(authorization, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get TODOS for meeting
         * @param {string} meetingId ID of a meeting
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingTodos(meetingId: string, authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingTodos(meetingId, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Meeting-types.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingTypes(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingTypes(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get meetings for an organization
         * @param {string} authorization an authorization header
         * @param {string} fromDate Start of range of meetings being requested in RFC 3339
         * @param {string} toDate End of range of meetings being requested in RFC 3339
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingsForOrg(authorization: string, fromDate: string, toDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingsForOrg(authorization, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get meetings for a user
         * @param {string} authorization an authorization header
         * @param {string} userId user_id to get the meetings for (clients can only see theirs)
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingsForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingsForUser(authorization, userId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all the open TODOS assigned to the connected user (consultant/client) or of the whole organization (admin)
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyTodos(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyTodos(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get note history for the specified user_id (over all the meetings)
         * @param {string} authorization an authorization header
         * @param {string} userId The client ID for which the chat history will be retrieved
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteHistoryForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteHistoryForUser(authorization, userId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all open meeting requests.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenMeetingRequests(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenMeetingRequests(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the weekly report data.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns 3 months of weekly report data
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportChart(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportChart(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get request by ID.
         * @param {string} authorization an authorization header
         * @param {string} requestId ID of a request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequest(authorization: string, requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequest(authorization, requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get range or requests.
         * @param {string} authorization an authorization header
         * @param {string} from The starting date for the range query
         * @param {string} to The end date for the range query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequestsRange(authorization: string, from: string, to: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequestsRange(authorization, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove a participant from a meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are removing the participant from
         * @param {string} userId The userID of the participant being removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMeetingParticipants(authorization: string, meetingId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMeetingParticipants(authorization, meetingId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Set a ToDo as done/not done. This can also be set by clients
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {boolean} isDone state of a ToDo item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTodoDone(todoId: string, authorization: string, isDone: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTodoDone(todoId, authorization, isDone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId ID of a meeting which will be updated
         * @param {Meeting} meetingObj Meeting Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeeting(authorization: string, meetingId: string, meetingObj: Meeting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeeting(authorization, meetingId, meetingObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update specific note for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} messageId The meeting ID of the message being updated
         * @param {MeetingChatMessage} chatObj Chat message. Ensure that both ID and text fields of the object are filled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeetingChatMessage(authorization: string, messageId: string, chatObj: MeetingChatMessage, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeetingChatMessage(authorization, messageId, chatObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update specific note for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} noteId The meeting ID for which the notes will be retrieved
         * @param {string} note Text field for any notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeetingNote(authorization: string, noteId: string, note: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeetingNote(authorization, noteId, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a participant status
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are removing the participant from
         * @param {string} userId The userID of the participant being removed
         * @param {number} statusId The RSVP status ID to update for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeetingParticipantRSVP(authorization: string, meetingId: string, userId: string, statusId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeetingParticipantRSVP(authorization, meetingId, userId, statusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Meeting Request.
         * @param {string} authorization an authorization header
         * @param {string} requestId ID of a request
         * @param {string} [startTime] Requested start time. Accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} [endTime] Requested end time. Accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} [meetingType] The meeting type
         * @param {string} [meetingID] Referenced meeting ID.
         * @param {string} [note] text field for notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeetingRequest(authorization: string, requestId: string, startTime?: string, endTime?: string, meetingType?: string, meetingID?: string, note?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeetingRequest(authorization, requestId, startTime, endTime, meetingType, meetingID, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update ToDo
         * @param {string} meetingId ID of a meeting
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {string} item description of ToDo
         * @param {boolean} isDone state of a ToDo item.
         * @param {string} assignee userID of assignee has to do the ToDo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeetingTodos(meetingId: string, todoId: string, authorization: string, item: string, isDone: boolean, assignee: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeetingTodos(meetingId, todoId, authorization, item, isDone, assignee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update meeting-type.
         * @param {string} meetingTypeId meeting-type id.
         * @param {string} authorization an authorization header
         * @param {string} [name] name of the meeting-type
         * @param {number} [preSlices] number of pre-slices.
         * @param {number} [slices] number of slices.
         * @param {number} [postSlices] number of post-slices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeetingType(meetingTypeId: string, authorization: string, name?: string, preSlices?: number, slices?: number, postSlices?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeetingType(meetingTypeId, authorization, name, preSlices, slices, postSlices, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update ToDo
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {OrgToDo} orgTodoObj A ToDo Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTodos(todoId: string, authorization: string, orgTodoObj: OrgToDo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTodos(todoId, authorization, orgTodoObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new meeting chat message.
         * @param {string} authorization an authorization header
         * @param {MeetingChatMessage} chatObj Cchat object. Only the text amd meeting_id fields need to be filled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingChatMessage(authorization: string, chatObj: MeetingChatMessage, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.addMeetingChatMessage(authorization, chatObj, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add new meeting note. Only requires the note field of the MeetingNote object to be filled. All other attributes are disregarded.
         * @param {string} authorization an authorization header
         * @param {MeetingNote} noteObj Note object. Fill in the note, meeting_id, created_by and client_id fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingNote(authorization: string, noteObj: MeetingNote, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.addMeetingNote(authorization, noteObj, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a participant to a meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are adding the participant to
         * @param {string} userId The userID of the participant being added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingParticipants(authorization: string, meetingId: string, userId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.addMeetingParticipants(authorization, meetingId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add ToDo to meeting.
         * @param {string} meetingId ID of a meeting
         * @param {string} authorization an authorization header
         * @param {string} item description of ToDo
         * @param {string} clientId ID of the client
         * @param {string} assignee userID of assignee has to do the ToDo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingTodos(meetingId: string, authorization: string, item: string, clientId: string, assignee: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.addMeetingTodos(meetingId, authorization, item, clientId, assignee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create meeting-type.
         * @param {string} authorization an authorization header
         * @param {string} name name of the meeting-type
         * @param {number} preSlices number of pre-slices.
         * @param {number} slices number of slices.
         * @param {number} postSlices number of post-slices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeetingType(authorization: string, name: string, preSlices: number, slices: number, postSlices: number, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.addMeetingType(authorization, name, preSlices, slices, postSlices, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new ToDo task.
         * @param {string} authorization an authorization header
         * @param {OrgToDo} orgTodoObj A ToDo Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewTodo(authorization: string, orgTodoObj: OrgToDo, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.addNewTodo(authorization, orgTodoObj, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Meeting Request.
         * @param {string} authorization an authorization header
         * @param {string} meetingType The meeting type
         * @param {string} [note] The request description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingRequest(authorization: string, meetingType: string, note?: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.createMeetingRequest(authorization, meetingType, note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Special Meeting Request - such as contact-back or a reschedule existing meeting.
         * @param {string} authorization an authorization header
         * @param {string} requestType The special request type
         * @param {MeetingRequest} requestObj Request Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingSpecialRequest(authorization: string, requestType: string, requestObj: MeetingRequest, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.createMeetingSpecialRequest(authorization, requestType, requestObj, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new meeting
         * @param {string} authorization an authorization header
         * @param {Meeting} meetingObj Meeting Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewMeeting(authorization: string, meetingObj: Meeting, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.createNewMeeting(authorization, meetingObj, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId ID of a meeting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeeting(authorization: string, meetingId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.deleteMeeting(authorization, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete meeting chat message
         * @param {string} authorization an authorization header
         * @param {string} messageId The meeting ID of the message being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingChatMessages(authorization: string, messageId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.deleteMeetingChatMessages(authorization, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete meeting note
         * @param {string} authorization an authorization header
         * @param {string} noteId The meeting ID for which the notes will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingNote(authorization: string, noteId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.deleteMeetingNote(authorization, noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Meeting Request. This action can be performed by clients and consultants.
         * @param {string} authorization an authorization header
         * @param {string} requestId The id of the meeting request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingRequest(authorization: string, requestId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.deleteMeetingRequest(authorization, requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete ToDo
         * @param {string} meetingId ID of a meeting
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingTodos(meetingId: string, todoId: string, authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.deleteMeetingTodos(meetingId, todoId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete meeting-type.
         * @param {string} meetingTypeId meeting-type id.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeetingType(meetingTypeId: string, authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.deleteMeetingType(meetingTypeId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete ToDo
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTodos(todoId: string, authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.deleteTodos(todoId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get availabilities for one or more consultant.
         * @param {string} authorization an authorization header
         * @param {AvailabilityRequest} availabilityRequest Availability request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailabilities(authorization: string, availabilityRequest: AvailabilityRequest, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getAvailabilities(authorization, availabilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get chat history for the specified user_id (over all the meetings)
         * @param {string} authorization an authorization header
         * @param {string} userId The client ID for which the chat history will be retrieved
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatHistoryForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getChatHistoryForUser(authorization, userId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all open meeting requests from a client, including the scheduled meetings for these requests.
         * @param {string} authorization an authorization header
         * @param {string} clientId ID of a client to get the requests for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientRequests(authorization: string, clientId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getClientRequests(authorization, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all TODOS for a specific client
         * @param {string} clientId client user id for which are the todos.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientTodos(clientId: string, authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getClientTodos(clientId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all closed meeting requests.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClosedRequests(authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getClosedRequests(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a single meeting by its ID
         * @param {string} authorization an authorization header
         * @param {string} meetingId Id of a meeting to fetch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeeting(authorization: string, meetingId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getMeeting(authorization, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get meeting chat for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID for which the chat will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingChat(authorization: string, meetingId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getMeetingChat(authorization, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get meeting notes for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID for which the notes will be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingNotes(authorization: string, meetingId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getMeetingNotes(authorization, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get TODOS for meeting
         * @param {string} meetingId ID of a meeting
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingTodos(meetingId: string, authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getMeetingTodos(meetingId, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Meeting-types.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingTypes(authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getMeetingTypes(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get meetings for an organization
         * @param {string} authorization an authorization header
         * @param {string} fromDate Start of range of meetings being requested in RFC 3339
         * @param {string} toDate End of range of meetings being requested in RFC 3339
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsForOrg(authorization: string, fromDate: string, toDate: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getMeetingsForOrg(authorization, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get meetings for a user
         * @param {string} authorization an authorization header
         * @param {string} userId user_id to get the meetings for (clients can only see theirs)
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingsForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getMeetingsForUser(authorization, userId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all the open TODOS assigned to the connected user (consultant/client) or of the whole organization (admin)
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTodos(authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getMyTodos(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Get note history for the specified user_id (over all the meetings)
         * @param {string} authorization an authorization header
         * @param {string} userId The client ID for which the chat history will be retrieved
         * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistoryForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getNoteHistoryForUser(authorization, userId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all open meeting requests.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenMeetingRequests(authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getOpenMeetingRequests(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the weekly report data.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getReport(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns 3 months of weekly report data
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportChart(authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getReportChart(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get request by ID.
         * @param {string} authorization an authorization header
         * @param {string} requestId ID of a request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequest(authorization: string, requestId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getRequest(authorization, requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get range or requests.
         * @param {string} authorization an authorization header
         * @param {string} from The starting date for the range query
         * @param {string} to The end date for the range query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestsRange(authorization: string, from: string, to: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getRequestsRange(authorization, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a participant from a meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are removing the participant from
         * @param {string} userId The userID of the participant being removed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMeetingParticipants(authorization: string, meetingId: string, userId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.removeMeetingParticipants(authorization, meetingId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Set a ToDo as done/not done. This can also be set by clients
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {boolean} isDone state of a ToDo item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTodoDone(todoId: string, authorization: string, isDone: boolean, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.setTodoDone(todoId, authorization, isDone, options).then((request) => request(axios, basePath));
        },
        /**
         * Update meeting
         * @param {string} authorization an authorization header
         * @param {string} meetingId ID of a meeting which will be updated
         * @param {Meeting} meetingObj Meeting Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeeting(authorization: string, meetingId: string, meetingObj: Meeting, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateMeeting(authorization, meetingId, meetingObj, options).then((request) => request(axios, basePath));
        },
        /**
         * Update specific note for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} messageId The meeting ID of the message being updated
         * @param {MeetingChatMessage} chatObj Chat message. Ensure that both ID and text fields of the object are filled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingChatMessage(authorization: string, messageId: string, chatObj: MeetingChatMessage, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateMeetingChatMessage(authorization, messageId, chatObj, options).then((request) => request(axios, basePath));
        },
        /**
         * Update specific note for the specified meeting
         * @param {string} authorization an authorization header
         * @param {string} noteId The meeting ID for which the notes will be retrieved
         * @param {string} note Text field for any notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingNote(authorization: string, noteId: string, note: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateMeetingNote(authorization, noteId, note, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a participant status
         * @param {string} authorization an authorization header
         * @param {string} meetingId The meeting ID that we are removing the participant from
         * @param {string} userId The userID of the participant being removed
         * @param {number} statusId The RSVP status ID to update for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingParticipantRSVP(authorization: string, meetingId: string, userId: string, statusId: number, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateMeetingParticipantRSVP(authorization, meetingId, userId, statusId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Meeting Request.
         * @param {string} authorization an authorization header
         * @param {string} requestId ID of a request
         * @param {string} [startTime] Requested start time. Accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} [endTime] Requested end time. Accepted layout is 2006-01-02T15:04:05.000Z
         * @param {string} [meetingType] The meeting type
         * @param {string} [meetingID] Referenced meeting ID.
         * @param {string} [note] text field for notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingRequest(authorization: string, requestId: string, startTime?: string, endTime?: string, meetingType?: string, meetingID?: string, note?: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateMeetingRequest(authorization, requestId, startTime, endTime, meetingType, meetingID, note, options).then((request) => request(axios, basePath));
        },
        /**
         * Update ToDo
         * @param {string} meetingId ID of a meeting
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {string} item description of ToDo
         * @param {boolean} isDone state of a ToDo item.
         * @param {string} assignee userID of assignee has to do the ToDo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingTodos(meetingId: string, todoId: string, authorization: string, item: string, isDone: boolean, assignee: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateMeetingTodos(meetingId, todoId, authorization, item, isDone, assignee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update meeting-type.
         * @param {string} meetingTypeId meeting-type id.
         * @param {string} authorization an authorization header
         * @param {string} [name] name of the meeting-type
         * @param {number} [preSlices] number of pre-slices.
         * @param {number} [slices] number of slices.
         * @param {number} [postSlices] number of post-slices.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeetingType(meetingTypeId: string, authorization: string, name?: string, preSlices?: number, slices?: number, postSlices?: number, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateMeetingType(meetingTypeId, authorization, name, preSlices, slices, postSlices, options).then((request) => request(axios, basePath));
        },
        /**
         * Update ToDo
         * @param {string} todoId ID of a todo
         * @param {string} authorization an authorization header
         * @param {OrgToDo} orgTodoObj A ToDo Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTodos(todoId: string, authorization: string, orgTodoObj: OrgToDo, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateTodos(todoId, authorization, orgTodoObj, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * 
     * @summary Add new meeting chat message.
     * @param {string} authorization an authorization header
     * @param {MeetingChatMessage} chatObj Cchat object. Only the text amd meeting_id fields need to be filled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public addMeetingChatMessage(authorization: string, chatObj: MeetingChatMessage, options?: any) {
        return CalendarApiFp(this.configuration).addMeetingChatMessage(authorization, chatObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add new meeting note. Only requires the note field of the MeetingNote object to be filled. All other attributes are disregarded.
     * @param {string} authorization an authorization header
     * @param {MeetingNote} noteObj Note object. Fill in the note, meeting_id, created_by and client_id fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public addMeetingNote(authorization: string, noteObj: MeetingNote, options?: any) {
        return CalendarApiFp(this.configuration).addMeetingNote(authorization, noteObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a participant to a meeting
     * @param {string} authorization an authorization header
     * @param {string} meetingId The meeting ID that we are adding the participant to
     * @param {string} userId The userID of the participant being added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public addMeetingParticipants(authorization: string, meetingId: string, userId: string, options?: any) {
        return CalendarApiFp(this.configuration).addMeetingParticipants(authorization, meetingId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add ToDo to meeting.
     * @param {string} meetingId ID of a meeting
     * @param {string} authorization an authorization header
     * @param {string} item description of ToDo
     * @param {string} clientId ID of the client
     * @param {string} assignee userID of assignee has to do the ToDo.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public addMeetingTodos(meetingId: string, authorization: string, item: string, clientId: string, assignee: string, options?: any) {
        return CalendarApiFp(this.configuration).addMeetingTodos(meetingId, authorization, item, clientId, assignee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create meeting-type.
     * @param {string} authorization an authorization header
     * @param {string} name name of the meeting-type
     * @param {number} preSlices number of pre-slices.
     * @param {number} slices number of slices.
     * @param {number} postSlices number of post-slices.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public addMeetingType(authorization: string, name: string, preSlices: number, slices: number, postSlices: number, options?: any) {
        return CalendarApiFp(this.configuration).addMeetingType(authorization, name, preSlices, slices, postSlices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new ToDo task.
     * @param {string} authorization an authorization header
     * @param {OrgToDo} orgTodoObj A ToDo Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public addNewTodo(authorization: string, orgTodoObj: OrgToDo, options?: any) {
        return CalendarApiFp(this.configuration).addNewTodo(authorization, orgTodoObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Meeting Request.
     * @param {string} authorization an authorization header
     * @param {string} meetingType The meeting type
     * @param {string} [note] The request description.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public createMeetingRequest(authorization: string, meetingType: string, note?: string, options?: any) {
        return CalendarApiFp(this.configuration).createMeetingRequest(authorization, meetingType, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Special Meeting Request - such as contact-back or a reschedule existing meeting.
     * @param {string} authorization an authorization header
     * @param {string} requestType The special request type
     * @param {MeetingRequest} requestObj Request Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public createMeetingSpecialRequest(authorization: string, requestType: string, requestObj: MeetingRequest, options?: any) {
        return CalendarApiFp(this.configuration).createMeetingSpecialRequest(authorization, requestType, requestObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new meeting
     * @param {string} authorization an authorization header
     * @param {Meeting} meetingObj Meeting Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public createNewMeeting(authorization: string, meetingObj: Meeting, options?: any) {
        return CalendarApiFp(this.configuration).createNewMeeting(authorization, meetingObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete meeting
     * @param {string} authorization an authorization header
     * @param {string} meetingId ID of a meeting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteMeeting(authorization: string, meetingId: string, options?: any) {
        return CalendarApiFp(this.configuration).deleteMeeting(authorization, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete meeting chat message
     * @param {string} authorization an authorization header
     * @param {string} messageId The meeting ID of the message being deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteMeetingChatMessages(authorization: string, messageId: string, options?: any) {
        return CalendarApiFp(this.configuration).deleteMeetingChatMessages(authorization, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete meeting note
     * @param {string} authorization an authorization header
     * @param {string} noteId The meeting ID for which the notes will be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteMeetingNote(authorization: string, noteId: string, options?: any) {
        return CalendarApiFp(this.configuration).deleteMeetingNote(authorization, noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Meeting Request. This action can be performed by clients and consultants.
     * @param {string} authorization an authorization header
     * @param {string} requestId The id of the meeting request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteMeetingRequest(authorization: string, requestId: string, options?: any) {
        return CalendarApiFp(this.configuration).deleteMeetingRequest(authorization, requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete ToDo
     * @param {string} meetingId ID of a meeting
     * @param {string} todoId ID of a todo
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteMeetingTodos(meetingId: string, todoId: string, authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).deleteMeetingTodos(meetingId, todoId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete meeting-type.
     * @param {string} meetingTypeId meeting-type id.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteMeetingType(meetingTypeId: string, authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).deleteMeetingType(meetingTypeId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete ToDo
     * @param {string} todoId ID of a todo
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteTodos(todoId: string, authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).deleteTodos(todoId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get availabilities for one or more consultant.
     * @param {string} authorization an authorization header
     * @param {AvailabilityRequest} availabilityRequest Availability request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getAvailabilities(authorization: string, availabilityRequest: AvailabilityRequest, options?: any) {
        return CalendarApiFp(this.configuration).getAvailabilities(authorization, availabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get chat history for the specified user_id (over all the meetings)
     * @param {string} authorization an authorization header
     * @param {string} userId The client ID for which the chat history will be retrieved
     * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
     * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getChatHistoryForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any) {
        return CalendarApiFp(this.configuration).getChatHistoryForUser(authorization, userId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all open meeting requests from a client, including the scheduled meetings for these requests.
     * @param {string} authorization an authorization header
     * @param {string} clientId ID of a client to get the requests for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getClientRequests(authorization: string, clientId: string, options?: any) {
        return CalendarApiFp(this.configuration).getClientRequests(authorization, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all TODOS for a specific client
     * @param {string} clientId client user id for which are the todos.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getClientTodos(clientId: string, authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).getClientTodos(clientId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all closed meeting requests.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getClosedRequests(authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).getClosedRequests(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a single meeting by its ID
     * @param {string} authorization an authorization header
     * @param {string} meetingId Id of a meeting to fetch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getMeeting(authorization: string, meetingId: string, options?: any) {
        return CalendarApiFp(this.configuration).getMeeting(authorization, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get meeting chat for the specified meeting
     * @param {string} authorization an authorization header
     * @param {string} meetingId The meeting ID for which the chat will be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getMeetingChat(authorization: string, meetingId: string, options?: any) {
        return CalendarApiFp(this.configuration).getMeetingChat(authorization, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get meeting notes for the specified meeting
     * @param {string} authorization an authorization header
     * @param {string} meetingId The meeting ID for which the notes will be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getMeetingNotes(authorization: string, meetingId: string, options?: any) {
        return CalendarApiFp(this.configuration).getMeetingNotes(authorization, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get TODOS for meeting
     * @param {string} meetingId ID of a meeting
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getMeetingTodos(meetingId: string, authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).getMeetingTodos(meetingId, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Meeting-types.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getMeetingTypes(authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).getMeetingTypes(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get meetings for an organization
     * @param {string} authorization an authorization header
     * @param {string} fromDate Start of range of meetings being requested in RFC 3339
     * @param {string} toDate End of range of meetings being requested in RFC 3339
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getMeetingsForOrg(authorization: string, fromDate: string, toDate: string, options?: any) {
        return CalendarApiFp(this.configuration).getMeetingsForOrg(authorization, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get meetings for a user
     * @param {string} authorization an authorization header
     * @param {string} userId user_id to get the meetings for (clients can only see theirs)
     * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
     * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getMeetingsForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any) {
        return CalendarApiFp(this.configuration).getMeetingsForUser(authorization, userId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all the open TODOS assigned to the connected user (consultant/client) or of the whole organization (admin)
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getMyTodos(authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).getMyTodos(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get note history for the specified user_id (over all the meetings)
     * @param {string} authorization an authorization header
     * @param {string} userId The client ID for which the chat history will be retrieved
     * @param {string} fromDate accepted layout is 2006-01-02T15:04:05.000Z
     * @param {string} toDate accepted layout is 2006-01-02T15:04:05.000Z
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getNoteHistoryForUser(authorization: string, userId: string, fromDate: string, toDate: string, options?: any) {
        return CalendarApiFp(this.configuration).getNoteHistoryForUser(authorization, userId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all open meeting requests.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getOpenMeetingRequests(authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).getOpenMeetingRequests(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the weekly report data.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getReport(authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).getReport(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns 3 months of weekly report data
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getReportChart(authorization: string, options?: any) {
        return CalendarApiFp(this.configuration).getReportChart(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get request by ID.
     * @param {string} authorization an authorization header
     * @param {string} requestId ID of a request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getRequest(authorization: string, requestId: string, options?: any) {
        return CalendarApiFp(this.configuration).getRequest(authorization, requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get range or requests.
     * @param {string} authorization an authorization header
     * @param {string} from The starting date for the range query
     * @param {string} to The end date for the range query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getRequestsRange(authorization: string, from: string, to: string, options?: any) {
        return CalendarApiFp(this.configuration).getRequestsRange(authorization, from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a participant from a meeting
     * @param {string} authorization an authorization header
     * @param {string} meetingId The meeting ID that we are removing the participant from
     * @param {string} userId The userID of the participant being removed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public removeMeetingParticipants(authorization: string, meetingId: string, userId: string, options?: any) {
        return CalendarApiFp(this.configuration).removeMeetingParticipants(authorization, meetingId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set a ToDo as done/not done. This can also be set by clients
     * @param {string} todoId ID of a todo
     * @param {string} authorization an authorization header
     * @param {boolean} isDone state of a ToDo item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public setTodoDone(todoId: string, authorization: string, isDone: boolean, options?: any) {
        return CalendarApiFp(this.configuration).setTodoDone(todoId, authorization, isDone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update meeting
     * @param {string} authorization an authorization header
     * @param {string} meetingId ID of a meeting which will be updated
     * @param {Meeting} meetingObj Meeting Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateMeeting(authorization: string, meetingId: string, meetingObj: Meeting, options?: any) {
        return CalendarApiFp(this.configuration).updateMeeting(authorization, meetingId, meetingObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update specific note for the specified meeting
     * @param {string} authorization an authorization header
     * @param {string} messageId The meeting ID of the message being updated
     * @param {MeetingChatMessage} chatObj Chat message. Ensure that both ID and text fields of the object are filled.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateMeetingChatMessage(authorization: string, messageId: string, chatObj: MeetingChatMessage, options?: any) {
        return CalendarApiFp(this.configuration).updateMeetingChatMessage(authorization, messageId, chatObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update specific note for the specified meeting
     * @param {string} authorization an authorization header
     * @param {string} noteId The meeting ID for which the notes will be retrieved
     * @param {string} note Text field for any notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateMeetingNote(authorization: string, noteId: string, note: string, options?: any) {
        return CalendarApiFp(this.configuration).updateMeetingNote(authorization, noteId, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a participant status
     * @param {string} authorization an authorization header
     * @param {string} meetingId The meeting ID that we are removing the participant from
     * @param {string} userId The userID of the participant being removed
     * @param {number} statusId The RSVP status ID to update for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateMeetingParticipantRSVP(authorization: string, meetingId: string, userId: string, statusId: number, options?: any) {
        return CalendarApiFp(this.configuration).updateMeetingParticipantRSVP(authorization, meetingId, userId, statusId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Meeting Request.
     * @param {string} authorization an authorization header
     * @param {string} requestId ID of a request
     * @param {string} [startTime] Requested start time. Accepted layout is 2006-01-02T15:04:05.000Z
     * @param {string} [endTime] Requested end time. Accepted layout is 2006-01-02T15:04:05.000Z
     * @param {string} [meetingType] The meeting type
     * @param {string} [meetingID] Referenced meeting ID.
     * @param {string} [note] text field for notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateMeetingRequest(authorization: string, requestId: string, startTime?: string, endTime?: string, meetingType?: string, meetingID?: string, note?: string, options?: any) {
        return CalendarApiFp(this.configuration).updateMeetingRequest(authorization, requestId, startTime, endTime, meetingType, meetingID, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update ToDo
     * @param {string} meetingId ID of a meeting
     * @param {string} todoId ID of a todo
     * @param {string} authorization an authorization header
     * @param {string} item description of ToDo
     * @param {boolean} isDone state of a ToDo item.
     * @param {string} assignee userID of assignee has to do the ToDo.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateMeetingTodos(meetingId: string, todoId: string, authorization: string, item: string, isDone: boolean, assignee: string, options?: any) {
        return CalendarApiFp(this.configuration).updateMeetingTodos(meetingId, todoId, authorization, item, isDone, assignee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update meeting-type.
     * @param {string} meetingTypeId meeting-type id.
     * @param {string} authorization an authorization header
     * @param {string} [name] name of the meeting-type
     * @param {number} [preSlices] number of pre-slices.
     * @param {number} [slices] number of slices.
     * @param {number} [postSlices] number of post-slices.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateMeetingType(meetingTypeId: string, authorization: string, name?: string, preSlices?: number, slices?: number, postSlices?: number, options?: any) {
        return CalendarApiFp(this.configuration).updateMeetingType(meetingTypeId, authorization, name, preSlices, slices, postSlices, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update ToDo
     * @param {string} todoId ID of a todo
     * @param {string} authorization an authorization header
     * @param {OrgToDo} orgTodoObj A ToDo Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateTodos(todoId: string, authorization: string, orgTodoObj: OrgToDo, options?: any) {
        return CalendarApiFp(this.configuration).updateTodos(todoId, authorization, orgTodoObj, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataApi - axios parameter creator
 * @export
 */
export const DataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Administrate the uploaded file
         * @param {string} authorization an authorization header
         * @param {string} clientId user ID.
         * @param {any} file the file object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFile: async (authorization: string, clientId: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addFile', 'authorization', authorization)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('addFile', 'clientId', clientId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('addFile', 'file', file)
            const localVarPath = `/data/users/{client_id}/files`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Authenticated User File.
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthenticatedUserFile: async (authorization: string, fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteAuthenticatedUserFile', 'authorization', authorization)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteAuthenticatedUserFile', 'fileId', fileId)
            const localVarPath = `/data/files/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get DownladObjectURL for a specific file id (logged in user level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUserDownloadObjectURL: async (authorization: string, fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getAuthenticatedUserDownloadObjectURL', 'authorization', authorization)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getAuthenticatedUserDownloadObjectURL', 'fileId', fileId)
            const localVarPath = `/data/files/{file_id}/down-link`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Authenticated User Files.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUserFiles: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getAuthenticatedUserFiles', 'authorization', authorization)
            const localVarPath = `/data/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get DownladObjectURL by file Id (consultant level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {string} clientId user (client) id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadObjectURL: async (authorization: string, fileId: string, clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getDownloadObjectURL', 'authorization', authorization)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getDownloadObjectURL', 'fileId', fileId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getDownloadObjectURL', 'clientId', clientId)
            const localVarPath = `/data/users/{client_id}/files/{file_id}/down-link`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a file info by its ID (consultant level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByID: async (authorization: string, fileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getFileByID', 'authorization', authorization)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFileByID', 'fileId', fileId)
            const localVarPath = `/data/files/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of files for a specific user.
         * @param {string} authorization an authorization header
         * @param {string} clientId user (client) id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesByUser: async (authorization: string, clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getFilesByUser', 'authorization', authorization)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getFilesByUser', 'clientId', clientId)
            const localVarPath = `/data/users/{client_id}/files`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary POST UploadObjectURL.
         * @param {string} authorization an authorization header
         * @param {string} clientId Client user ID
         * @param {string} fullName File full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadObjectURL: async (authorization: string, clientId: string, fullName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUploadObjectURL', 'authorization', authorization)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getUploadObjectURL', 'clientId', clientId)
            // verify required parameter 'fullName' is not null or undefined
            assertParamExists('getUploadObjectURL', 'fullName', fullName)
            const localVarPath = `/data/users/{client_id}/files/up-link`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (fullName !== undefined) { 
                localVarFormParams.set('full_name', fullName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataApi - functional programming interface
 * @export
 */
export const DataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataApiAxiosParamCreator(configuration)
    return {
        /**
         * Administrate the uploaded file
         * @param {string} authorization an authorization header
         * @param {string} clientId user ID.
         * @param {any} file the file object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFile(authorization: string, clientId: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFile(authorization, clientId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Authenticated User File.
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAuthenticatedUserFile(authorization: string, fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAuthenticatedUserFile(authorization, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get DownladObjectURL for a specific file id (logged in user level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthenticatedUserDownloadObjectURL(authorization: string, fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthenticatedUserDownloadObjectURL(authorization, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Authenticated User Files.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthenticatedUserFiles(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthenticatedUserFiles(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get DownladObjectURL by file Id (consultant level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {string} clientId user (client) id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadObjectURL(authorization: string, fileId: string, clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadObjectURL(authorization, fileId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a file info by its ID (consultant level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileByID(authorization: string, fileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileByID(authorization, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of files for a specific user.
         * @param {string} authorization an authorization header
         * @param {string} clientId user (client) id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesByUser(authorization: string, clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesByUser(authorization, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary POST UploadObjectURL.
         * @param {string} authorization an authorization header
         * @param {string} clientId Client user ID
         * @param {string} fullName File full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadObjectURL(authorization: string, clientId: string, fullName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadObjectURL(authorization, clientId, fullName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataApi - factory interface
 * @export
 */
export const DataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataApiFp(configuration)
    return {
        /**
         * Administrate the uploaded file
         * @param {string} authorization an authorization header
         * @param {string} clientId user ID.
         * @param {any} file the file object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFile(authorization: string, clientId: string, file: any, options?: any): AxiosPromise<DataResponseMessage> {
            return localVarFp.addFile(authorization, clientId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Authenticated User File.
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthenticatedUserFile(authorization: string, fileId: string, options?: any): AxiosPromise<DataResponseMessage> {
            return localVarFp.deleteAuthenticatedUserFile(authorization, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get DownladObjectURL for a specific file id (logged in user level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUserDownloadObjectURL(authorization: string, fileId: string, options?: any): AxiosPromise<DataResponseMessage> {
            return localVarFp.getAuthenticatedUserDownloadObjectURL(authorization, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Authenticated User Files.
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUserFiles(authorization: string, options?: any): AxiosPromise<DataResponseMessage> {
            return localVarFp.getAuthenticatedUserFiles(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get DownladObjectURL by file Id (consultant level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {string} clientId user (client) id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadObjectURL(authorization: string, fileId: string, clientId: string, options?: any): AxiosPromise<DataResponseMessage> {
            return localVarFp.getDownloadObjectURL(authorization, fileId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a file info by its ID (consultant level).
         * @param {string} authorization an authorization header
         * @param {string} fileId file id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByID(authorization: string, fileId: string, options?: any): AxiosPromise<DataResponseMessage> {
            return localVarFp.getFileByID(authorization, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of files for a specific user.
         * @param {string} authorization an authorization header
         * @param {string} clientId user (client) id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesByUser(authorization: string, clientId: string, options?: any): AxiosPromise<DataResponseMessage> {
            return localVarFp.getFilesByUser(authorization, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary POST UploadObjectURL.
         * @param {string} authorization an authorization header
         * @param {string} clientId Client user ID
         * @param {string} fullName File full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadObjectURL(authorization: string, clientId: string, fullName: string, options?: any): AxiosPromise<DataResponseMessage> {
            return localVarFp.getUploadObjectURL(authorization, clientId, fullName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataApi - object-oriented interface
 * @export
 * @class DataApi
 * @extends {BaseAPI}
 */
export class DataApi extends BaseAPI {
    /**
     * Administrate the uploaded file
     * @param {string} authorization an authorization header
     * @param {string} clientId user ID.
     * @param {any} file the file object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public addFile(authorization: string, clientId: string, file: any, options?: any) {
        return DataApiFp(this.configuration).addFile(authorization, clientId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Authenticated User File.
     * @param {string} authorization an authorization header
     * @param {string} fileId file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public deleteAuthenticatedUserFile(authorization: string, fileId: string, options?: any) {
        return DataApiFp(this.configuration).deleteAuthenticatedUserFile(authorization, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get DownladObjectURL for a specific file id (logged in user level).
     * @param {string} authorization an authorization header
     * @param {string} fileId file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getAuthenticatedUserDownloadObjectURL(authorization: string, fileId: string, options?: any) {
        return DataApiFp(this.configuration).getAuthenticatedUserDownloadObjectURL(authorization, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Authenticated User Files.
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getAuthenticatedUserFiles(authorization: string, options?: any) {
        return DataApiFp(this.configuration).getAuthenticatedUserFiles(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get DownladObjectURL by file Id (consultant level).
     * @param {string} authorization an authorization header
     * @param {string} fileId file id
     * @param {string} clientId user (client) id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getDownloadObjectURL(authorization: string, fileId: string, clientId: string, options?: any) {
        return DataApiFp(this.configuration).getDownloadObjectURL(authorization, fileId, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a file info by its ID (consultant level).
     * @param {string} authorization an authorization header
     * @param {string} fileId file id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getFileByID(authorization: string, fileId: string, options?: any) {
        return DataApiFp(this.configuration).getFileByID(authorization, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of files for a specific user.
     * @param {string} authorization an authorization header
     * @param {string} clientId user (client) id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getFilesByUser(authorization: string, clientId: string, options?: any) {
        return DataApiFp(this.configuration).getFilesByUser(authorization, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary POST UploadObjectURL.
     * @param {string} authorization an authorization header
     * @param {string} clientId Client user ID
     * @param {string} fullName File full name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getUploadObjectURL(authorization: string, clientId: string, fullName: string, options?: any) {
        return DataApiFp(this.configuration).getUploadObjectURL(authorization, clientId, fullName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchedulerApi - axios parameter creator
 * @export
 */
export const SchedulerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a new series
         * @param {string} authorization an authorization header
         * @param {Series} seriesObj Series Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSeries: async (authorization: string, seriesObj: Series, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('addSeries', 'authorization', authorization)
            // verify required parameter 'seriesObj' is not null or undefined
            assertParamExists('addSeries', 'seriesObj', seriesObj)
            const localVarPath = `/calendar/my/series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seriesObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete series
         * @param {string} authorization an authorization header
         * @param {string} seriesId The id of the series which we are deleting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeries: async (authorization: string, seriesId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteSeries', 'authorization', authorization)
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('deleteSeries', 'seriesId', seriesId)
            const localVarPath = `/calendar/my/series/{series_id}`
                .replace(`{${"series_id"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get series fur current user
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeries: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getSeries', 'authorization', authorization)
            const localVarPath = `/calendar/my/series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a series
         * @param {string} authorization an authorization header
         * @param {string} seriesId The id of the series which we are updating
         * @param {Series} seriesObj Series Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeries: async (authorization: string, seriesId: string, seriesObj: Series, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('updateSeries', 'authorization', authorization)
            // verify required parameter 'seriesId' is not null or undefined
            assertParamExists('updateSeries', 'seriesId', seriesId)
            // verify required parameter 'seriesObj' is not null or undefined
            assertParamExists('updateSeries', 'seriesObj', seriesObj)
            const localVarPath = `/calendar/my/series/{series_id}`
                .replace(`{${"series_id"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'text/plain';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seriesObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerApi - functional programming interface
 * @export
 */
export const SchedulerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a new series
         * @param {string} authorization an authorization header
         * @param {Series} seriesObj Series Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSeries(authorization: string, seriesObj: Series, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSeries(authorization, seriesObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete series
         * @param {string} authorization an authorization header
         * @param {string} seriesId The id of the series which we are deleting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSeries(authorization: string, seriesId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSeries(authorization, seriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get series fur current user
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeries(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSeries(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a series
         * @param {string} authorization an authorization header
         * @param {string} seriesId The id of the series which we are updating
         * @param {Series} seriesObj Series Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSeries(authorization: string, seriesId: string, seriesObj: Series, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSeries(authorization, seriesId, seriesObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchedulerApi - factory interface
 * @export
 */
export const SchedulerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerApiFp(configuration)
    return {
        /**
         * Add a new series
         * @param {string} authorization an authorization header
         * @param {Series} seriesObj Series Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSeries(authorization: string, seriesObj: Series, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.addSeries(authorization, seriesObj, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete series
         * @param {string} authorization an authorization header
         * @param {string} seriesId The id of the series which we are deleting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSeries(authorization: string, seriesId: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.deleteSeries(authorization, seriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get series fur current user
         * @param {string} authorization an authorization header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeries(authorization: string, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.getSeries(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a series
         * @param {string} authorization an authorization header
         * @param {string} seriesId The id of the series which we are updating
         * @param {Series} seriesObj Series Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeries(authorization: string, seriesId: string, seriesObj: Series, options?: any): AxiosPromise<CalendarResponseMessage> {
            return localVarFp.updateSeries(authorization, seriesId, seriesObj, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerApi - object-oriented interface
 * @export
 * @class SchedulerApi
 * @extends {BaseAPI}
 */
export class SchedulerApi extends BaseAPI {
    /**
     * Add a new series
     * @param {string} authorization an authorization header
     * @param {Series} seriesObj Series Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public addSeries(authorization: string, seriesObj: Series, options?: any) {
        return SchedulerApiFp(this.configuration).addSeries(authorization, seriesObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete series
     * @param {string} authorization an authorization header
     * @param {string} seriesId The id of the series which we are deleting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public deleteSeries(authorization: string, seriesId: string, options?: any) {
        return SchedulerApiFp(this.configuration).deleteSeries(authorization, seriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get series fur current user
     * @param {string} authorization an authorization header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getSeries(authorization: string, options?: any) {
        return SchedulerApiFp(this.configuration).getSeries(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a series
     * @param {string} authorization an authorization header
     * @param {string} seriesId The id of the series which we are updating
     * @param {Series} seriesObj Series Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public updateSeries(authorization: string, seriesId: string, seriesObj: Series, options?: any) {
        return SchedulerApiFp(this.configuration).updateSeries(authorization, seriesId, seriesObj, options).then((request) => request(this.axios, this.basePath));
    }
}


