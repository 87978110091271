import { handleCaughtError } from '_helpers';
import { AuthenticationApi, AuthenticationResponse, AccountsApi, AccountResponseMessage } from 'server/api';

/***
 * This is an interface between the authentication redux store/reducers and the authentication API.
 */

const USER_STORAGE_KEY = 'MEETPLANS_AUTH';
const authApi = new AuthenticationApi();
const accountsApi = new AccountsApi();

function handleErrorResponse(e: any) {
  handleCaughtError(e);
  const error = (e.response && e.response.data && e.response.data.message) || e.message || e.statusText;
  if (error) {
    logout();

    return Promise.reject(error);
  }

  return Promise.reject();
}

/***
 * Authentication
 */
async function login(email: string, password: string): Promise<AuthenticationResponse> {
  try {
    const { data } = await authApi.authenticateUser(email, password);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

function storeLogin(userData: AuthenticationResponse, remember: boolean) {
  const serializedUserData = JSON.stringify(userData);
  if (remember) {
    localStorage.setItem(USER_STORAGE_KEY, serializedUserData);
  } else {
    localStorage.setItem(USER_STORAGE_KEY, serializedUserData);
    sessionStorage.setItem(USER_STORAGE_KEY, serializedUserData);
  }
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem(USER_STORAGE_KEY);
  sessionStorage.removeItem(USER_STORAGE_KEY);
}

function checkAuthentication(): AuthenticationResponse | undefined {
  let serializedUserData = localStorage.getItem(USER_STORAGE_KEY);
  if (serializedUserData === null) {
    serializedUserData = sessionStorage.getItem(USER_STORAGE_KEY);
  }

  if (serializedUserData) {
    return JSON.parse(serializedUserData);
  }

  return;
}

/***
 * user activities
 */

export async function resetPassword(lang: string, email: string) {
  const { data } = await authApi.resetPassword(lang, email);
  return data;
}

export async function updatePassword(hash: string, password: string): Promise<AuthenticationResponse> {
  try {
    const { data } = await authApi.updatePassword(hash, password);
    return data;
  } catch (err) {
    handleCaughtError(err);
    return err;
  }
}

export async function validateHash(hash: string): Promise<number> {
  try {
    const { data } = await authApi.validatePasswordResetHash(hash);
    return data.code?? -1;
  } catch (err) {
    handleCaughtError(err);
    return -1;
  }
}

export async function validateInvitationHash(hash: string): Promise<boolean> {
  if(!hash) {
    return false;
  }
  try {
    const { data } = await accountsApi.validateInviteHash(hash);
    return data.hashvalid??false;      
  } catch (err) {
    handleCaughtError(err);
    return false;
  }
}

async function activateInvitedOrgUser(hash: string, email: string, password: string): Promise<AccountResponseMessage> {
  const { data } = await accountsApi.activateUser(hash, email, 'email_pwd', password);
  return data;
}

export const userService = {
  login,
  logout,
  resetPassword,
  updatePassword,
  validateHash,
  activateInvitedOrgUser,

  storeLogin,
  checkAuthentication,
};
