import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureMessage } from "@sentry/react";
import { handleCaughtError } from '_helpers';
import { AppDispatch } from 'app/store';

import { OrganizationUser } from 'server';
import { RootState } from '../../app/rootReducer';

import { getClientData } from './clientDataService';
import { AsyncRequest, RESPONSE_CODE, startLoading, loadingFailed } from '../asyncRequest';

/*
Gets and upadtes the personal user data
*/

interface ClientInfoState extends AsyncRequest {
  clientData?: OrganizationUser;
}

const initialState: ClientInfoState = {
  loading: false,
  error: null,
  clientData: undefined,
};

export const fetchClientData = createAsyncThunk<OrganizationUser | undefined, string, { state: RootState; dispatch: AppDispatch }>(
  'clientData/fetchClientdata',
  async (clientId: string, { getState, dispatch }) => {
    try {
      const state = getState();
      const authToken = state.authentication.usertoken;
      if (!authToken) {
        const msg = "fetchClientData :: Can't fetch clientInfoData, user is unauthenticated";
        captureMessage(msg);
        return;
      }

      const response = await getClientData(authToken, clientId);

      if (response.code === RESPONSE_CODE.SUCCESS) {
        return response.user;
      } else {
        const msg = "fetchClientData :: error getting client data";
        captureMessage(msg);
      }
    } catch (err) {
      handleCaughtError(err);
    }
  }
);

const accountsSlice = createSlice({
  name: 'clientData',
  initialState,
  reducers: {
    requestStart: startLoading,
    requestFailed: loadingFailed,
    currentClientData(state, action: PayloadAction<OrganizationUser>) {
      state.clientData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchClientData.fulfilled, (state, action) => {
      state.loading = false;
      state.clientData = action.payload;
    });
    builder.addCase(fetchClientData.rejected, (state, action) => {
      state.loading = false;
      state.clientData = undefined;
      state.error = action.error.message!;
    });
  },
});

export const { requestStart, requestFailed } = accountsSlice.actions;
export default accountsSlice.reducer;
