import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Box from 'components/box/Box';
import { updateLang } from 'features/account/accountSlice';
import { RootState } from 'app/rootReducer';
import i18n from 'i18n';

import './AuthLayout.scss';

import { isExpired } from './authSlice';
import {Select} from "antd";

type AuthLayoutProps = { children: any; title: string; description?: string };

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, title, description }: AuthLayoutProps) => {
  const { Option } = Select;
  const lang = useSelector((state: RootState) => state.accounts.pref_lang);
  const logo_url = useSelector((state: RootState) => state.organization.data.logo_url);

  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, expiry } = useSelector((state: RootState) => state.authentication);

  function setLang(lang: string) {
    i18n.changeLanguage(lang);
    dispatch(updateLang(lang));
  }

  useEffect(() => {
    const hasSessionExpired = expiry && isExpired(expiry);
    if (isLoggedIn && !hasSessionExpired) {
      history.push('/app');
    }
  }, [isLoggedIn, history, expiry]);

  return (
    <Container className="authlayout-container">
      <div className="auth-body">
        <Select onChange={(val) => setLang(val)} defaultValue={lang} className="position-fixed" style={{ right: '2rem', top: '2rem' }} aria-label="Choose language">
          <Option value="de" aria-selected={lang === 'de'}>Deutsch</Option>
          <Option value="en" aria-selected={lang === 'en'}>English</Option>
        </Select>
        <Row className="justify-content-md-center">
          <Col md="auto">
            <div className="logo-login mt60">
              <img className="mb40" src={logo_url} alt="Meetplans" />
            </div>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col md="auto" className="main-col">
            <Box title={title} activeVariant="teal">
              {description && (
                <Row>
                  <Col>
                    <p className="px-5">{description}</p>
                  </Col>
                </Row>
              )}
              {children}
            </Box>
          </Col>
        </Row>
      </div>

      <div className="footer">
        Powered by <a href="https://meetplans.com">MeetPlans</a>{' '}
      </div>
      <script src="https://betteruptime.com/widgets/announcement.js" data-id="123707" async={true} type="text/javascript"></script>
    </Container>
  );
};

export default AuthLayout;
