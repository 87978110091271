import { handleErrorResponse } from '_helpers';
import { AccountsApi, AccountResponseMessage, OrganizationUserPersonalData } from 'server';

const accountsApi = new AccountsApi();

async function getPersonalUserData(authToken: string): Promise<AccountResponseMessage> {
  try {
    const { data } = await accountsApi.getPersonalUserData(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function updatePersonalUserData(
  authToken: string,
  userData: OrganizationUserPersonalData,
): Promise<AccountResponseMessage> {
  try {
    const { data } = await accountsApi.updatePersonalUserData(authToken, userData);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function changePassword(authToken: string, password: string, oldpassword: string): Promise<AccountResponseMessage> {
  try {
    const { data } = await accountsApi.changePassword(authToken, password, oldpassword);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getOrganizationUser(userId: string, authToken: string) {
  try {
    const { data } = await accountsApi.getOrganizationUser(userId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addUsersRole(roleId: string, userId: string, authToken: string) {
  try {
    const { data } = await accountsApi.addUserRole(userId, authToken, roleId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteUsersRole(roleId: string, userId: string, authToken: string) {
  try {
    const { data } = await accountsApi.deleteUserRole(roleId, userId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function createLabel(category: string, authToken: string, name: string) {
  try {
    const { data } = await accountsApi.createLabel(authToken, name, category);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addLabelToUser(userId: string, authToken: string, labelId: string) {
  try {
    const { data } = await accountsApi.addLabelToUser(userId, authToken, labelId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getLabels(authToken: string) {
  try {
    const { data } = await accountsApi.getLabels(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getLabelsByUser(authToken: string, userId: string) {
  try {
    const { data } = await accountsApi.getLabelByUser(userId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteLabelFromUser(authToken: string, userId: string, labelId: string) {
  try {
    const { data } = await accountsApi.deleteLabelFromUser(userId, labelId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deleteUserlabel(labelId: string, authToken: string) {
  try {
    const { data } = await accountsApi.deleteLabel(labelId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function searchAnyOrganizationUserByName(authToken: string, name: string, role: string) {
  try {
    const { data } = await accountsApi.searchAnyOrganizationUserByName(authToken, name, role);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function searchOrganizationUsers(authToken: string, name: string) {
  try {
    const { data } = await accountsApi.searchOrganizationUsers(authToken, name, '');
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function searchOrganizationUserLabel(authToken: string, name: string, label: string) {
  try {
    const { data } = await accountsApi.searchOrganizationUsers(authToken, name, label);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function updateUserStatus(authToken: string, status: string) {
  try {
    const { data } = await accountsApi.updateUserStatus(authToken, status);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export const accountService = {
  getPersonalUserData,
  getOrganizationUser,
  updatePersonalUserData,
  changePassword,
  addUsersRole,
  deleteUsersRole,
  createLabel,
  addLabelToUser,
  getUserlabels: getLabels,
  deleteUserlabel,
  getLabelByUser: getLabelsByUser,
  deleteLabelFromUser,
  searchAnyOrganizationUserByName,
  searchOrganizationUsers,
  searchOrganizationUserLabel,
  updateUserStatus,
};
