import { BASE_PATH } from 'server/base';
import { MeetingChatMessage } from 'server';

export const WS_URL = BASE_PATH.replace('http', 'ws') + '/data/ws';

export type SocketMessage = {
  action: string;
  message: string;
  chat_message?: MeetingChatMessage;
  target?: {
    room_id: string;
  };
  sender?: {
    org_id: string;
    user_id: string;
  };
};

const fetchMessagesPayload = (roomId: string) => {
  const payload: SocketMessage = {
    target: { room_id: roomId },
    message: roomId,
    action: 'fetch-messages',
  };
  return payload;
};

const leaveRoomPayload = (roomId: string) => {
  const payload: SocketMessage = {
    message: roomId,
    action: 'leave-room',
  };
  return payload;
};

const joinRoomPayload = (roomId: string) => {
  const payload: SocketMessage = {
    target: { room_id: roomId },
    message: roomId,
    action: 'join-room',
  };

  return payload;
};

const sendMessagePayload = (roomId: string, chatMessage: MeetingChatMessage) => {
  const payload: SocketMessage = {
    target: { room_id: roomId },
    message: chatMessage.text ?? '',
    chat_message: chatMessage,
    action: 'send-message',
  };

  return payload;
};

const fetchRequests = (roomId: string, clientId: string) => {
  const payload: SocketMessage = {
    target: { room_id: roomId },
    message: clientId,
    action: 'fetch-requests',
  };
  
  return payload
};

const exportedMethods = {
  fetchRequests,
  sendMessagePayload,
  joinRoomPayload,
  leaveRoomPayload,
  fetchMessagesPayload
};

export default exportedMethods;