import { handleCaughtError } from '_helpers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';

import { CalendarResponseMessage, MeetingChatMessage } from 'server';
import calendarServices from './calendarInterface';
import { showMessage } from 'features/alerts/alertSlice';
import { RESPONSE_CODE } from '../asyncRequest';
// import openNotificationWithIcon from "../alerts/Toast";
// import {RootState} from "app/rootReducer";
import { subMonths } from 'date-fns';

import axios from 'axios';

interface ChatState extends CalendarResponseMessage {
  chat_messages: Array<MeetingChatMessage>;
  message: string;
  roomId: string;
}

const initialState: ChatState = {
  chat_messages: [],
  message: '',
  roomId: '',
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatMessages(state, action: PayloadAction<CalendarResponseMessage>) {
      state.chat_messages = action.payload.chat_messages || [];
    },
    appendChatMessages(state, action: PayloadAction<MeetingChatMessage[]>) {
      console.log('checking before appending message to room ', state.roomId);
      console.log(action.payload);

      if (action.payload.length > 0 && (action.payload[0].client_id === state.roomId || action.payload[0].meeting_id === state.roomId)) {
        console.log('appending...');
        state.chat_messages = [...state.chat_messages, ...action.payload];
      }
    },
    setRoom(state, action: PayloadAction<string>) {
      state.roomId = action.payload;
    },
  },
});

export const { setChatMessages, appendChatMessages, setRoom } = chatSlice.actions;
export default chatSlice.reducer;

/***
 * Chat dispatch methods
 **/

export const getChatMessages = (clientId: string, meetingId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    const { isExpert } = state.accounts;
    if (!usertoken || !userid) return;

    let resp: CalendarResponseMessage;
    dispatch(setRoom(meetingId));
    if (isExpert) {
      resp = await calendarServices.getChatMessages(usertoken, meetingId);
    } else {
      resp = await calendarServices.getChatHistoryMessages(usertoken, clientId, subMonths(new Date(), 6), new Date());
    }

    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setChatMessages(resp));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const sendChatMessage = (chatObj: MeetingChatMessage, file?: File): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;

    if (chatObj.message_type === 'file' && file && chatObj.client_id) {
      chatObj.file_name = file.name;
    }

    const resp: CalendarResponseMessage = await calendarServices.sendChatMessage(usertoken, chatObj);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      if (file && resp.chat_messages && resp.chat_messages.length > 0 && resp.chat_messages[0].url) {
        const formData = new FormData();
        formData.append('myFile', file, file.name);
        axios.put(resp.chat_messages[0].url, file);
      }

      dispatch(getChatMessages(chatObj.client_id!, chatObj.meeting_id ?? ''));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const deleteMeetingChatMessage = (message: MeetingChatMessage): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;

    const resp: CalendarResponseMessage = await calendarServices.deleteMeetingChatMessage(usertoken, message.id!);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getChatMessages(message.client_id!, message.meeting_id ?? ''));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};

export const updateMeetingChatMessage = (messageId: string, chatObj: MeetingChatMessage): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) return;

    const resp: CalendarResponseMessage = await calendarServices.updateMeetingChatMessage(usertoken, chatObj);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getChatMessages(chatObj.client_id!, ''));
    } else {
      dispatch(showMessage(resp.message || 'something went wrong', 'warning'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(showMessage(err, 'danger'));
  }
};
