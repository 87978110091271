import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validateInvitationHash } from '../interface';

import AuthLayout from '../AuthLayout';
import Loading from 'components/loading/Loading';
import ActivateNewUserForm from './ActivateNewUserForm';

const ActivateUser = () => {
  const [hashValid, setHashValidity] = useState<null | boolean>(null);
  const { t } = useTranslation('login');

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code: string = query.get('code') || '';

  const checkHash = useCallback(async () => {
    const hashValid = await validateInvitationHash(code);
    setHashValidity(hashValid);
  }, [code]);

  useEffect(() => {
    checkHash();
  }, [checkHash]);

  if (hashValid === null) {
    return <Loading />;
  }

  return (
    <>
      {!hashValid ? (
        <div className="p-5">
          <div className="p-5 mb-4 bg-warning rounded-lg">
            <h1>{t('This link is no long valid')}</h1>
            <h3>{t('Please ask your administrator to send you a new invitation.')}</h3>
            <hr />
          </div>
        </div>
      ) : (
        <AuthLayout title={t('Confirm your new account')}>
          <ActivateNewUserForm code={code} />
        </AuthLayout>
      )}
    </>
  );
};

export default ActivateUser;
