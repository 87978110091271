import {configureStore, Action, getDefaultMiddleware} from '@reduxjs/toolkit';
import  {ThunkAction} from 'redux-thunk';

import rootReducer, { RootState } from './rootReducer';
import {useDispatch} from "react-redux";


const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware({immutableCheck: false})] // prevent performance issues in dev.
  // todo: "middleware" should be removed once we remove all unneccessary data from state (update, create time, so on)
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
