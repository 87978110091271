import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import MainContainer from './components/main-container/MainContainer';
import Login from './features/auth/login/Login';
import ResetPassword from './features/auth/reset-password/ResetPassword';
import RecoverPassword from './features/auth/recover-password/Recover';
import NoMatch from './components/no-match/NoMatch';
import { RootState } from 'app/rootReducer';

import { checkAuthentication } from 'features/auth/authSlice';
import { getWhiteLabel } from 'features/orgData/orgDataSlice';
import ActivateUser from "./features/auth/ActivateUser/ActivateUser";

import { Alert, Col } from 'react-bootstrap';

import './App.scss';

function App() {
  const alerts = useSelector((state: RootState) => state.alert.alerts);
  const dispatch = useDispatch();
  const {  isLoggedIn } = useSelector((state: RootState) => state.authentication)

  useEffect(() => {
    dispatch(getWhiteLabel());
  }, [dispatch])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) dispatch(checkAuthentication());
    return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
  },[dispatch]);

  return (
    <div className="App">
      {alerts.length > 0 && (
        <Col md={{span: 8, offset: 2}}>
          {alerts.map((alert) => (
            <Alert key={alert.id} variant={alert.message.type}>
              {alert.message.content}
            </Alert>
          ))}
        </Col>
      )}
      <BrowserRouter>
        <Switch>
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/recover" component={RecoverPassword} />
          <Route path="/login" component={Login} />
          <Route path="/join" component={ActivateUser} />
          <Redirect from="/meeting" to="/app/meeting" />
          {(isLoggedIn !== null) ? <PrivateRoute path="/app" component={MainContainer} />: <Login/>}
          {(isLoggedIn !== null) ? <Redirect from="/" to="/app" />: <Login/>}
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
