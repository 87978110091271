import { handleCaughtError } from '_helpers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';

import { AccountResponseMessage, OrganizationUserPersonalData, OrganizationUser, Label } from 'server';
import { accountService } from './accountService';
import { AsyncRequest, RESPONSE_CODE, startLoading, loadingFailed } from '../asyncRequest';
import { getOrganizationUsers } from 'features/user-management/userManagementSlice';
import openNotificationWithIcon from '../alerts/Toast';

/*
Gets and upadtes the personal user data
*/

interface AccountState extends AsyncRequest {
  userPersonalData: OrganizationUserPersonalData | null;
  isAdmin: boolean;
  isConsultant: boolean;
  isExpert: boolean;
  pref_lang: string;
  // TODO: distinguish between 'my' data and general search data, which should be in orgSlice
  organizationUser: OrganizationUser;
  availableLabels: Label[];
  myLabels: Label[];
  organizationUsersPersonalData: OrganizationUserPersonalData[];
  expertSearchResult: OrganizationUser[];
  organizationLabels: Label[];
}

const initialState: AccountState = {
  userPersonalData: null,
  isAdmin: false,
  isConsultant: false,
  isExpert: false,
  loading: false,
  error: null,
  pref_lang: 'de',
  organizationUser: {},
  availableLabels: [],
  myLabels: [],
  organizationUsersPersonalData: [],
  expertSearchResult: [],
  organizationLabels: [],
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    requestStart: startLoading,
    requestFailed: loadingFailed,
    updateLang: (state, action) => {
      state.pref_lang = action.payload.toLowerCase();
      document.documentElement.lang = action.payload.toLowerCase();
    },
    setUserAccountData(state, action: PayloadAction<OrganizationUserPersonalData>) {
      state.userPersonalData = action.payload;
      state.loading = false;
      state.error = null;
      state.pref_lang = action.payload.pref_lang?.toLowerCase() || 'de';
      state.isAdmin = action.payload?.roles?.some((role) => role.role_name?.includes('admin')) || false;
      state.isConsultant = action.payload?.roles?.some((role) => role.role_name?.includes('consultant')) || false;
      state.isExpert = action.payload?.roles?.some((role) => role.role_name?.includes('interpreter')) || false;
      document.documentElement.lang = action.payload.pref_lang?.toLowerCase() || 'de';
    },
    setOrganizationUser(state, action: PayloadAction<OrganizationUser>) {
      state.organizationUser = action.payload;
    },
    setAvailableLabels(state, action: PayloadAction<Label[]>) {
      state.availableLabels = action.payload;
    },
    setMyLabels(state, action: PayloadAction<Label[]>) {
      state.myLabels = action.payload;
    },
    setOrganizationUsersPersonalData(state, action: PayloadAction<OrganizationUserPersonalData[]>) {
      state.organizationUsersPersonalData = action.payload;
    },
    // TODO: Move this to a dedicated, expertSearch, slice.
    setExpertSearchResult(state, action: PayloadAction<OrganizationUser[]>) {
      //For Expert Search
      state.expertSearchResult = action.payload;
    },
    setOrganizationLabels(state, action: PayloadAction<Label[]>) {
      state.organizationLabels = action.payload;
    },
  },
});

export const {
  requestStart,
  setUserAccountData,
  requestFailed,
  updateLang,
  setOrganizationUser,
  setAvailableLabels,
  setMyLabels,
  setOrganizationLabels,
  setOrganizationUsersPersonalData,
  setExpertSearchResult,
} = accountsSlice.actions;
export default accountsSlice.reducer;

export const getPersonalUserData = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      dispatch(requestFailed('Not authenticated'));
      return;
    }

    dispatch(requestStart());
    const response: AccountResponseMessage = await accountService.getPersonalUserData(authToken);
    if (response.code === RESPONSE_CODE.SUCCESS && response.user_personal_data) {
      dispatch(setUserAccountData(response.user_personal_data));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const setPersonalUserData = (
  userData: OrganizationUserPersonalData
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      dispatch(requestFailed('Not authenticated'));
      return;
    }

    dispatch(requestStart());
    const response: AccountResponseMessage = await accountService.updatePersonalUserData(
      authToken,
      userData
    );
    if (response.code === RESPONSE_CODE.SUCCESS && response.user_personal_data) {
      dispatch(setUserAccountData(response.user_personal_data));
      openNotificationWithIcon('success', 'Success!', 'Your personal settings were saved', 'settings');
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const getOrganizationUser = (userId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }
    const response: AccountResponseMessage = await accountService.getOrganizationUser(userId, authToken);
    //todo: Server should return status 1000 on success. Instead returns 0 with valid data. That's why the response code is commented out
    if (/* response.code === RESPONSE_CODE.SUCCESS && */ response.user) {
      dispatch(setOrganizationUser(response.user));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const searchOrganizationUsers = (name: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }
    const response: AccountResponseMessage = await accountService.searchOrganizationUsers(authToken, name);
    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setExpertSearchResult(response.users ?? []));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const searchOrganizationUserLabel = (name: string, label: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }
    const response: AccountResponseMessage = await accountService.searchOrganizationUserLabel(authToken, name, label);
    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setExpertSearchResult(response.users ?? []));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const updateUsersRole = (userId: string, newRole: string, oldRole?: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }

    const addResponse: AccountResponseMessage = await accountService.addUsersRole(newRole, userId, authToken);
    if (addResponse.code === RESPONSE_CODE.SUCCESS) {
      if (oldRole) {
        const deleteResponse = await accountService.deleteUsersRole(oldRole, userId, authToken);
        if (deleteResponse.code === RESPONSE_CODE.SUCCESS) {
          dispatch(getOrganizationUsers());
        }
      } else {
        dispatch(getOrganizationUsers());
      }
    } else {
      dispatch(requestFailed(addResponse.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const getLabels = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }

    const response: AccountResponseMessage = await accountService.getUserlabels(authToken);

    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setAvailableLabels(response.labels ?? []));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const addLabel = (category: string, labelName: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }

    const response: AccountResponseMessage = await accountService.createLabel(category, authToken, labelName);

    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getLabels());
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

export const deleteLabel = (labelId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const authToken = state.authentication.usertoken;
    if (!authToken) {
      return;
    }

    const response: AccountResponseMessage = await accountService.deleteUserlabel(labelId, authToken);

    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getLabels());
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

// self
export const getUserLabels = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) {
      return;
    }

    const response: AccountResponseMessage = await accountService.getLabelByUser(usertoken, userid);
    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setMyLabels(response.labels ?? []));
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

// to self
export const addUsersLabel = (labelId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) {
      return;
    }

    const response: AccountResponseMessage = await accountService.addLabelToUser(userid, usertoken, labelId);

    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getUserLabels());
    } else {
      openNotificationWithIcon('error', "Can't add label", 'Server error: ' + response.message);
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};

// to self
export const deleteUsersLabel = (labelId: string): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { usertoken, userid } = state.authentication;
    if (!usertoken || !userid) {
      return;
    }

    const response: AccountResponseMessage = await accountService.deleteLabelFromUser(usertoken, userid, labelId);

    if (response.code === RESPONSE_CODE.SUCCESS) {
      dispatch(getUserLabels());
    } else {
      dispatch(requestFailed(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(requestFailed(err.toString()));
  }
};
