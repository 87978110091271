import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setUser } from '@sentry/react';
import { handleCaughtError } from '_helpers';
import { AppThunk } from 'app/store';

import { AuthenticationResponse, AccountRole, AccountResponseMessage } from 'server';
import { userService } from './interface';
import { AsyncRequest, RESPONSE_CODE, startLoading, loadingFailed } from '../asyncRequest';
import openNotificationWithIcon from '../alerts/Toast';

/*
Checks the authentication hash code for validity
Updates the user's password after a reset request.
*/

const ADMIN_ROLE_NAME = 'organization admin';

interface AuthState extends AsyncRequest {
  isLoggedIn: boolean | null;
  email: string | null;
  expiry: string | null;
  roles: Array<AccountRole> | null;
  userid: string | null;
  usertoken: string | null;
  isAdmin: boolean;
  errorOnAuth: boolean;
}

const initialState: AuthState = {
  isLoggedIn: null,
  email: null,
  expiry: null,
  roles: null,
  userid: null,
  usertoken: null,
  loading: false,
  error: null,
  errorOnAuth: false,
  isAdmin: false,
};

const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    requestStart: startLoading,
    loginFailure: loadingFailed,
    setLoggedinUser(state: AuthState, action: PayloadAction<AuthenticationResponse>) {
      const { code = null, email = null, expiry = null, roles = null, userid = null, usertoken = null } = action.payload;
      state.isLoggedIn = code === RESPONSE_CODE.SUCCESS;
      state.email = email;
      state.expiry = expiry;
      state.errorOnAuth = false;
      state.userid = userid;
      state.roles = roles;
      state.usertoken = 'Bearer ' + usertoken;
      state.loading = false;
      state.error = null;
      state.isAdmin = !!roles?.find((role) => role.role_name?.includes(ADMIN_ROLE_NAME));
    },
    logout() {
      return initialState;
    },
    setErrorOnAuth(state: AuthState, action: PayloadAction<boolean>) {
      state.errorOnAuth = action.payload;
    },
    setIsLoggedIn(state: AuthState, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { requestStart, setLoggedinUser, loginFailure, logout, setErrorOnAuth, setIsLoggedIn } = authSlice.actions;
export default authSlice.reducer;

export const performLogin = (email: string, password: string, rememberMe: boolean): AppThunk => async (dispatch) => {
  try {
    dispatch(logout());
    dispatch(requestStart());

    const resp: AuthenticationResponse = await userService.login(email, password);
    if (resp.code === RESPONSE_CODE.SUCCESS) {
      dispatch(setLoggedinUser(resp));
      setUser({ email: email });
      userService.storeLogin(resp, rememberMe);
    } else {
      dispatch(logout());
      dispatch(loginFailure(resp.message || ''));
    }
  } catch (err) {
    handleCaughtError(err);
    openNotificationWithIcon('error', 'Error', 'Credential failure', 'login');
    dispatch(loginFailure(err ? err.toString() : 'unkown error'));
  }
};

export const performLogout = (): AppThunk => async (dispatch) => {
  userService.logout();
  dispatch(logout());
};

export const isExpired = (exp?: string | null): boolean => {
  if (!exp) {
    return false;
  }
  return Date.now() > Date.parse(exp);
};

export const checkAuthentication = (): AppThunk => async (dispatch) => {
  // Checks if the user is authenticated, and stores it in the redux state
  try {
    let userData = userService.checkAuthentication();
    if (userData) {
      dispatch(setLoggedinUser(userData));
    } else {
      dispatch(setErrorOnAuth(true));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(logout());
  }
};

export const sendResetPasswordLink = (lang: string, email: string): AppThunk => async () => {
  try {
    let resetPassword = userService.resetPassword(lang, email);
    return (await resetPassword).code === RESPONSE_CODE.SUCCESS;
  } catch (err) {}
};

export const activateInvitedOrgUser = (hash: string, email: string, password: string): AppThunk => async (dispatch) => {
  try {
    dispatch(requestStart());

    const response: AccountResponseMessage = await userService.activateInvitedOrgUser(hash, email, password);
    if (response.code === RESPONSE_CODE.SUCCESS && response.message === 'User successfully activated') {
      dispatch(performLogin(email, password, false));
    } else {
      dispatch(loginFailure(response.message || 'Something went wrong'));
    }
  } catch (err) {
    handleCaughtError(err);
    dispatch(loginFailure(err.response?.data?.message || err.toString()));
  }
};
