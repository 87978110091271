import { handleErrorResponse } from '_helpers';
import { AccountsApi } from 'server';
import { IUserInviteForm } from 'models/interfaces';

const accountsApi = new AccountsApi();

async function getOrganizationUsers(authToken: string) {
  try {
    const { data } = await accountsApi.getOrganizationUsers(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function addNewOrgUser(authToken: string, inviteObj: IUserInviteForm) {
  try {
    const { email, language, firstName, lastName, roleID, subDivID } = inviteObj;
    const { data } = await accountsApi.addNewOrgUser(authToken, email, language, firstName, lastName, roleID, subDivID || '');
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function sendInvitation(authToken: string, email: string, language: string, userId: string) {
  try {
    const { data } = await accountsApi.sendInvite(authToken, email, language, userId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function deactivateUser(authToken: string, userId: string) {
  try {
    const { data } = await accountsApi.deactivateUser(authToken, userId);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

async function getOrganizationRoles(authToken: string) {
  try {
    const { data } = await accountsApi.getOrganizationRoles(authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export const userManagementService = {
  getOrganizationUsers,
  addNewOrgUser,
  sendInvitation,
  getOrganizationRoles,
  deactivateUser,
};
