import React from 'react';
import ResetPasswordForm from './form/Form';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../AuthLayout';

const ResetPassword = () => {
  const { t } = useTranslation('login');

  return (
    <AuthLayout title={t('forgot password')}>
      <ResetPasswordForm />
    </AuthLayout>
  );
};

export default ResetPassword;
