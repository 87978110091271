import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validateHash } from '../interface';

import Button from 'react-bootstrap/Button';
import AuthLayout from '../AuthLayout';
import SetNewPasswordForm from './form/Form';
import Loading from "components/loading/Loading";

const RecoverPassword = () => {
  const [hashValid, setHashValidity] = useState<boolean>();
  const { t } = useTranslation('login');

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code: string = query.get('code') || '';

  const checkHash = useCallback(async () => {
    const hashValid = await validateHash(code);
    setHashValidity(hashValid > 0);
  }, [code]);

  useEffect(() => {
    checkHash();
  }, [checkHash]);

  if (hashValid === undefined) return <Loading/>;

  return (
    <>
      {!hashValid ? (
        <div className="p-5">
        <div className="p-5 mb-4 bg-light rounded-lg">
          <h1>{t('This link is no long valid')}.</h1>
          <h3>{t('Please repeat the password-reset process')}.</h3>
          <hr />
          <div className="d-flex justify-content-end">
            <Link to="/reset-password">
              <Button variant="primary">{t('Reset my password')}.</Button>
            </Link>
          </div>
        </div>
        </div>
      ) : (
        <AuthLayout title={t('Reset your password')}>
          <SetNewPasswordForm code={code} />
        </AuthLayout>
      )}
    </>
  );
};

export default RecoverPassword;
