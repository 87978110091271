import { subMonths } from 'date-fns';
import { handleErrorResponse } from '_helpers';
import { AccountsApi, CalendarApi, AccountResponseMessage, OrganizationUser, MeetingNote } from 'server';
import { RESPONSE_CODE } from '../asyncRequest';

const accountsApi = new AccountsApi();
const calendarApi = new CalendarApi();

export async function getClientData(authToken: string, clientId: string): Promise<AccountResponseMessage> {
  try {
    const { data } = await accountsApi.getOrganizationUser(clientId, authToken);
    return data;
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export async function searchClient(authToken: string, searchTerm: string): Promise<OrganizationUser[]> {
  try {
    const { data } = await accountsApi.searchClient(authToken, searchTerm);

    if (data.code === RESPONSE_CODE.SUCCESS && data.users) {
      return data.users;
    } else {
      return [];
    }
  } catch (e) {
    return handleErrorResponse(e);
  }
}

export async function getClientMeetingNotes(authToken: string, clientId: string, fromDate: Date=subMonths(new Date(), 6), toDate: Date=new Date()): Promise<MeetingNote[]> {
  try {
    const { data } = await calendarApi.getNoteHistoryForUser(authToken, clientId, fromDate.toISOString(), toDate.toISOString());
    if (data.code === RESPONSE_CODE.SUCCESS && data.meeting_notes) {
      return data.meeting_notes;
    } else {
      return [];
    }
  } catch (e) {
    return handleErrorResponse(e);
  }
}