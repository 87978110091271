import React from 'react';
import { useTranslation } from 'react-i18next';

import LoginForm from './loginForm/LoginForm';
import AuthLayout from '../AuthLayout';

const Login = () => {
  const { t } = useTranslation('login');

  return (
    <AuthLayout title={t('title')} description={t('description')}>
      <LoginForm />
    </AuthLayout>
  );
};

export default Login;
