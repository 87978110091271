import React from 'react';
import './Loading.scss';
import {Spin} from "antd";

type LoadingProps = { children?: any;};

const Loading: React.FC<LoadingProps> = ({children}) => {
  return (
    <div className="loading-container">
      <Spin size="large"></Spin>
      {children}
    </div>
  );
};

export default Loading;
